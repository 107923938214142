import React, { useMemo, useState } from 'react';
import axios from 'axios';

const SelectDipendenti = ({ valore }) => {
    const [dati, setDati] = useState([]);

    const ottieniDati = async (value) => {
        let Cantiere = value.Cantiere;
        Cantiere = Cantiere ? { Cantiere: Cantiere } : { Cantiere: 'NO' };

        try {
            const risposta = await axios.get(`${process.env.REACT_APP_URL_SERVER}/DipendentiCantiere`, {
                params: Cantiere,
            });
            const OrdinePrego = risposta.data.sort((a, b) => {
                let x = a.Dipendente.toLowerCase();
                let y = b.Dipendente.toLowerCase();
                if (x > y) {
                    return 1;
                }
                if (x < y) {
                    return -1;
                }
                return 0;
            });
            setDati(OrdinePrego);
        } catch (error) {
            console.log(error);
        }
    };
    useMemo(() => ottieniDati(valore), [valore]);

    return (
        <>
            <select onChange={valore.onChange} id='Nome' name='Nome'>
                <option label='Scegli' placeholder='Scegli' value=''></option>
                {dati.map((elemento) => {
                    return (
                        <option key={Math.random()} value={elemento['ID']}>
                            {elemento['Dipendente']} {elemento['Termine'] === 'true' ? '[Terminato]' : ''} Mat.: {elemento['Matricola']}
                        </option>
                    );
                })}
            </select>
        </>
    );
};

export default SelectDipendenti;

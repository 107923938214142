import React, { useRef, useState } from 'react';
import axios from 'axios';
import '../Component/CSS/Prodotti.css';
import Tabelle from '../Component/Tabelle';
import { getOut } from '../App';
import { toast } from 'react-toastify';
import Select from '../Component/Select';

export default function Prodotti() {
	getOut(10);

	const [aggiornato, setAggiornato] = useState(false);
	const selezionato = useRef(),
		reparto = useRef(),
		prodotto = useRef(),
		formato = useRef(),
		prezzo = useRef(),
		repartoNuovo = useRef(),
		prodottoNuovo = useRef(),
		formatoNuovo = useRef(),
		prezzoNuovo = useRef();

	const ModificaProdotto = async () => {
		if (
			repartoNuovo.current.value &&
			prodottoNuovo.current.value &&
			formatoNuovo.current.value &&
			prezzoNuovo.current.value
		) {
			await axios.put(
				`${process.env.REACT_APP_URL_SERVER}/ModificaProdotto/${selezionato.current.value}`,
				{
					Reparto: repartoNuovo.current.value,
					Prodotto: prodottoNuovo.current.value,
					Formato: formatoNuovo.current.value,
					Prezzo: parseInt(prezzoNuovo.current.value),
				}
			);

			selezionato.current.value = '';
			repartoNuovo.current.value = '';
			prodottoNuovo.current.value = '';
			formatoNuovo.current.value = '';
			prezzoNuovo.current.value = '';
			setAggiornato((prev) => !prev);
			toast.success('Prodotto Modificato Correttamente');
		} else {
			toast.warning('Tutti i Campi Sono Obbligatori');
		}
	};
	const AggiungiProdotto = async () => {
		if (
			reparto.current.value &&
			prodotto.current.value &&
			formato.current.value &&
			prezzo.current.value
		) {
			await axios.post(`${process.env.REACT_APP_URL_SERVER}/InserisciProdotto`, {
				Reparto: reparto.current.value,
				Prodotto: prodotto.current.value,
				Formato: formato.current.value,
				Prezzo: parseInt(prezzo.current.value),
			});
			reparto.current.value = '';
			prodotto.current.value = '';
			formato.current.value = '';
			prezzo.current.value = '';

			setAggiornato((prev) => !prev);
			toast.success('Prodotto Inserito Correttamente');
		} else {
			toast.warning('Tutti i Campi Sono Obbligatori.');
		}
	};
	const selezionami = async () => {
		console.log(selezionato.current.value);
		const risposta = await axios.post(
			`${process.env.REACT_APP_URL_SERVER}/SelezionaProdottoSingolo`,
			{
				ID: selezionato.current.value,
			}
		);
		const { Reparto, Prodotto, Formato, Prezzo } = risposta.data[0];

		repartoNuovo.current.value = Reparto;
		prodottoNuovo.current.value = Prodotto;
		formatoNuovo.current.value = Formato;
		prezzoNuovo.current.value = Prezzo;
	};

	const selezione = {
		url: 'Prodotti',
		Value: 'ID',
		Display: 'Prodotto',
		Aggiornato: aggiornato,
	};

	return (
		<div id='Contenuto'>
			{process.env.REACT_APP_TIPOSITO === 'SVILUPPO' ? (
				<h1 style={{ color: 'red', textDecoration: 'underline' }}>SVILUPPO</h1>
			) : (
				<h1>Prodotti</h1>
			)}

			<div id='Prodotti'>
				<div id='ModificaProdotto'>
					<h2>Modifica Prodotto</h2>
					<form>
						<label htmlFor='Prod'>Prodotto</label>
						<select name='Prodotto' onChange={selezionami} ref={selezionato} id='Prod'>
							<Select value={selezione} />
						</select>
						<label htmlFor='repartoNuo'>Reparto</label>
						<input type='text' name='repartoNuovo' ref={repartoNuovo} id='repartoNuo' />
						<label htmlFor='prodottoNuo'>Articolo</label>
						<input
							type='text'
							name='prodottoNuovo'
							ref={prodottoNuovo}
							id='prodottoNuo'
						/>
						<label htmlFor='formatoNuo'>Formato</label>
						<input type='text' name='formatoNuovo' ref={formatoNuovo} id='formatoNuo' />
						<label htmlFor='prezzoNuo'>Prezzo</label>
						<input
							type='number'
							name='prezzoNuovo'
							min='0'
							ref={prezzoNuovo}
							id='prezzoNuo'
						/>
						<button type='button' id='ModificaMi' onClick={ModificaProdotto}>
							Modifica Prodotto
						</button>
					</form>
				</div>
				<div id='NewProduct'>
					<h2>Nuovo Prodotto</h2>
					<form>
						<label htmlFor='rep'>Reparto</label>
						<input type='text' name='reparto' ref={reparto} id='rep' />
						<label htmlFor='prod'>Articolo</label>
						<input type='text' name='prodotto' ref={prodotto} id='prod' />
						<label htmlFor='forma'>Formato</label>
						<input type='text' name='formato' ref={formato} id='forma' />
						<label htmlFor='prez'>Prezzo</label>
						<input type='number' name='prezzo' min='0' ref={prezzo} id='prez' />
						<button type='button' onClick={AggiungiProdotto}>
							Aggiungi Prodotto
						</button>
					</form>
				</div>
				<div id='Tabelle'>
					<Tabelle value={{ url: 'ModificaProdotti' }} />
				</div>
				<hr></hr>
			</div>
		</div>
	);
}

/* <table id='Ordinabili'>
    <thead>
        <tr>
            <th>Reparto</th>
            <th>Articolo</th>
            <th>Formato</th>
            <th>Prezzo</th>
            <th>Nuovo Prezzo</th>
            <th>Azione</th>
        </tr>
    </thead>
    <tbody></tbody>
</table>
 */

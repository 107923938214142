import React, { useRef } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { getOut } from '../App';
import '../Component/CSS/Programma.css';

export default function Programma() {
    getOut(11);

    const stanza = useRef(),
        cadenza = useRef(),
        IDTag = useRef(),
        ente = useRef(),
        palazzo = useRef(),
        posta = useRef(),
        note = useRef();

    const LeggiNFC = async () => {
        if ('NDEFReader' in window) {
            try {
                const ndef = new window.NDEFReader();
                const decoder = new TextDecoder();

                return new Promise((resolve, reject) => {
                    const controller = new AbortController();
                    controller.signal.onabort = reject;
                    controller.signal.onabort = (e) => {
                        /* We're done waiting for NFC tags. */
                    };
                    ndef.scan({ signal: controller.signal })
                        .then(() => {
                            ndef.onreading = async (event) => {
                                const ndefMessage = event.message;

                                for (const record of ndefMessage.records) {
                                    toast.success(
                                        `Seriale: ${event.serialNumber}

Record type: ${record.recordType}
Record id: ${record.id}
Dati: ${decoder.decode(record.data)}`
                                    );
                                    if ((cadenza.current.value, ente.current.value, palazzo.current.value, cadenza.current.value)) {
                                        const scrittoADB = await axios.post(`${process.env.REACT_APP_URL_SERVER}/InserisciTag`, {
                                            Seriale: event.serialNumber,
                                            ID_TAG: record.id,
                                            Luogo: decoder.decode(record.data),
                                            Ente: ente.current.value,
                                            Indirizzo: palazzo.current.value,
                                            Cadenza: cadenza.current.value,
                                            Mail: posta.current.value,
                                            Note: note.current.value,
                                        });
                                        toast(scrittoADB);
                                        break;
                                    } else {
                                        toast.error('Cadenza, Ente e Palazzo sono obbligatori, compila e riprova.');
                                    }
                                }

                                resolve(event);
                            };

                            ndef.onreadingerror = (event) => {
                                toast.error('Errore! Riprovare?');
                            };
                        })
                        .catch((error) => {
                            reject(error);
                            toast.error(`Errore! ${error}.`);
                        });
                });
            } catch (error) {
                toast.error(error);
            }
        } else {
            toast.error('Non Puoi scansionare. Il tuo dispositivo non supporta la funzionalità.');
        }
    };

    const ScriviNFC = async () => {
        if ('NDEFReader' in window) {
            let Dove = stanza.current.value;
            let Cosa = IDTag.current.value;
            if (Dove && Cosa) {
                const controller = new AbortController();
                toast('Puoi avvicinare il TAG per scriverlo');
                try {
                    const ndef = new window.NDEFReader();
                    await ndef.write(
                        {
                            records: [{ recordType: 'text', data: `${Dove}`, id: `${Cosa}` }],
                        },
                        { signal: controller.signal }
                    );
                    toast.success(`Record ${Cosa} scritto correttamente.`);
                    controller.signal.onabort = (e) => {};
                    controller.abort();
                } catch (error) {
                    toast.error('Argh! ' + error);
                }
            } else {
                toast.error(`Scegli la stanza e indica l'id del tag`);
            }
        } else {
            toast.error('Non Puoi Usare gli NFC con il tuo dispositivo (non supporta la funzionalità).');
        }
    };
    const MakeReadOnly = async () => {
        if ('NDEFReader' in window) {
            toast('Scansiona e rendi di sola Lettura il TAG.');
            try {
                const ndef = new window.NDEFReader();
                await ndef.makeReadOnly();
                toast.success('Reso Sola Lettura.');
            } catch (error) {
                toast.error('Argh! ' + error);
            }
        } else {
            toast.error('Non Puoi Usare gli NFC con il tuo dispositivo (non supporta la funzionalità).');
        }
    };

    return (
        <div id='Contenuto'>
            {process.env.REACT_APP_TIPOSITO === 'SVILUPPO' ? (
                <h1 style={{ color: 'red', textDecoration: 'underline' }}>SVILUPPO</h1>
            ) : (
                <h1>Programma NFC</h1>
            )}

            <div id='Programmazione'>
                <label>Luogo</label>
                <select name='Stanza' ref={stanza}>
                    <option label='Scegli' value=''></option>
                    <option value='Ambulatorio'>Ambulatorio</option>
                    <option value='Area Comune'>Area Comune</option>
                    <option value='Ascensore'>Ascensore</option>
                    <option value='Atrio'>Atrio</option>
                    <option value='Bagno'>Bagno</option>
                    <option value='Camera'>Camera</option>
                    <option value='Corridoio'>Corridoio</option>
                    <option value='Finestre'>Finestre</option>
                    <option value='Ingresso'>Ingresso</option>
                    <option value='Magazzino'>Magazzino</option>
                    <option value='Mobilio'>Mobilio</option>
                    <option value='Reparto'>Reparto</option>
                    <option value='Ripostiglio'>Ripostiglio</option>
                    <option value='Spogliatoio'>Spogliatoio</option>
                    <option value='Sala'>Sala</option>
                    <option value='Sala Attesa'>Sala Attesa</option>
                    <option value='Sala Operatoria'>Sala Operatoria</option>
                    <option value='Scale'>Scale</option>
                    <option value='Scaffali'>Scaffali</option>
                    <option value='Ufficio'>Ufficio</option>
                    <option value='Generico'>Generico</option>
                    <option value='Altro'>Altro</option>
                </select>

                <label>Cadenza</label>
                <select name='Cadenza' ref={cadenza}>
                    <option label='Scegli' value=''></option>
                    <option value='Giornaliera'>Giornaliera</option>
                    <option value='Settimanale'>Settimanale</option>
                    <option value='Bisettimanale'>Bisettimanale</option>
                    <option value='Mensile'>Mensile</option>
                </select>

                <label>ID Tag (Univoco)</label>
                <input type='number' name='IDTag' ref={IDTag} />

                <label>Ente</label>
                <input type='text' ref={ente} />

                <label>Palazzo-Indirizzo</label>
                <input type='text' ref={palazzo} />

                <label>Mail</label>
                <input type='email' name='Posta' ref={posta} />

                <label>Note</label>
                <input type='Note' name='Note' ref={note} />

                <hr />
                <button type='button' name='ScriviNFC' onClick={ScriviNFC} style={{ backgroundColor: 'blue' }}>
                    Scrivi sul Tag
                </button>
                <hr />
                <button type='button' name='nfc' onClick={LeggiNFC} style={{ backgroundColor: 'green' }}>
                    Scansiona Tag/Crea Istanza nel DB
                </button>
                <hr />
                <button type='button' name='ReadOnly' onClick={MakeReadOnly} style={{ backgroundColor: 'red' }}>
                    Make ReadOnly
                </button>
            </div>
        </div>
    );
}

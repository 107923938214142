import '../Component/CSS/Questionari.css';
import { getOut } from '../App';
import axios from 'axios';
import { toast } from 'react-toastify';

export default function Questionari() {
    getOut(0);

    function InviaQuestionario() {
        let RisposteQuestionario = {};
        const Risposte = document.querySelectorAll('form');
        let TotDomande = document.querySelectorAll('form').length;
        let Note = document.getElementById('Note').value;
        let Domanda,
            Risposta,
            DomandeRisposte = 0;

        Risposte.forEach((element) => {
            Domanda = element.querySelector('h3').textContent + '§';

            const RisposteInterne = element.querySelectorAll('input');
            for (let x = 0; x < RisposteInterne.length; x++) {
                if (RisposteInterne[x].checked) {
                    Risposta = `<strong> ${RisposteInterne[x].value} </strong>§`;
                    DomandeRisposte += 1;
                    return (RisposteQuestionario[Domanda] = Risposta);
                }
            }
        });

        RisposteQuestionario = JSON.stringify(RisposteQuestionario).split('§').join('<br />');
        RisposteQuestionario = RisposteQuestionario.replaceAll(`","`, '')
            .replaceAll(`}`, '')
            .replaceAll(`{"`, '')
            .replaceAll(`":"`, '')
            .replaceAll('\n', '');

        RisposteQuestionario += `<br /> Note Addizionali: <br /> <strong>${Note}</strong>`;
        RisposteQuestionario += `<br /> Domande Totali: <br /> <strong>${TotDomande}</strong>`;
        RisposteQuestionario += `<br /> Domande Risposte: <br /> <strong>${DomandeRisposte}</strong>`;
        RisposteQuestionario += `<br /> Domande Senza Risposta: <br /> <strong>${TotDomande - DomandeRisposte}</strong>`;

        RisposteQuestionario = {
            Testo: RisposteQuestionario,
            Oggetto: 'Risposte al Questionario',
            Mail: process.env.REACT_APP_MAIL_COMUNICAZIONI,
        };

        axios.post(`${process.env.REACT_APP_URL_SERVER}/InviaMail`, RisposteQuestionario);

        const SvuotaInput = document.querySelectorAll('form input');
        SvuotaInput.forEach((element) => {
            element.checked = false;
        });
        document.getElementById('Note').value = '';
        RisposteQuestionario = {};

        toast.success('Grazie di aver partecipato, il test è stato invato correttamente ed in modo anonimo.');
    }

    return (
        <div id='Contenuto'>
            {process.env.REACT_APP_TIPOSITO === 'SVILUPPO' ? (
                <h1 style={{ color: 'red', textDecoration: 'underline' }}>SVILUPPO</h1>
            ) : (
                <h1>Questionario</h1>
            )}

            <div id='Quest'>
                <strong>
                    <p>
                        Questo questionario è uno strumento di indagine sul benessere organizzativo nell’ambiente di lavoro. I risultati consentiranno
                        di individuare eventuali criticità e margini di miglioramento. Per ogni domanda risponda ponendo una croce nel quadratino
                        corrispondente alla sua percezione, Il questionario è anonimo sarà ritirato direttamente dall’ufficio della qualità e i
                        risultati saranno elaborati dalla Direzione.
                    </p>
                </strong>
                <hr />
                <form>
                    <h3>Può indicarci che ruolo ha nella struttura?</h3>

                    <p>Amministrativo</p>
                    <input type='radio' name='Ruolo' value='Amministrativo' />
                    <p>Facchino</p>
                    <input type='radio' name='Ruolo' value='Facchino' />
                    <p>Autista</p>
                    <input type='radio' name='Ruolo' value='Autista' />
                    <p>Add. Pulizie</p>
                    <input type='radio' name='Ruolo' id='test' value='AddPulizie' />
                    <hr />
                </form>
                <form>
                    <h3>Orario di lavoro:</h3>
                    <p>Ottimo</p>
                    <input type='radio' name='Orario' value='Ottimo' />
                    <p>Buono</p>
                    <input type='radio' name='Orario' value='Buono' />
                    <p>Sufficiente</p>
                    <input type='radio' name='Orario' value='Sufficiente' />
                    <p>Scarso</p>
                    <input type='radio' name='Orario' value='Scarso' />
                    <hr />
                </form>
                <form>
                    <h3>Clima delle relazione con i colleghi:</h3>
                    <p>Ottimo</p>
                    <input type='radio' name='Clima' value='Ottimo' />
                    <p>Buono</p>
                    <input type='radio' name='Clima' value='Buono' />
                    <p>Sufficiente</p>
                    <input type='radio' name='Clima' value='Sufficiente' />
                    <p>Scarso</p>
                    <input type='radio' name='Clima' value='Scarso' />
                    <hr />
                </form>
                <form>
                    <h3>Relazione con la Direzione:</h3>
                    <p>Ottimo</p>
                    <input type='radio' name='Direzione' value='Ottimo' />
                    <p>Buono</p>
                    <input type='radio' name='Direzione' value='Buono' />
                    <p>Sufficiente</p>
                    <input type='radio' name='Direzione' value='Sufficiente' />
                    <p>Scarso</p>
                    <input type='radio' name='Direzione' value='Scarso' />
                    <hr />
                </form>
                <form>
                    <h3>Organizzazione del lavoro:</h3>
                    <p>Ottimo</p>
                    <input type='radio' name='Organizzazione' value='Ottimo' />
                    <p>Buono</p>
                    <input type='radio' name='Organizzazione' value='Buono' />
                    <p>Sufficiente</p>
                    <input type='radio' name='Organizzazione' value='Sufficiente' />
                    <p>Scarso</p>
                    <input type='radio' name='Organizzazione' value='Scarso' />
                    <hr />
                </form>
                <form>
                    <h3>Risorse tecniche messe a disposizione per lavorare (attrezzature ecc):</h3>
                    <p>Ottimo</p>
                    <input type='radio' name='Risorse' value='Ottimo' />
                    <p>Buono</p>
                    <input type='radio' name='Risorse' value='Buono' />
                    <p>Sufficiente</p>
                    <input type='radio' name='Risorse' value='Sufficiente' />
                    <p>Scarso</p>
                    <input type='radio' name='Risorse' value='Scarso' />
                    <hr />
                </form>
                <form>
                    <h3>Adeguatezza delle misure di protezione e prevenzione adottate dall’azienda per la salute e sicurezza sul luogo di lavoro:</h3>
                    <p>Ottimo</p>
                    <input type='radio' name='Misure' value='Ottimo' />
                    <p>Buono</p>
                    <input type='radio' name='Misure' value='Buono' />
                    <p>Sufficiente</p>
                    <input type='radio' name='Misure' value='Sufficiente' />
                    <p>Scarso</p>
                    <input type='radio' name='Misure' value='Scarso' />
                    <hr />
                </form>
                <form>
                    <h3>
                        Utilità ai fini dell’espletamento del servizio degli strumenti a disposizione degli operatori (procedure, istruzioni
                        operative, modulistica ecc.):
                    </h3>
                    <p>Ottimo</p>
                    <input type='radio' name='Util' value='Ottimo' />
                    <p>Buono</p>
                    <input type='radio' name='Util' value='Buono' />
                    <p>Sufficiente</p>
                    <input type='radio' name='Util' value='Sufficiente' />
                    <p>Scarso</p>
                    <input type='radio' name='Util' value='Scarso' />
                    <hr />
                </form>
                <form>
                    <h3>Confort degli ambienti di lavoro:</h3>
                    <p>Ottimo</p>
                    <input type='radio' name='Confort' value='Ottimo' />
                    <p>Buono</p>
                    <input type='radio' name='Confort' value='Buono' />
                    <p>Sufficiente</p>
                    <input type='radio' name='Confort' value='Sufficiente' />
                    <p>Scarso</p>
                    <input type='radio' name='Confort' value='Scarso' />
                    <hr />
                </form>
                <form>
                    <h3>Riconoscimento delle proprie competenze professionali:</h3>
                    <p>Ottimo</p>
                    <input type='radio' name='Competenze' value='Ottimo' />
                    <p>Buono</p>
                    <input type='radio' name='Competenze' value='Buono' />
                    <p>Sufficiente</p>
                    <input type='radio' name='Competenze' value='Sufficiente' />
                    <p>Scarso</p>
                    <input type='radio' name='Competenze' value='Scarso' />
                    <hr />
                </form>
                <form>
                    <h3>Possibilità di accrescere la propria professionalità:</h3>
                    <p>Ottimo</p>
                    <input type='radio' name='Accrescere' value='Ottimo' />
                    <p>Buono</p>
                    <input type='radio' name='Accrescere' value='Buono' />
                    <p>Sufficiente</p>
                    <input type='radio' name='Accrescere' value='Sufficiente' />
                    <p>Scarso</p>
                    <input type='radio' name='Accrescere' value='Scarso' />
                    <hr />
                </form>
                <form>
                    <h3>Riconoscimento delle esigenze personali (ferie, permessi ecc.):</h3>
                    <p>Ottimo</p>
                    <input type='radio' name='Ferie' value='Ottimo' />
                    <p>Buono</p>
                    <input type='radio' name='Ferie' value='Buono' />
                    <p>Sufficiente</p>
                    <input type='radio' name='Ferie' value='Sufficiente' />
                    <p>Scarso</p>
                    <input type='radio' name='Ferie' value='Scarso' />
                    <hr />
                </form>
                <form>
                    <h3>Organizzazione turni di ferie:</h3>
                    <p>Ottimo</p>
                    <input type='radio' name='Turni' value='Ottimo' />
                    <p>Buono</p>
                    <input type='radio' name='Turni' value='Buono' />
                    <p>Sufficiente</p>
                    <input type='radio' name='Turni' value='Sufficiente' />
                    <p>Scarso</p>
                    <input type='radio' name='Turni' value='Scarso' />
                    <hr />
                </form>
                <form>
                    <h3>Formazione professionale promossa dalla Società:</h3>
                    <p>Ottimo</p>
                    <input type='radio' name='Form' value='Ottimo' />
                    <p>Buono</p>
                    <input type='radio' name='Form' value='Buono' />
                    <p>Sufficiente</p>
                    <input type='radio' name='Form' value='Sufficiente' />
                    <p>Scarso</p>
                    <input type='radio' name='Form' value='Scarso' />
                    <hr />
                </form>
                <form>
                    <h3>Definizione e chiarezza degli obiettivi dell’organizzazione:</h3>
                    <p>Ottimo</p>
                    <input type='radio' name='Definizione' value='Ottimo' />
                    <p>Buono</p>
                    <input type='radio' name='Definizione' value='Buono' />
                    <p>Sufficiente</p>
                    <input type='radio' name='Definizione' value='Sufficiente' />
                    <p>Scarso</p>
                    <input type='radio' name='Definizione' value='Scarso' />
                    <hr />
                </form>
                <form>
                    <h3>Fruibilità delle informazioni di cui si ha bisogno:</h3>
                    <p>Ottimo</p>
                    <input type='radio' name='Inform' value='Ottimo' />
                    <p>Buono</p>
                    <input type='radio' name='Inform' value='Buono' />
                    <p>Sufficiente</p>
                    <input type='radio' name='Inform' value='Sufficiente' />
                    <p>Scarso</p>
                    <input type='radio' name='Inform' value='Scarso' />
                    <hr />
                </form>
                <form>
                    <h3>Impegno della Direzione a scoraggiare comportamenti minacciosi e/o offensivi, tra dipendenti, nei luoghi di lavoro:</h3>
                    <p>Ottimo</p>
                    <input type='radio' name='Impegno' value='Ottimo' />
                    <p>Buono</p>
                    <input type='radio' name='Impegno' value='Buono' />
                    <p>Sufficiente</p>
                    <input type='radio' name='Impegno' value='Sufficiente' />
                    <p>Scarso</p>
                    <input type='radio' name='Impegno' value='Scarso' />
                    <hr />
                </form>
                <form>
                    <h3>Conoscenza del Sistema di gestione integrata e della Politica aziendale:</h3>
                    <p>Ottimo</p>
                    <input type='radio' name='Sistema' value='Ottimo' />
                    <p>Buono</p>
                    <input type='radio' name='Sistema' value='Buono' />
                    <p>Sufficiente</p>
                    <input type='radio' name='Sistema' value='Sufficiente' />
                    <p>Scarso</p>
                    <input type='radio' name='Sistema' value='Scarso' />
                    <hr />
                </form>
                <h3>Note Addizionali</h3>
                <textarea name='Note' id='Note' cols='30' rows='10'></textarea>
                <hr />
                <button type='button' name='Invia' onClick={InviaQuestionario}>
                    Invia il Questionario (Anonimo)
                </button>
                <hr></hr>
            </div>
        </div>
    );
}

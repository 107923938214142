import React, { useEffect, useRef } from 'react';
import axios from 'axios';

import '../Component/CSS/Ordini.css';
import Tabelle from '../Component/Tabelle';
import { getOut } from '../App';
import { toast } from 'react-toastify';

export default function Ordini() {
    getOut(5);

    const sceltaCantiere = useRef(''),
        Prodotto1 = useRef(''),
        Prodotto2 = useRef(''),
        Prodotto3 = useRef(''),
        Prodotto4 = useRef(''),
        Prodotto5 = useRef(''),
        Prodotto6 = useRef(''),
        Prodotto7 = useRef(''),
        Prodotto8 = useRef(''),
        Prodotto9 = useRef(''),
        Prodotto10 = useRef(''),
        QuantoProdotto1 = useRef(0),
        QuantoProdotto2 = useRef(0),
        QuantoProdotto3 = useRef(0),
        QuantoProdotto4 = useRef(0),
        QuantoProdotto5 = useRef(0),
        QuantoProdotto6 = useRef(0),
        QuantoProdotto7 = useRef(0),
        QuantoProdotto8 = useRef(0),
        QuantoProdotto9 = useRef(0),
        QuantoProdotto10 = useRef(0);
    let corpo = {};
    const NomeDipendente = sessionStorage.getItem('Nome');

    const refNames = [Prodotto1, Prodotto2, Prodotto3, Prodotto4, Prodotto5, Prodotto6, Prodotto7, Prodotto8, Prodotto9, Prodotto10];
    const quantitaRefs = [
        QuantoProdotto1,
        QuantoProdotto2,
        QuantoProdotto3,
        QuantoProdotto4,
        QuantoProdotto5,
        QuantoProdotto6,
        QuantoProdotto7,
        QuantoProdotto8,
        QuantoProdotto9,
        QuantoProdotto10,
    ];
    let ProdottiEQuantita = [
        { Prodotto: Prodotto1.current, Quantita: QuantoProdotto1.current },
        { Prodotto: Prodotto2.current, Quantita: QuantoProdotto2.current },
        { Prodotto: Prodotto3.current, Quantita: QuantoProdotto3.current },
        { Prodotto: Prodotto4.current, Quantita: QuantoProdotto4.current },
        { Prodotto: Prodotto5.current, Quantita: QuantoProdotto5.current },
        { Prodotto: Prodotto6.current, Quantita: QuantoProdotto6.current },
        { Prodotto: Prodotto7.current, Quantita: QuantoProdotto7.current },
        { Prodotto: Prodotto8.current, Quantita: QuantoProdotto8.current },
        { Prodotto: Prodotto9.current, Quantita: QuantoProdotto9.current },
        { Prodotto: Prodotto10.current, Quantita: QuantoProdotto10.current },
    ];

    const MailInviata = async function (e) {
        try {
            if (corpo.Oggetto) {
                await axios.post(`${process.env.REACT_APP_URL_SERVER}/InviaMail`, corpo);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        const Elementi = async () => {
            const risposta = await axios.get(`${process.env.REACT_APP_URL_SERVER}/OrdinaProdotti`);
            let ISelect = document.getElementsByClassName(`Selezione`);

            Array.from(ISelect).forEach((select) => {
                // Cancella tutte le opzioni esistenti nel select
                select.innerHTML = '';

                // Aggiungi il placeholder
                const chooseOption = document.createElement('option');
                chooseOption.value = ''; // Lascia il valore vuoto o usa un valore appropriato
                chooseOption.textContent = 'Scegli Prodotto'; // Testo per l'opzione "scegli"
                select.appendChild(chooseOption);

                // Aggiungi le opzioni basate sui dati forniti
                risposta.data.forEach((element) => {
                    const option = document.createElement('option');
                    option.value = element.ID;
                    option.textContent = element.Prodotto;
                    select.appendChild(option);
                });
            });
        };
        Elementi();

        return () => {};
    }, []);

    return (
        <div id='Contenuto'>
            {process.env.REACT_APP_TIPOSITO === 'SVILUPPO' ? (
                <h1 style={{ color: 'red', textDecoration: 'underline' }}>SVILUPPO</h1>
            ) : (
                <h1>Ordini</h1>
            )}

            <div id='Ordini'>
                <div id='Cantiere'>
                    <select
                        id='Cantieri'
                        onChange={(e) => {
                            sceltaCantiere.current = e.target.value;
                        }}>
                        <option label='Scegli' placeholder='Scegli' value=''></option>
                        <option value='Cam. Comm Pesaro'>Cam. Comm Pesaro</option>
                        <option value='CCIA Pesaro'>CCIA Pesaro</option>
                        <option value='Comm. Tribut. Marche'>Comm. Tribut. Marche</option>
                        <option value='Giud. Pace Latina'>Giud. Pace Latina</option>
                        <option value='Giud. Pace Pesaro'>Giud. Pace Pesaro</option>
                        <option value='IDER'>IDER</option>
                        <option value='Mot. Civ. Pesaro'>Mot. Civ. Pesaro</option>
                        <option value='Pref. Pesaro'>Pref. Pesaro</option>
                        <option value='Proc. Latina'>Proc. Latina</option>
                        <option value='Rag. Pesaro'>Rag. Pesaro</option>
                        <option value='Rag. Terr. Stato Ancona'>Rag. Terr. Stato Ancona</option>
                        <option value='Roma'>Roma</option>
                        <option value='Trib. Frosinone'>Trib. Frosinone</option>
                        <option value='Trib. Latina'>Trib. Latina</option>
                        <option value='Trib. Latina Sez. Fallimentare'>Trib. Latina Sez. Fallimentare</option>
                        <option value='Trib. Rimini'>Trib. Rimini</option>
                        <option value='Trib. Urbino'>Trib. Urbino</option>
                        <option value='Trib. Pesaro'>Trib. Pesaro</option>
                        <option value='Traser'>Traser</option>
                        <option value='Uni. Urbino'>Uni. Urbino</option>

                        <option value='Ferie'>Ferie</option>
                        <option value='Permesso'>Permesso</option>
                        <option value='Malattia'>Malattia</option>
                        <option value='Infortunio'>Infortunio</option>
                        <option value='Aspettativa'>Aspettativa</option>
                        <option value='104'>104</option>
                        <option value='Sospensione'>Sospensione</option>
                    </select>
                    {ProdottiEQuantita.map((el, index) => {
                        const posizione = index + 1;

                        const refName = refNames[index]; // Seleziona il nome del ref
                        const quantitaRef = quantitaRefs[index];
                        return (
                            <div key={index}>
                                <select
                                    className='Selezione'
                                    name={`Select${posizione}`}
                                    ref={refName}
                                    onChange={(e) => {
                                        el.Prodotto = e.target.value;
                                    }}></select>
                                <input
                                    type='number'
                                    name={`Selezione${posizione}`}
                                    className='Quantitativi'
                                    id={`Selezione${posizione}`}
                                    min='0'
                                    step='1'
                                    defaultValue={0}
                                    ref={quantitaRef}
                                    onChange={(e) => {
                                        el.Quantita = parseInt(e.target.value, 10);
                                    }}
                                />
                            </div>
                        );
                    })}

                    <button
                        type='button'
                        onClick={async () => {
                            const MaterialeOrdinato = [];

                            const check = async () => {
                                for (let i = 0; i < 10; i++) {
                                    const lista = document.getElementsByClassName('Selezione')[i];
                                    const prodotto = lista.value;
                                    let nomeProdotto = '';
                                    const quantita = document.getElementsByClassName('Quantitativi')[i].value;
                                    if (prodotto && quantita > 0) {
                                        for (let x = 0; x < lista.options.length; x++) {
                                            if (lista.options[x].value === prodotto) {
                                                nomeProdotto = lista.options[x].text;
                                                break;
                                            }
                                        }
                                        const elemento = { IDProdotto: prodotto, nomeProdotto, quantita };
                                        MaterialeOrdinato[i] = elemento;
                                    }
                                }
                            };

                            await check();

                            if (MaterialeOrdinato.length > 0 && sceltaCantiere.current) {
                                let TestoMail = '';
                                let today = new Date();
                                let time = `${('0' + today.getHours()).slice(-2)}:${('0' + today.getMinutes()).slice(-2)}:${(
                                    '0' + today.getSeconds()
                                ).slice(-2)}`;

                                MaterialeOrdinato.forEach((el) => {
                                    let IDProdotto = el.IDProdotto;
                                    let Nome = el.nomeProdotto;
                                    let Quanti = el.quantita;
                                    let Riga = `Prodotto: ${Nome} [ID: ${IDProdotto}] In Quantità di: ${Quanti} <br />`;
                                    TestoMail = TestoMail + Riga;
                                });

                                corpo = {
                                    Corpo: `${NomeDipendente} Ha richiesto il seguente Materiale per il Cantiere ${sceltaCantiere.current}: <hr></hr>${TestoMail}<hr></hr>`,
                                    Oggetto: `Ordine Materiale Pulizie di ${NomeDipendente} alle ${time}`,
                                    Mail: process.env.REACT_APP_MAIL_NOTIFICHE,
                                    Testo: `${NomeDipendente} Ha richiesto il seguente Materiale per il Cantiere ${sceltaCantiere.current}: <hr></hr>${TestoMail}<hr></hr>`,
                                };

                                await MailInviata();

                                const listaProdotti = document.getElementsByClassName('Selezione');
                                const listaQuanti = document.getElementsByClassName('Quantitativi');

                                //Pulizia
                                for (let index = 0; index < listaProdotti.length; index++) {
                                    const refName = refNames[index];
                                    const quantitaRef = quantitaRefs[index];
                                    listaProdotti[index].value = '';
                                    refName.current = '';
                                    ProdottiEQuantita[index].Prodotto = '';
                                    listaQuanti[index].value = 0;
                                    quantitaRef.current = 0;
                                    ProdottiEQuantita[index].Quantita = 0;
                                }
                                sceltaCantiere.current = null;
                                document.getElementById('Cantieri').value = '';

                                toast.success('Ordine Inviato correttamente');
                            } else {
                                toast.error('Devi scegliere il Cantiere e almeno un prodotto con il suo quantitativo per effettuare un ordine!');
                            }
                        }}
                        id='Ordina'>
                        Invia L'ordine
                    </button>
                </div>
                <div id='Tabelle'>
                    <Tabelle
                        value={{
                            url: 'Prodotti',
                        }}
                    />
                </div>
                <hr></hr>
            </div>
        </div>
    );
}

import React, { useState } from 'react';
import '../Component/CSS/StatoPulizie.css';
import Tabelle from '../Component/Tabelle.js';
import { LogOut } from '../App';

export default function StatoPulizie() {
    const [inizio, setInizio] = useState(0);
    const [fine, setFine] = useState(0);

    function getOut() {
        let LivelloUtenza = parseInt(sessionStorage.getItem('ProUser'));
        if (LivelloUtenza >= 9 || LivelloUtenza === 2) {
        } else {
            LogOut();
        }
    }
    getOut();

    const ImpostaDataInizio = (e) => {
        let StatoInizio = parseInt(e.target.valueAsNumber) / 1000;
        setInizio(StatoInizio);
    };
    const ImpostaDataFine = (e) => {
        let StatoFine = parseInt(e.target.valueAsNumber) / 1000 + 83400;
        setFine(StatoFine);
    };

    return (
        <div id='Contenuto'>
            {process.env.REACT_APP_TIPOSITO === 'SVILUPPO' ? (
                <h1 style={{ color: 'red', textDecoration: 'underline' }}>SVILUPPO</h1>
            ) : (
                <h1>Stato Pulizie</h1>
            )}

            <div id='StatoPulizie'>
                <div id='Tempi'>
                    <label htmlFor='DataInizio'>Da Quale Data:</label>
                    <sub>Se non compilato rende dal giorno zero</sub>
                    <input
                        type='date'
                        id='DataInizio'
                        onChange={ImpostaDataInizio}
                        onFocus={() => document.getElementById('DataInizio').showPicker()}
                    />
                    <label htmlFor='DataFine'>Fino A Quale Data:</label>
                    <input type='date' id='DataFine' onChange={ImpostaDataFine} onFocus={() => document.getElementById('DataFine').showPicker()} />
                </div>
                <div id='Tabelle'>
                    <Tabelle
                        value={{
                            url: 'StatoPulizie',
                            Inizio: inizio,
                            Fine: fine,
                        }}
                    />
                </div>
                <hr></hr>
            </div>
        </div>
    );
}

import '../Component/CSS/Home.css';

window.addEventListener('load', () => {
    if ('serviceWorker' in window.navigator) {
        window.navigator.serviceWorker.register('service-worker.js');
    }
});

window.addEventListener('beforeinstallprompt', (event) => {
    event.preventDefault();
    //console.log('👍', 'beforeinstallprompt', event);
    window.deferredPrompt = event;
});

const Installazione = async () => {
    console.log('👍', 'butInstall-clicked');
    const promptEvent = window.deferredPrompt;
    if (!promptEvent) {
        return;
    }
    promptEvent.prompt();
    const result = await promptEvent.userChoice;
    console.log('👍', 'userChoice', result);
    window.deferredPrompt = null;
};
window.addEventListener('appinstalled', (event) => {
    console.log('👍', 'appinstalled', event);
    window.deferredPrompt = null;
});

export default function Home() {
    return (
        <div id='Contenuto'>
            <h1>Benvenuti in {process.env.REACT_APP_NOMESITO}</h1>
            <div id='HomePage'>
                <br></br>
                <img src={require('.//Immagini/Logo.png')} alt='Logo' id='Logo' />
                <br></br>
                <br></br>
                <br></br>
                <p>
                    {process.env.REACT_APP_DESCRIZIONEHOME}
                    <br />
                    <br />
                    <strong>Servizi erogati: </strong>
                    <br />
                    Pulizie - sanificazioni - derattizazioni - facchinaggio - movimentazione merce conto terzi-giardinaggio - manutenzione elettrica e
                    edile - traporti.
                    <br />
                </p>
                <hr></hr>
                <h2>Servizi</h2>
                <a href={process.env.REACT_APP_URL_BASE + '/ComunicazioniAnonime'}>
                    <button type='button' id='Comunicazioni'>
                        Comunicazioni
                    </button>
                </a>
                <a href={process.env.REACT_APP_URL_BASE + '/Preventivi'}>
                    <button type='button' id='Preventivi'>
                        Preventivi
                    </button>
                </a>
                <a href={process.env.REACT_APP_URL_BASE + '/Certificazioni'}>
                    <button type='button' id='Certificazioni'>
                        Certificazioni e Documenti
                    </button>
                </a>
                <hr></hr>
                <a href={process.env.REACT_APP_URL_BASE + '/Accesso'}>
                    <button type='button'>Area Riservata</button>
                </a>
                <hr></hr>
                <button onClick={Installazione}>Installa Applicazione</button>
            </div>
        </div>
    );
}

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';

const opzioniPaginazione = {
    rowsPerPageText: 'Righe per pagina',
    rangeSeparatorText: 'di',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Tutto',
};

const Tabelle = ({ value }) => {
    const [dati, setDati] = useState([]);
    let columns = [],
        Columns = [],
        data = [];

    useEffect(() => {
        const ottieniDati = async () => {
            try {
                const risposta = await axios.post(process.env.REACT_APP_URL_SERVER + '/' + value.url, {
                    Inizio: value?.Inizio,
                    Fine: value?.Fine,
                    Dipendente: value?.Dipendente,
                    Cantiere: value?.Cantiere,
                    Settimana: value.Settimana,
                });
                setDati(risposta.data);
            } catch (error) {
                console.log(error);
            }
        };
        ottieniDati();
    }, [value]);

    dati.forEach((element) => {
        let dato = {};
        for (const [key, value] of Object.entries(element)) {
            if (key === 'Giorno') {
                let SoloGiorno = value.slice(0, 10);
                dato[key] = SoloGiorno;
            } else if (key === 'IDutente' || key === 'ID') {
                //Non aggiungere se la tabella è quella dei piani di lavoro
            } else {
                dato[key] = value;
            }
        }

        data.push(dato);
        Columns = Object.keys(element);
    });

    Columns.forEach((element) => {
        if (element === 'ID' || element === 'IDutente') {
        } else if (element === 'CantiereLun') {
            columns.push({
                name: `Lunedì`,
                selector: (row) => row[element],
                cellExport: (row) => row[element],
                sortable: true,
                wrap: true,
                compact: true,
                center: true,
            });
        } else if (element === 'CantiereMar') {
            columns.push({
                name: `Martedì`,
                selector: (row) => row[element],
                cellExport: (row) => row[element],
                sortable: true,
                wrap: true,
                compact: true,
                center: true,
            });
        } else if (element === 'CantiereMer') {
            columns.push({
                name: `Mercoledì`,
                selector: (row) => row[element],
                cellExport: (row) => row[element],
                sortable: true,
                wrap: true,
                compact: true,
                center: true,
            });
        } else if (element === 'CantiereGio') {
            columns.push({
                name: `Giovedì`,
                selector: (row) => row[element],
                cellExport: (row) => row[element],
                sortable: true,
                wrap: true,
                compact: true,
                center: true,
            });
        } else if (element === 'CantiereVen') {
            columns.push({
                name: `Venerdì`,
                selector: (row) => row[element],
                cellExport: (row) => row[element],
                sortable: true,
                wrap: true,
                compact: true,
                center: true,
            });
        } else if (element === 'CantiereSab') {
            columns.push({
                name: `Sabato`,
                selector: (row) => row[element],
                cellExport: (row) => row[element],
                sortable: true,
                wrap: true,
                compact: true,
                center: true,
            });
        } else {
            columns.push({
                name: `${element}`,
                selector: (row) => row[element],
                cellExport: (row) => row[element],
                sortable: true,
                wrap: true,
                compact: true,
                center: true,
            });
        }
    });

    const tableData = {
        columns,
        data,
    };

    return (
        <DataTableExtensions {...tableData}>
            <DataTable
                title={value.url.replace(/([A-Z])/g, ' $1').trim()}
                defaultSortAsc={false}
                exportHeaders={true}
                fileName={value.url.replace(/([A-Z])/g, ' $1').trim()}
                pagination
                paginationComponentOptions={opzioniPaginazione}
                highlightOnHover
            />
        </DataTableExtensions>
    );
};

export default Tabelle;

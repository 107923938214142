import React, { useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';

import '../Component/CSS/IngressiUscite.css';
import { getOut } from '../App';
import axios from 'axios';
import { toast } from 'react-toastify';
import Clock from 'react-live-clock';

const OpzioniGeoLocalizzanti = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 100000,
};

export default function IngressiUscite() {
    getOut(11);
    const refCanvas = useRef();

    const Position = async () => {
        navigator.geolocation.getCurrentPosition((Posizione) => {
            if (parseInt(Posizione.coords.accuracy) < 50) {
                toast.success(`Accuratezza: ${parseInt(Posizione.coords.accuracy)} Metri`);
            } else {
                toast.error(`Accuratezza: ${parseInt(Posizione.coords.accuracy)} Metri`);
            }
        });
    };
    const TanaIn = (Posizione) => {
        const crd = Posizione.coords;
        console.log(
            `La Tua Posizione: 
            Latitudine : ${crd.latitude}
            Longitudine: ${crd.longitude}
            Più o meno ${crd.accuracy} Metri.`
        );
        InArea(Posizione.coords.latitude, Posizione.coords.longitude, 'Ingresso');
    };
    const TanaOut = (Posizione) => {
        const crd = Posizione.coords;
        console.log(
            `La Tua Posizione: 
            Latitudine : ${crd.latitude}
            Longitudine: ${crd.longitude}
            Più o meno ${crd.accuracy} Metri.`
        );
        InArea(Posizione.coords.latitude, Posizione.coords.longitude, 'Uscita');
    };
    const NoTana = (error) => {
        toast.error(`ERROR(${error.code}): ${error.message}`);
    };
    const InArea = async (_Lat, _Lon, _INorOUT) => {
        /* PORTONACCIO */
        let AltoDestraLatPORT = 41.90955902929891,
            BassoDestraLatPORT = 41.895936188014424,
            AltoSinistraLatPORT = 41.910341495798136,
            BassoSinistraLatPORT = 41.89707020476531;
        let AltoDestraLonPORT = 12.5426087142813,
            BassoDestraLonPORT = 12.52945516230574,
            AltoSinistraLonPORT = 12.530978656595734,
            BassoSinistraLonPORT = 12.544926142716797;

        /* TRIBUNALE RIMINI */
        let AltoDestraLatTRIB = 44.0483176,
            BassoDestraLatTRIB = 44.0466859,
            AltoSinistraLatTRIB = 44.0480778,
            BassoSinistraLatTRIB = 44.0468995;
        let AltoDestraLonTRIB = 12.5783281,
            BassoDestraLonTRIB = 12.5783551,
            AltoSinistraLonTRIB = 12.574644,
            BassoSinistraLonTRIB = 12.5738082;

        /* TRIBUNALE PESARO */
        let AltoDestraLatTRIBP = 43.906108902446796,
            BassoDestraLatTRIBP = 43.90507201587623,
            AltoSinistraLatTRIBP = 43.90606221073799,
            BassoSinistraLatTRIBP = 43.90501566282777;
        let AltoDestraLonTRIBP = 12.914146887679344,
            BassoDestraLonTRIBP = 12.914332368686994,
            AltoSinistraLonTRIBP = 12.912642927219732,
            BassoSinistraLonTRIBP = 12.912747958633702;

        /* TRIBUNALE URBINO */
        let AltoDestraLatTRIBU = 43.72826995995184,
            BassoDestraLatTRIBU = 43.72774560189686,
            AltoSinistraLatTRIBU = 43.72822662474993,
            BassoSinistraLatTRIBU = 43.72760548340418;
        let AltoDestraLonTRIBU = 12.634941934849639,
            BassoDestraLonTRIBU = 12.63572353109624,
            AltoSinistraLonTRIBU = 12.634410209526939,
            BassoSinistraLonTRIBU = 12.634750033982868;

        /* CCIAO PESARO */
        let AltoDestraLatCCIAO = 43.912097867802885,
            BassoDestraLatCCIAO = 43.91159821842739,
            AltoSinistraLatCCIAO = 43.9121224809641,
            BassoSinistraLatCCIAO = 43.9116827242811;

        let AltoDestraLonCCIAO = 12.910759574859672,
            BassoDestraLonCCIAO = 12.910621773003252,
            AltoSinistraLonCCIAO = 12.910214061742481,
            BassoSinistraLonCCIAO = 12.910078537602624;

        /* MOTORIZZAZIONE CIVILE PESARO */
        let AltoDestraLatMOT = 43.89256077312724,
            BassoDestraLatMOT = 43.891646124985535,
            AltoSinistraLatMOT = 43.892429693841855,
            BassoSinistraLatMOT = 43.89129366128339;
        let AltoDestraLonMOT = 12.869662412324425,
            BassoDestraLonMOT = 12.87031318286117,
            AltoSinistraLonMOT = 12.868259819925356,
            BassoSinistraLonMOT = 12.868356829197914;

        /* COMM TRIBUTARIA PESARO */
        let AltoDestraLatCOMM = 43.90684353366331,
            BassoDestraLatCOMM = 43.9064212926073,
            AltoSinistraLatCOMM = 43.906881216357995,
            BassoSinistraLatCOMM = 43.906518881767404;
        let AltoDestraLonCOMM = 12.911045067495246,
            BassoDestraLonCOMM = 12.910992764422625,
            AltoSinistraLonCOMM = 12.910158597469488,
            BassoSinistraLonCOMM = 12.910064720159651;

        /* RAGIONERIA TERRITORIALE PESARO */
        let AltoDestraLatRAG = 43.91335805133363,
            BassoDestraLatRAG = 43.91254522836469,
            AltoSinistraLatRAG = 43.91332173394856,
            BassoSinistraLatRAG = 43.912344615030214;
        let AltoDestraLonRAG = 12.907740829968413,
            BassoDestraLonRAG = 12.907815249937823,
            AltoSinistraLonRAG = 12.906384466009866,
            BassoSinistraLonRAG = 12.9064564853351;

        /* PREFETTURA PESARO */
        let AltoDestraLatPREF = 43.910618874565685,
            BassoDestraLatPREF = 43.909416212048164,
            AltoSinistraLatPREF = 43.91059849065768,
            BassoSinistraLatPREF = 43.909394371698035;
        let AltoDestraLonPREF = 12.913092950972025,
            BassoDestraLonPREF = 12.91320612845434,
            AltoSinistraLonPREF = 12.91141549900203,
            BassoSinistraLonPREF = 12.911603454460788;

        if (
            (_Lat <= AltoDestraLatPORT &&
                _Lat <= AltoSinistraLatPORT &&
                _Lat >= BassoDestraLatPORT &&
                _Lat >= BassoSinistraLatPORT &&
                _Lon <= AltoDestraLonPORT &&
                _Lon >= AltoSinistraLonPORT &&
                _Lon >= BassoDestraLonPORT &&
                _Lon <= BassoSinistraLonPORT) ||
            (_Lat <= AltoDestraLatTRIB &&
                _Lat <= AltoSinistraLatTRIB &&
                _Lat >= BassoDestraLatTRIB &&
                _Lat >= BassoSinistraLatTRIB &&
                _Lon <= AltoDestraLonTRIB &&
                _Lon >= AltoSinistraLonTRIB &&
                _Lon >= BassoDestraLonTRIB &&
                _Lon <= BassoSinistraLonTRIB) ||
            (_Lat <= AltoDestraLatTRIBP &&
                _Lat <= AltoSinistraLatTRIBP &&
                _Lat >= BassoDestraLatTRIBP &&
                _Lat >= BassoSinistraLatTRIBP &&
                _Lon <= AltoDestraLonTRIBP &&
                _Lon >= AltoSinistraLonTRIBP &&
                _Lon >= BassoDestraLonTRIBP &&
                _Lon <= BassoSinistraLonTRIBP) ||
            (_Lat <= AltoDestraLatTRIBU &&
                _Lat <= AltoSinistraLatTRIBU &&
                _Lat >= BassoDestraLatTRIBU &&
                _Lat >= BassoSinistraLatTRIBU &&
                _Lon <= AltoDestraLonTRIBU &&
                _Lon >= AltoSinistraLonTRIBU &&
                _Lon >= BassoDestraLonTRIBU &&
                _Lon <= BassoSinistraLonTRIBU) ||
            (_Lat <= AltoDestraLatCCIAO &&
                _Lat <= AltoSinistraLatCCIAO &&
                _Lat >= BassoDestraLatCCIAO &&
                _Lat >= BassoSinistraLatCCIAO &&
                _Lon <= AltoDestraLonCCIAO &&
                _Lon >= AltoSinistraLonCCIAO &&
                _Lon >= BassoDestraLonCCIAO &&
                _Lon <= BassoSinistraLonCCIAO) ||
            (_Lat <= AltoDestraLatMOT &&
                _Lat <= AltoSinistraLatMOT &&
                _Lat >= BassoDestraLatMOT &&
                _Lat >= BassoSinistraLatMOT &&
                _Lon <= AltoDestraLonMOT &&
                _Lon >= AltoSinistraLonMOT &&
                _Lon >= BassoDestraLonMOT &&
                _Lon <= BassoSinistraLonMOT) ||
            (_Lat <= AltoDestraLatCOMM &&
                _Lat <= AltoSinistraLatCOMM &&
                _Lat >= BassoDestraLatCOMM &&
                _Lat >= BassoSinistraLatCOMM &&
                _Lon <= AltoDestraLonCOMM &&
                _Lon >= AltoSinistraLonCOMM &&
                _Lon >= BassoDestraLonCOMM &&
                _Lon <= BassoSinistraLonCOMM) ||
            (_Lat <= AltoDestraLatRAG &&
                _Lat <= AltoSinistraLatRAG &&
                _Lat >= BassoDestraLatRAG &&
                _Lat >= BassoSinistraLatRAG &&
                _Lon <= AltoDestraLonRAG &&
                _Lon >= AltoSinistraLonRAG &&
                _Lon >= BassoDestraLonRAG &&
                _Lon <= BassoSinistraLonRAG) ||
            (_Lat <= AltoDestraLatPREF &&
                _Lat <= AltoSinistraLatPREF &&
                _Lat >= BassoDestraLatPREF &&
                _Lat >= BassoSinistraLatPREF &&
                _Lon <= AltoDestraLonPREF &&
                _Lon >= AltoSinistraLonPREF &&
                _Lon >= BassoDestraLonPREF &&
                _Lon <= BassoSinistraLonPREF)
        ) {
            const canvas = refCanvas.current.toDataURL();

            let NomeFile = parseInt(new Date().getTime() / 1000);
            let Offset = new Date().getTimezoneOffset();

            if (Offset === -120) {
                NomeFile += 7200;
            } else if (Offset === -60) {
                NomeFile += 3600;
            }
            let timbratura = NomeFile;

            NomeFile = NomeFile.toString() + '.png';

            let dipendente = window.sessionStorage.getItem('Nome');

            await axios.post(`${process.env.REACT_APP_URL_SERVER}/SalvaFirma`, { NomeFile: NomeFile, Canvas: canvas });
            if (_INorOUT === 'Ingresso') {
                await axios.put(`${process.env.REACT_APP_URL_SERVER}/TimbraturaIngresso`, {
                    Immagine: NomeFile,
                    Ingresso: timbratura,
                    Nome: dipendente,
                });
            } else {
                await axios.put(`${process.env.REACT_APP_URL_SERVER}/TimbraturaUscita`, {
                    Immagine: NomeFile,
                    Uscita: timbratura,
                    Nome: dipendente,
                });
            }

            document.getElementById('Inizio').style.display = 'none';
            document.getElementById('Fine').style.display = 'none';

            toast.success(`Timbratura di ${_INorOUT} inserita correttamente.`);
            refCanvas.current.clear();
        } else {
            toast.error('Non sei nella zona di Timbratura.');
        }
    };

    const CreaImmagine = async (_tipo) => {
        Position();
        if (_tipo === 'Ingresso') {
            navigator.geolocation.getCurrentPosition(TanaIn, NoTana, OpzioniGeoLocalizzanti);
        } else {
            navigator.geolocation.getCurrentPosition(TanaOut, NoTana, OpzioniGeoLocalizzanti);
        }
    };

    return (
        <div id='Contenuto'>
            {process.env.REACT_APP_TIPOSITO === 'SVILUPPO' ? (
                <h1 style={{ color: 'red', textDecoration: 'underline' }}>SVILUPPO</h1>
            ) : (
                <h1>Timbrature</h1>
            )}

            <div id='IngressiUscite'>
                <div id='Timbrature'>
                    <h2>Orario</h2>
                    <Clock format='HH:mm:ss' interval={1000} ticking={true} style={{ fontSize: '2.5em' }} />
                    <br></br>
                </div>

                <h2>Firma</h2>
                <SignatureCanvas penColor='black' canvasProps={{ className: 'sigCanvas' }} ref={refCanvas} id='CanvasFirme' />

                <button type='button' id='Inizio' onClick={() => CreaImmagine('Ingresso')}>
                    Ingresso
                </button>
                <button type='button' id='Fine' onClick={() => CreaImmagine('Uscita')}>
                    Uscita
                </button>

                <hr></hr>

                <button type='button' onClick={() => refCanvas.current.clear()}>
                    Cancella Firma
                </button>
                <hr></hr>
            </div>
        </div>
    );
}

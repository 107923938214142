import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../Component/CSS/Scansioni.css';
import { getOut } from '../App';
import { toast } from 'react-toastify';
import axios from 'axios';

export default function Scansioni() {
    getOut(-1);
    const Navigate = useNavigate();
    const [abilitato, setAbilitato] = useState(false);

    const ReadNFC = async () => {
        if ('NDEFReader' in window) {
            setAbilitato(true);
            try {
                const ndef = new window.NDEFReader();
                const controller = new AbortController();
                controller.signal.onabort = (e) => {
                    /* We're done waiting for NFC tags. */
                };
                return new Promise((resolve, reject) => {
                    controller.signal.onabort = reject;

                    ndef.scan({ signal: controller.signal })
                        .then(() => {
                            ndef.onreading = async (event) => {
                                const ndefMessage = event.message;
                                let ID_TAG;
                                let GiornoPulizia = new Date();
                                let Prima = new Date();
                                let Dopo = new Date();
                                let Offset = GiornoPulizia.getTimezoneOffset() * 60;
                                GiornoPulizia = GiornoPulizia.getTime() / 1000 - Offset;
                                Prima = Prima.setHours(0, 0, 0, 0) / 1000;
                                Dopo = Dopo.setHours(0, 0, 0, 0) / 1000;
                                Dopo = Dopo + 86300;

                                for (const record of ndefMessage.records) {
                                    ID_TAG = record.id;
                                }

                                await axios.put(`${process.env.REACT_APP_URL_SERVER}/AggiornaStatoPulizia`, {
                                    Stato: 'Svolto',
                                    DataSvolgimento: GiornoPulizia,
                                    IDTAG: ID_TAG,
                                    Prima: parseInt(Prima),
                                    Dopo: parseInt(Dopo),
                                });
                                toast.success(`Tag ID: ${ID_TAG}`);

                                resolve(event);
                            };

                            ndef.onreadingerror = (event) => {
                                toast.error('Errore! Riprovare?');
                            };
                        })
                        .catch((error) => {
                            reject(error);
                            toast.error(`Errore! ${error}.`);
                        });
                });
            } catch (error) {
                toast.error(error);
            }
        } else {
            toast.error('Non Puoi scansionare. Il tuo dispositivo non supporta la funzionalità.');
        }
    };

    return (
        <div id='Contenuto'>
            {process.env.REACT_APP_TIPOSITO === 'SVILUPPO' ? (
                <h1 style={{ color: 'red', textDecoration: 'underline' }}>SVILUPPO</h1>
            ) : (
                <h1>Scansioni</h1>
            )}

            <div id='Scansioni'>
                <hr></hr>
                <hr></hr>
                <button type='button' name='nfc' disabled={abilitato} onClick={ReadNFC}>
                    Scansiona TAG
                </button>
                <hr></hr>
                <hr></hr>
                {window.sessionStorage.getItem('ProUser') === '11' ? <button onClick={() => Navigate('/Programma')}>Programma NFC</button> : ''}
            </div>
        </div>
    );
}

//window.location.assign(`${process.env.REACT_APP_URL_BASE}/Programma`;

import { createRoot } from 'react-dom/client';

import '../Component/CSS/Customer.css';
import { getOut } from '../App';
import axios from 'axios';
import { toast } from 'react-toastify';
import {
	IoSkullOutline,
	IoSadOutline,
	IoRemoveCircleOutline,
	IoAddCircleOutline,
	IoHappyOutline,
} from 'react-icons/io5';

const level = sessionStorage.getItem('ProUser');
let root;
const Question = [
	{
		ID: 1,
		Domanda: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla sed
            facilisis velit. Vestibulum ipsum arcu, vehicula id justo eget,
            ornare venenatis ante. Duis pharetra ornare semper. Cras ex turpis,
            aliquam malesuada dignissim vitae, hendrerit vel leo. Nunc aliquet
            imperdiet tortor in lacinia. Curabitur ornare lorem vel leo luctus
            dictum. Sed in ultrices felis. Cras vehicula consectetur porttitor.
            Praesent in rhoncus quam, nec varius diam. Maecenas sed mi id lectus
            pellentesque pellentesque id eget lacus. Aenean eleifend gravida
            dolor. Nulla diam nunc, ultricies a egestas a, sodales vel leo. Nam
            eget quam porttitor, aliquam libero ac, mollis erat. 1`,
	},
	{
		ID: 2,
		Domanda: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla sed
            facilisis velit. Vestibulum ipsum arcu, vehicula id justo eget,
            ornare venenatis ante. Duis pharetra ornare semper. Cras ex turpis,
            aliquam malesuada dignissim vitae, hendrerit vel leo. Nunc aliquet
            imperdiet tortor in lacinia. Curabitur ornare lorem vel leo luctus
            dictum. Sed in ultrices felis. Cras vehicula consectetur porttitor.
            Praesent in rhoncus quam, nec varius diam. Maecenas sed mi id lectus
            pellentesque pellentesque id eget lacus. Aenean eleifend gravida
            dolor. Nulla diam nunc, ultricies a egestas a, sodales vel leo. Nam
            eget quam porttitor, aliquam libero ac, mollis erat. 2`,
	},
	{
		ID: 3,
		Domanda: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla sed
            facilisis velit. Vestibulum ipsum arcu, vehicula id justo eget,
            ornare venenatis ante. Duis pharetra ornare semper. Cras ex turpis,
            aliquam malesuada dignissim vitae, hendrerit vel leo. Nunc aliquet
            imperdiet tortor in lacinia. Curabitur ornare lorem vel leo luctus
            dictum. Sed in ultrices felis. Cras vehicula consectetur porttitor.
            Praesent in rhoncus quam, nec varius diam. Maecenas sed mi id lectus
            pellentesque pellentesque id eget lacus. Aenean eleifend gravida
            dolor. Nulla diam nunc, ultricies a egestas a, sodales vel leo. Nam
            eget quam porttitor, aliquam libero ac, mollis erat. 3`,
	},
	{
		ID: 4,
		Domanda: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla sed
            facilisis velit. Vestibulum ipsum arcu, vehicula id justo eget,
            ornare venenatis ante. Duis pharetra ornare semper. Cras ex turpis,
            aliquam malesuada dignissim vitae, hendrerit vel leo. Nunc aliquet
            imperdiet tortor in lacinia. Curabitur ornare lorem vel leo luctus
            dictum. Sed in ultrices felis. Cras vehicula consectetur porttitor.
            Praesent in rhoncus quam, nec varius diam. Maecenas sed mi id lectus
            pellentesque pellentesque id eget lacus. Aenean eleifend gravida
            dolor. Nulla diam nunc, ultricies a egestas a, sodales vel leo. Nam
            eget quam porttitor, aliquam libero ac, mollis erat. 4`,
	},
	{
		ID: 5,
		Domanda: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla sed
            facilisis velit. Vestibulum ipsum arcu, vehicula id justo eget,
            ornare venenatis ante. Duis pharetra ornare semper. Cras ex turpis,
            aliquam malesuada dignissim vitae, hendrerit vel leo. Nunc aliquet
            imperdiet tortor in lacinia. Curabitur ornare lorem vel leo luctus
            dictum. Sed in ultrices felis. Cras vehicula consectetur porttitor.
            Praesent in rhoncus quam, nec varius diam. Maecenas sed mi id lectus
            pellentesque pellentesque id eget lacus. Aenean eleifend gravida
            dolor. Nulla diam nunc, ultricies a egestas a, sodales vel leo. Nam
            eget quam porttitor, aliquam libero ac, mollis erat. 5`,
	},
	{ ID: 1000, Domanda: 'Stocazzo? FINE' },
];
const DomandeRisposte = (Q) => {
	return Q.map((el) => {
		return { ID: el.ID, Valore: 0 };
	});
};
const risultato = DomandeRisposte(Question);

let Conclusione = {
	TipoUtente: { Nome: '', Valore: 0 },
	Edificio: { Nome: '', Valore: 0 },
	Servizio: { Nome: '', Valore: 0 },
	Score: 0,
	Data: 0,
	Risposte: risultato,
};

const OnSelect = (_apri, _cosa, _selected) => {
	_apri.forEach((element) => {
		if (element === 'Domanda' || element === 'Voti') {
			Conclusione.Servizio = { Nome: _selected, Valore: _cosa };
			document.getElementById(element).style.display = 'flex';
			document.getElementById('Progressivo').innerHTML = `Domanda N° ${Question.at(0).ID}`;
			document.getElementById('Domanda').innerHTML = Question.at(0).Domanda;

			for (let i = 0; i < document.getElementById('Service').options.length; i++) {
				document.getElementById('Service').options[i].disabled = true;
			}
		} else if (element === 'Service') {
			Conclusione.Edificio = { Nome: _selected, Valore: _cosa };
			document.getElementById(element).style.display = 'block';

			for (let i = 0; i < document.getElementById('Edificio').options.length; i++) {
				document.getElementById('Edificio').options[i].disabled = true;
			}
		} else if (element === 'Edificio') {
			Conclusione.TipoUtente = { Nome: _selected, Valore: _cosa };
			document.getElementById(element).style.display = 'block';

			for (let i = 0; i < document.getElementById('TipoUtente').options.length; i++) {
				document.getElementById('TipoUtente').options[i].disabled = true;
			}
		}
	});
};

const Next = async (_valore) => {
	let Step = Number(document.getElementById('Domanda').dataset.value);
	Conclusione.Score += _valore;
	Conclusione.Risposte[Step].Valore = _valore;

	if (Step !== Question.length - 1) {
		document.getElementById('Domanda').dataset.value = Step + 1;
		document.getElementById('Domanda').innerHTML = Question.at(Step + 1).Domanda;
		document.getElementById('Progressivo').innerHTML = `Domanda N° ${Question.at(Step + 1).ID}`;
	} else {
		document.getElementById('Progressivo').style.display = 'none';
		document.getElementById('Voti').style.display = 'none';
		document.getElementById('Domanda').innerHTML = `<h2>Grazie della Partecipazione!</h2>`;
		toast.success('Tocca "Ricomincia" per effettuare un nuovo Quiz');

		Conclusione.Score = Math.ceil(
			(Conclusione.Score * Conclusione.TipoUtente.Valore * Conclusione.Edificio.Valore) /
				Question.length
		);
		let data = Math.floor(Date.now() / 1000);
		Conclusione.Data = data;

		await axios.post(`${process.env.REACT_APP_URL_SERVER}/Customer`, {
			Conclusione,
		});

		document.getElementById('Domanda').dataset.value = 0;
		Conclusione = {
			TipoUtente: { Nome: '', Valore: 0 },
			Edificio: { Nome: '', Valore: 0 },
			Servizio: { Nome: '', Valore: 0 },
			Score: 0,
			Data: 0,
			Risposte: risultato,
		};
	}
};

let Reportistica = {
	TipoUtente: '',
	Edificio: '',
	Servizio: '',
};
const Report = (_chi, _cosa) => {
	Reportistica[_chi] = _cosa;
};
const Finale = async () => {
	let Valore = 0;
	let Icona;

	const Smile = document.getElementById('Smile');
	const Risultati = await axios.get(`${process.env.REACT_APP_URL_SERVER}/GetReport`, {
		params: {
			TipoUtente: Reportistica.TipoUtente,
			Edificio: Reportistica.Edificio,
			Servizio: Reportistica.Servizio,
		},
	});

	const MedieDiMedie = (_R) => {
		_R.map((el) => {
			return (Valore += el.Media);
		});
	};

	MedieDiMedie(Risultati.data);

	let Totali = Risultati.data.length;
	let Media = Totali ? Math.ceil(Valore / Totali) : 0;

	document.getElementById('Quanti').innerHTML = `Totale Test Somministrati: ${Totali}`;
	document.getElementById('Media').innerHTML = `Media sui Test Somministrati: ${Media}`;

	if (Media === 0) {
		// non fare nulla
	} else if (Media < 20) {
		Icona = <IoSkullOutline style={{ color: 'red' }} />;
	} else if (Media >= 20 && Media < 40) {
		Icona = <IoSadOutline style={{ color: 'Orange' }} />;
	} else if (Media >= 40 && Media < 60) {
		Icona = <IoRemoveCircleOutline style={{ color: 'gold' }} />;
	} else if (Media >= 60 && Media < 80) {
		Icona = <IoAddCircleOutline style={{ color: 'LimeGreen' }} />;
	} else if (Media >= 80) {
		Icona = <IoHappyOutline style={{ color: 'green' }} />;
	}

	if (!root) {
		root = createRoot(Smile);
		root.render(Icona);
	} else {
		root.render(Icona);
	}
};
export default function Customer() {
	getOut(11);

	return (
		<div id='Contenuto'>
			{process.env.REACT_APP_TIPOSITO === 'SVILUPPO' ? (
				<h1 style={{ color: 'red', textDecoration: 'underline' }}>SVILUPPO</h1>
			) : (
				<h1>Customer</h1>
			)}

			<div id='Customer'>
				<div id='BOX'>
					<div id='Scelte'>
						<div>
							<select
								id='TipoUtente'
								onChange={(event) =>
									OnSelect(
										['Edificio'],
										event.target.value,
										event.target.selectedOptions[0].textContent
									)
								}
							>
								<option label='Tipo Utente' value='-1'></option>
								<option value='10'>Studente</option>
								<option value='5'>Amministrativo</option>
								<option value='2'>Operatore</option>
							</select>
						</div>
						<div>
							<select
								id='Edificio'
								onChange={(event) =>
									OnSelect(
										['Service'],
										event.target.value,
										event.target.selectedOptions[0].textContent
									)
								}
							>
								<option label='Edificio' value='-1'></option>
								<option value='1'>A</option>
								<option value='1'>B</option>
								<option value='2'>C</option>
								<option value='5'>D</option>
								<option value='7'>E</option>
								<option value='4'>F</option>
								<option value='3'>G</option>
								<option value='2'>H</option>
								<option value='8'>I</option>
								<option value='6'>L</option>
							</select>
						</div>
						<div>
							<select
								id='Service'
								onChange={(event) =>
									OnSelect(
										['Domanda', 'Voti'],
										event.target.value,
										event.target.selectedOptions[0].textContent
									)
								}
							>
								<option label='Servizio' value='-1'></option>
								<option value='1'>Servizio X</option>
								<option value='3'>Servizio Y</option>
								<option value='4'>Servizio Z</option>
								<option value='5'>Servizio K</option>
							</select>
						</div>
					</div>
					<div id='Domande' style={{ marginBottom: '100px' }}>
						<span>
							<h2 id='Progressivo'>
								<strong>
									Scegli dal menu a fianco per iniziare il questionario
								</strong>
							</h2>
						</span>
						<span>
							<p id='Domanda' data-value={0}></p>
						</span>
						<div id='Voti'>
							<IoSkullOutline
								style={{ width: '47%', height: '47%', color: 'Red' }}
								onClick={() => Next(2)}
							/>
							<IoSadOutline
								style={{ width: '50%', height: '50%', color: 'Orange' }}
								onClick={() => Next(4)}
							/>
							<IoRemoveCircleOutline
								style={{
									width: '50%',
									height: '50%',
									color: 'gold',
								}}
								onClick={() => Next(6)}
							/>
							<IoAddCircleOutline
								style={{ width: '50%', height: '50%', color: 'LimeGreen' }}
								onClick={() => Next(8)}
							/>
							<IoHappyOutline
								style={{ width: '50%', height: '50%', color: 'green' }}
								onClick={() => Next(10)}
							/>
						</div>
					</div>
					<button type='button' onClick={() => window.location.reload()}>
						Ricomincia
					</button>
				</div>
				{level >= 11 && (
					<div id='BoxReport' style={{ marginTop: '50px', marginBottom: '50px' }}>
						<div id='SceltaReport'>
							<h2>AMMINISTRATORE</h2>
							<select
								id='SceltoUtente'
								onChange={(event) => {
									Report(
										'TipoUtente',
										event.target.selectedOptions[0].textContent
									);
								}}
							>
								<option label='Tipo Utente' value=''></option>
								<option value=''>Studente</option>
								<option value=''>Amministrativo</option>
								<option value=''>Operatore</option>
							</select>
							<select
								id='SceltoEdificio'
								onChange={(event) => {
									Report('Edificio', event.target.selectedOptions[0].textContent);
								}}
							>
								<option label='Edificio' value=''></option>
								<option value=''>A</option>
								<option value=''>B</option>
								<option value=''>C</option>
								<option value=''>D</option>
								<option value=''>E</option>
								<option value=''>F</option>
								<option value=''>G</option>
								<option value=''>H</option>
								<option value=''>I</option>
								<option value=''>L</option>
							</select>
							<select
								id='SceltoService'
								style={{ marginBottom: '50px' }}
								onChange={(event) => {
									Report('Servizio', event.target.selectedOptions[0].textContent);
								}}
							>
								<option label='Servizio' value=''></option>
								<option value=''>Servizio X</option>
								<option value=''>Servizio Y</option>
								<option value=''>Servizio Z</option>
								<option value=''>Servizio K</option>
							</select>
							<button
								type='button'
								onClick={() => {
									Finale();
								}}
							>
								Ottieni i Risultati
							</button>
							<sub>
								Per Tutti i Risultati senza filtri <br /> clicca il bottone senza
								selezionare nulla
							</sub>
						</div>
						<div id='Report'>
							<h2 id='Quanti'> </h2>
							<h2 id='Media'> </h2>
							<div id='Smile'> </div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
}

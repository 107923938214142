import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { LogOut } from '../App';
import '../Component/CSS/Turni.css';
import Tabelle from '../Component/Tabelle';
import { toast } from 'react-toastify';
import Select from 'react-select';

export default function Turni() {
    function getOut() {
        let LivelloUtenza = parseInt(sessionStorage.getItem('ProUser'));
        if (LivelloUtenza >= 0 && LivelloUtenza !== 2) {
        } else {
            LogOut();
        }
    }
    getOut();

    const Cantiere = [
        { value: '', label: 'Nessuno' },

        { value: 'Roma', label: 'Roma' },
        { value: 'Roma 35', label: 'Roma 35' },
        { value: 'Rag. Pesaro', label: 'Rag. Pesaro' },
        { value: 'Trib. Pesaro', label: 'Trib.Pesaro' },
        { value: 'Pref. Pesaro', label: 'Pref. Pesaro' },
        { value: 'CC Pesaro', label: 'CC Pesaro' },
        { value: 'Mot. Pesaro', label: 'Mot. Pesaro' },
        { value: 'Proc. Latina', label: 'Proc. Latina' },
        { value: 'Trib. Latina', label: 'Trib. Latina' },
        { value: 'Giud. Pace', label: 'Giud. Pace' },
        { value: 'Trib. Frosinone', label: 'Trib. Frosinone' },
        { value: 'Uni. Urbino', label: 'Uni. Urbino' },
        { value: 'Trib. Urbino', label: 'Trib. Urbino' },
        { value: 'IDER', label: 'IDER' },
        { value: 'Trib. Rimini', label: 'Trib. Rimini' },

        { value: 'Ferie', label: 'Ferie' },
        { value: 'Permesso', label: 'Permesso' },
        { value: 'Malattia', label: 'Malattia' },
        { value: 'Infortunio', label: 'Infortunio' },
        { value: 'Aspettativa', label: 'Aspettativa' },
        { value: '104', label: '104' },
        { value: 'Sospensione', label: 'Sospensione' },
    ];
    const Mansione = [
        { value: '', label: 'Nessuno' },
        { value: 'Amministrativo', label: 'Amministrativo' },
        { value: 'Facchino', label: 'Facchino' },
        { value: 'Operatore', label: 'Operatore' },
        { value: 'Operatore Pulizie', label: 'Operatore Pulizie' },
        { value: 'Receptionist', label: 'Receptionist' },
    ];

    const customStyles = {
        menuPortal: (provided) => ({
            ...provided,
            height: 50,
            minWidth: '170px',
            fontSize: '15px',
            alignContent: 'center',
        }),
        control: (provided) => ({
            ...provided,
            height: 50,
            minWidth: '170px',
            fontSize: '15px',
            alignContent: 'center',
        }),
        option: (provided) => ({
            ...provided,
            height: 50,
            minWidth: '170px',
            fontSize: '15px',
        }),
        singleValue: (provided) => ({
            ...provided,
            fontSize: '15px',
        }),
        valueContainer: (provided) => ({
            ...provided,
            height: 50,
            minWidth: '170px',
            fontSize: '15px',
            alignContent: 'center',
        }),
        placeholder: (provided) => ({
            ...provided,
            height: 50,
            fontSize: '15px',
        }),
        menu: (provided) => ({
            ...provided,
            minWidth: '170px',
            fontSize: '15px',
        }),
    };

    const level = sessionStorage.getItem('ProUser'),
        [pianoData, setPianoData] = useState(null),
        [settimane, setSettimane] = useState([]),
        [settimana, setSettimana] = useState({ value: '', label: '' }),
        [utenti, setUtenti] = useState([]),
        [utenteScelto, setUtenteScelto] = useState({ value: '', label: '' }),
        [datiUtente, setDatiUtente] = useState([]);

    const ScegliUtente = async (selectedOption) => {
        setUtenteScelto(selectedOption);

        const DatiDipendente = await axios.get(`${process.env.REACT_APP_URL_SERVER}/OttieniPianoUtente/${selectedOption.value}`);
        let arr = [];
        let result = DatiDipendente.data;

        result.map((data) => {
            return arr.push({
                IDutente: data.IDutente,
                Mansione: data.Mansione,
                Attacco: data.Attacco,
                CantiereLun: data.CantiereLun,
                CantiereMar: data.CantiereMar,
                CantiereMer: data.CantiereMer,
                CantiereGio: data.CantiereGio,
                CantiereVen: data.CantiereVen,
                CantiereSab: data.CantiereSab,
                OreSettimanali: data.OreSettimanali,
                Note: data.Note,
            });
        });

        setDatiUtente(arr[0]);
    };

    const UpdatePianoAttuale = async () => {
        try {
            const DatiDipendente = await axios.post(`${process.env.REACT_APP_URL_SERVER}/UpdatePianoUtente`, datiUtente);
            if (DatiDipendente) {
                setUtenteScelto({ value: '', label: '' });
                setDatiUtente([]);
                toast.success(`Piano dell'utente ${utenteScelto.label} aggiornato correttamente`);
            }
        } catch (error) {
            toast.error(`C'è stato un problema: ${error}`);
        }
    };

    useEffect(() => {
        // Rende la settimana attuale per l'utente connesso
        const OttieniPianoLavoro = async () => {
            const response = await axios.post(`${process.env.REACT_APP_URL_SERVER}/OttieniPianoLavoro`, {
                IDutente: sessionStorage.getItem('IDutente'),
            });
            const dati = response.data[0];
            if (dati) {
                const Settimana = `${dati['Settimana'].substring(0, 10).split('-').reverse().join('/')} - ${dati['Settimana']
                    .substring(13)
                    .split('-')
                    .reverse()
                    .join('/')}`;

                setPianoData(
                    <>
                        <div
                            id='TurniSettimanali'
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                border: '1px solid black',
                                borderRadius: '15%',
                                marginTop: '10px',
                                padding: '30px',
                                width: '60vw',
                                maxWidth: '60vw',
                                minWidth: '60vw',
                            }}>
                            <h2>
                                Piano di Lavoro della Settimana: <br />
                                {Settimana}
                            </h2>
                            <br />
                            <span>
                                <strong>Nome: </strong>
                                <br />
                                <i>{dati['Nome']}</i>
                            </span>
                            <span>
                                <strong>Mansione: </strong>
                                <br />
                                <i>{dati['Mansione']}</i>
                            </span>
                            <br />
                            <span>
                                <strong>Lunedì: </strong>
                                <br />
                                <i>{dati['CantiereLun']}</i>
                            </span>
                            <span>
                                <strong>Martedì: </strong>
                                <br />
                                <i>{dati['CantiereMar']}</i>
                            </span>
                            <span>
                                <strong>Mercoledì: </strong>
                                <br />
                                <i>{dati['CantiereMer']}</i>
                            </span>
                            <span>
                                <strong>Giovedì: </strong>
                                <br />
                                <i>{dati['CantiereGio']}</i>
                            </span>
                            <span>
                                <strong>Venerdì: </strong>
                                <br />
                                <i>{dati['CantiereVen']}</i>
                            </span>
                            <span>
                                <strong>Sabato: </strong>
                                <br />
                                <i>{dati['CantiereSab']}</i>
                            </span>
                            <br />
                            <span>
                                <strong>Orario Attacco: </strong>
                                <br />
                                <i>{dati['Attacco']}</i>
                            </span>
                            <span>
                                <strong>Ore Settimanali: </strong>
                                <br />
                                <i>{dati['OreSettimanali']}</i>
                            </span>
                            <span>
                                <strong>Ore Giornaliere: </strong>
                                <br />
                                <i>{dati['OreGiorno']}</i>
                            </span>
                            <span>
                                <strong>Note: </strong>
                                <br />
                                <i>{dati['Note']}</i>
                            </span>
                        </div>
                    </>
                );
            }
        };

        // Ottiene le settimane disponibili su pianilavoro
        const OttieniSettimane = async () => {
            const response = await axios.get(`${process.env.REACT_APP_URL_SERVER}/OttieniSettimane`);
            const Settimane = response.data.map((item) => ({
                value: item.Settimana,
                label: item.Settimana,
            }));
            setSettimane(Settimane);
        };

        // Ottiene tutti i dipendenti attivi
        const OttieniUtenti = async () => {
            const arr = [];
            await axios.get(`${process.env.REACT_APP_URL_SERVER}/DipendentiAttivi`).then((res) => {
                let result = res.data;
                result.map((data) => {
                    return arr.push({ value: data.ID, label: data.Dipendente });
                });
                setUtenti(arr);
            });
        };

        OttieniSettimane();
        OttieniPianoLavoro();
        OttieniUtenti();
    }, []);

    return (
        <div id='Contenuto'>
            <div id='Turni'>
                {process.env.REACT_APP_TIPOSITO === 'SVILUPPO' ? (
                    <h1 style={{ color: 'red', textDecoration: 'underline' }}>SVILUPPO</h1>
                ) : (
                    <h1>Turni</h1>
                )}

                {level >= 5 && (
                    <>
                        <div id='Input'>
                            <div>
                                <p htmlFor='SceltaSettimana' className='ComeLabel'>
                                    Settimana
                                </p>
                                <Select
                                    styles={customStyles}
                                    options={settimane}
                                    name='SceltaSettimana'
                                    id='SceltaSettimana'
                                    onChange={(selectedOption) => {
                                        setSettimana({ value: selectedOption.value, label: selectedOption.label });
                                    }}
                                />
                                <sub>Mostra nella tabella il piano della settimana scelta</sub>
                                <hr></hr>
                            </div>

                            <div id='Pulsantiera'>
                                <p htmlFor='Dipendente' className='ComeLabel'>
                                    Modifica Turno Di:
                                </p>
                                <Select
                                    styles={customStyles}
                                    options={utenti}
                                    name='Dipendente'
                                    value={utenteScelto}
                                    id='Dipendente'
                                    placeholder='Dipendente'
                                    onChange={ScegliUtente}
                                />
                                <sub>
                                    Modifica solamente i Turni della settimana corrente, non è possibile modificare i turni precedenti. <br />
                                    ANCHE SE AVETE SELEZIONATO UNA SETTIMANA DIFFERENTE DAL MENÙ A TENDINA PRECEDENTE
                                </sub>
                            </div>

                            {utenteScelto.label && (
                                <div id='ModificaTurno'>
                                    <hr />
                                    <p htmlFor='Mansione' className='ComeLabel'>
                                        Mansione
                                    </p>
                                    <Select
                                        styles={customStyles}
                                        name='Mansione'
                                        id='mansione'
                                        options={Mansione}
                                        defaultValue={{ value: datiUtente?.Mansione, label: datiUtente?.Mansione }}
                                        key={Math.floor(Math.random() * 100000)}
                                        placeholder='Mansione'
                                        onChange={(selectedOption) => {
                                            datiUtente['Mansione'] = selectedOption.value;
                                        }}
                                    />
                                    <p htmlFor='modificaOrario' className='ComeLabel'>
                                        Orario Attacco
                                    </p>
                                    <Select
                                        styles={customStyles}
                                        key={Math.floor(Math.random() * 100000)}
                                        options={[
                                            ...Array.from({ length: 24 * 2 }, (_, i) => {
                                                const hours = Math.floor(i / 2);
                                                const minutes = i % 2 === 0 ? '00' : '30';
                                                const time = `${hours.toString().padStart(2, '0')}:${minutes}`;
                                                return { value: time, label: time };
                                            }),
                                        ]}
                                        defaultValue={{ value: datiUtente?.Attacco, label: datiUtente?.Attacco }}
                                        name='Attacco'
                                        id='modificaOrario'
                                        placeholder='Attacco'
                                        onChange={(selectedOption) => {
                                            datiUtente['Attacco'] = selectedOption.value;
                                        }}
                                    />

                                    <p htmlFor='CantiereLun' className='ComeLabel'>
                                        Cantiere Lun
                                    </p>
                                    <Select
                                        styles={customStyles}
                                        name='CantiereLun'
                                        id='CantiereLun'
                                        options={Cantiere}
                                        defaultValue={{ value: datiUtente?.CantiereLun, label: datiUtente?.CantiereLun }}
                                        key={Math.floor(Math.random() * 100000)}
                                        placeholder='Cantiere Lunedì'
                                        onChange={(selectedOption) => {
                                            datiUtente['CantiereLun'] = selectedOption.value;
                                        }}
                                    />
                                    <p htmlFor='CantiereMar' className='ComeLabel'>
                                        Cantiere Mar
                                    </p>
                                    <Select
                                        styles={customStyles}
                                        name='CantiereMar'
                                        id='CantiereMar'
                                        options={Cantiere}
                                        defaultValue={{ value: datiUtente?.CantiereMar, label: datiUtente?.CantiereMar }}
                                        key={Math.floor(Math.random() * 100000)}
                                        placeholder='Cantiere Martedì'
                                        onChange={(selectedOption) => {
                                            datiUtente['CantiereMar'] = selectedOption.value;
                                        }}
                                    />
                                    <p htmlFor='CantiereMer' className='ComeLabel'>
                                        Cantiere Mer
                                    </p>
                                    <Select
                                        styles={customStyles}
                                        name='CantiereMer'
                                        id='CantiereMer'
                                        options={Cantiere}
                                        defaultValue={{ value: datiUtente?.CantiereMer, label: datiUtente?.CantiereMer }}
                                        key={Math.floor(Math.random() * 100000)}
                                        placeholder='Cantiere Mercoledì'
                                        onChange={(selectedOption) => {
                                            datiUtente['CantiereMer'] = selectedOption.value;
                                        }}
                                    />
                                    <p htmlFor='CantiereGio' className='ComeLabel'>
                                        Cantiere Gio
                                    </p>
                                    <Select
                                        styles={customStyles}
                                        name='CantiereGio'
                                        id='CantiereGio'
                                        options={Cantiere}
                                        defaultValue={{ value: datiUtente?.CantiereGio, label: datiUtente?.CantiereGio }}
                                        key={Math.floor(Math.random() * 100000)}
                                        placeholder='Cantiere Giovedì'
                                        onChange={(selectedOption) => {
                                            datiUtente['CantiereGio'] = selectedOption.value;
                                        }}
                                    />
                                    <p htmlFor='CantiereVen' className='ComeLabel'>
                                        Cantiere Ven
                                    </p>
                                    <Select
                                        styles={customStyles}
                                        name='CantiereVen'
                                        id='CantiereVen'
                                        options={Cantiere}
                                        defaultValue={{ value: datiUtente?.CantiereVen, label: datiUtente?.CantiereVen }}
                                        key={Math.floor(Math.random() * 100000)}
                                        placeholder='Cantiere Venerdì'
                                        onChange={(selectedOption) => {
                                            datiUtente['CantiereVen'] = selectedOption.value;
                                        }}
                                    />
                                    <p htmlFor='CantiereSab' className='ComeLabel'>
                                        Cantiere Sab
                                    </p>
                                    <Select
                                        styles={customStyles}
                                        name='CantiereSab'
                                        id='CantiereSab'
                                        options={Cantiere}
                                        defaultValue={{ value: datiUtente?.CantiereSab, label: datiUtente?.CantiereSab }}
                                        key={Math.floor(Math.random() * 100000)}
                                        placeholder='Cantiere Sabato'
                                        onChange={(selectedOption) => {
                                            datiUtente['CantiereSab'] = selectedOption.value;
                                        }}
                                    />
                                    <label htmlFor='Note'>Note</label>
                                    <textarea
                                        id='Note'
                                        rows='5'
                                        cols='40'
                                        defaultValue={datiUtente?.Note}
                                        placeholder='Note'
                                        onChange={(e) => {
                                            datiUtente['Note'] = e.target.value;
                                        }}
                                    />
                                    <br />
                                    <br />
                                    <button type='button' onClick={UpdatePianoAttuale}>
                                        Aggiorna Piano Settimanale
                                    </button>
                                </div>
                            )}
                        </div>

                        <div id='Tabelle'>
                            <Tabelle
                                value={{
                                    url: 'TurniDaPiani',
                                    Settimana: settimana.label,
                                }}
                            />
                        </div>
                    </>
                )}
                {(Number(level) === 0 || Number(level) === 5) && pianoData}
            </div>
        </div>
    );
}

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DataTable from 'react-data-table-component';

import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';

const opzioniPaginazione = {
    rowsPerPageText: 'Righe per pagina',
    rangeSeparatorText: 'di',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Tutto',
};

const TabellaTimbrature = ({ value }) => {
    const [dati, setDati] = useState([]);
    let columns = [],
        data = [];

    useEffect(() => {
        const ottieniDati = async () => {
            try {
                const risposta = await axios.post(process.env.REACT_APP_URL_SERVER + '/' + value.url, {
                    Inizio: value.Inizio,
                    Fine: value.Fine,
                    Dipendente: value.Dipendente,
                });
                setDati(risposta.data);
            } catch (error) {
                console.log(error);
            }
        };
        ottieniDati();
    }, [value]);

    dati.forEach((element) => {
        let dato = {};
        for (const [key, value] of Object.entries(element)) {
            dato[key] = value;
        }
        data.push(dato);
    });

    const ApriFirma = (_immagine) => {
        document.getElementById('Firme').src = _immagine;
        console.log(_immagine);
    };

    columns = [
        {
            name: `Dipendente`,
            selector: (row) => row[`Dipendente`],
            cellExport: (row) => row[`Dipendente`],
            sortable: true,
            wrap: true,
            compact: true,
            center: true,
            exportHeaders: true,
        },
        {
            name: `Giorno`,
            selector: (row) => row[`Giorno`],
            cellExport: (row) => row[`Giorno`],
            sortable: true,
            wrap: true,
            compact: true,
            center: true,
            exportHeaders: true,
        },
        {
            name: `Ingresso(1)`,
            selector: (row) => (
                <p onClick={() => ApriFirma(row.FirmaIngresso1)}>
                    <u>{row[`Ora Ingresso (1)`]}</u>
                </p>
            ),
            cellExport: (row) => row[`Ora Ingresso (1)`],
            sortable: true,
            wrap: true,
            compact: true,
            center: true,
            exportHeaders: true,
        },
        {
            name: `Uscita(1)`,
            selector: (row) => (
                <p onClick={() => ApriFirma(row.FirmaUscita1)}>
                    <u>{row[`Ora Uscita (1)`]}</u>
                </p>
            ),
            cellExport: (row) => row[`Ora Uscita (1)`],
            sortable: true,
            wrap: true,
            compact: true,
            center: true,
            exportHeaders: true,
        },

        {
            name: `Ingresso(2)`,
            selector: (row) => (
                <p onClick={() => ApriFirma(row.FirmaIngresso2)}>
                    <u>{row[`Ora Ingresso (2)`]}</u>
                </p>
            ),
            cellExport: (row) => row[`Ora Ingresso (2)`],
            sortable: true,
            wrap: true,
            compact: true,
            center: true,
            exportHeaders: true,
        },
        {
            name: `Uscita(2)`,
            selector: (row) => (
                <p onClick={() => ApriFirma(row.FirmaUscita2)}>
                    <u>{row[`Ora Uscita (2)`]}</u>
                </p>
            ),
            cellExport: (row) => row[`Ora Uscita (2)`],
            sortable: true,
            wrap: true,
            compact: true,
            center: true,
            exportHeaders: true,
        },

        {
            name: `Ingresso(3)`,
            selector: (row) => (
                <p onClick={() => ApriFirma(row.FirmaIngresso3)}>
                    <u>{row[`Ora Ingresso (3)`]}</u>
                </p>
            ),
            cellExport: (row) => row[`Ora Ingresso (3)`],
            sortable: true,
            wrap: true,
            compact: true,
            center: true,
            exportHeaders: true,
        },
        {
            name: `Uscita(3)`,
            selector: (row) => (
                <p onClick={() => ApriFirma(row.FirmaUscita3)}>
                    <u>{row[`Ora Uscita (3)`]}</u>
                </p>
            ),
            cellExport: (row) => row[`Ora Uscita (3)`],
            sortable: true,
            wrap: true,
            compact: true,
            center: true,
            exportHeaders: true,
        },

        {
            name: `Ingresso(4)`,
            selector: (row) => (
                <p onClick={() => ApriFirma(row.FirmaIngresso4)}>
                    <u>{row[`Ora Ingresso (4)`]}</u>
                </p>
            ),
            cellExport: (row) => row[`Ora Ingresso (4)`],
            sortable: true,
            wrap: true,
            compact: true,
            center: true,
            exportHeaders: true,
        },
        {
            name: `Uscita(4)`,
            selector: (row) => (
                <p onClick={() => ApriFirma(row.FirmaUscita4)}>
                    <u>{row[`Ora Uscita (4)`]}</u>
                </p>
            ),
            cellExport: (row) => row[`Ora Uscita (4)`],
            sortable: true,
            wrap: true,
            compact: true,
            center: true,
            exportHeaders: true,
        },

        {
            name: `Turno`,
            selector: (row) => row[`Durata Turno`],
            cellExport: (row) => row[`Durata Turno`],
            sortable: true,
            wrap: true,
            compact: true,
            center: true,
            exportHeaders: true,
        },
        {
            name: `Lavorato/Giorno`,
            selector: (row) => row[`Tempo Lavorato Per Giorno`],
            cellExport: (row) => row[`Tempo Lavorato Per Giorno`],
            sortable: true,
            wrap: true,
            compact: true,
            center: true,
            exportHeaders: true,
        },
        {
            name: `Extra/Giorno`,
            selector: (row) => row[`Straordinario Per Giorno`],
            cellExport: (row) => row[`Straordinario Per Giorno`],
            sortable: true,
            wrap: true,
            compact: true,
            center: true,
            exportHeaders: true,
        },
        {
            name: `Parz. Extra`,
            selector: (row) => row[`SommaStraordinari`],
            cellExport: (row) => row[`SommaStraordinari`],
            sortable: true,
            wrap: true,
            compact: true,
            center: true,
            exportHeaders: true,
        },
        {
            name: `Parz. Ore`,
            selector: (row) => row[`Totale`],
            cellExport: (row) => row[`Totale`],
            sortable: true,
            wrap: true,
            compact: true,
            center: true,
            exportHeaders: true,
        },
    ];

    const tableData = {
        columns,
        data,
    };

    if (data[0]) {
        document.getElementById('OreTotali').value = data[data.length - 1] ? data[data.length - 1].Totale : 0;
        document.getElementById('StraordinariTotali').value = data[data.length - 1] ? data[data.length - 1].SommaStraordinari : 0;
    }
    return (
        <DataTableExtensions {...tableData}>
            <DataTable
                title={value.url.replace(/([A-Z])/g, ' $1').trim()}
                defaultSortAsc={false}
                exportHeaders={true}
                fileName={value.url.replace(/([A-Z])/g, ' $1').trim()}
                pagination
                paginationComponentOptions={opzioniPaginazione}
                highlightOnHover
            />
        </DataTableExtensions>
    );
};

export default TabellaTimbrature;

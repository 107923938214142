import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import '../Component/CSS/Accesso.css';
import { LogOut } from '../App';

export default function Accesso() {
    const Navigate = useNavigate();
    const [accesso, setAccesso] = useState({
        CodiceFiscale: '',
        Password: '',
    });

    const Conferma = () => {
        confirmAlert({
            message: (
                <div>
                    <strong>Politica di accettazione delle regole del sito.</strong>
                    <br></br>
                    <hr />
                    Scaricando la Busta Paga si conferma la presa visione e consegna del documento. Si autorizza la società{' '}
                    {process.env.REACT_APP_NOMESITO} ad utilizzare il numero di cellulare fornito per l'invio di messaggi SMS per solo comunicazioni
                    lavorative.
                </div>
            ),
            buttons: [
                {
                    label: 'Accetto',
                    onClick: async () => {
                        await axios.post(`${process.env.REACT_APP_URL_SERVER}/AccettazionePoliticaSito`, {
                            CodiceFiscale: sessionStorage.getItem('CodiceFiscale'),
                        });
                        Navigate('/Scansioni');
                        window.location.reload(false);
                    },
                },
                {
                    label: 'Rifiuto',
                    onClick: () => LogOut(),
                },
            ],
            closeOnEscape: false,
            closeOnClickOutside: false,
            keyCodeForClose: [8, 32],
        });
    };

    function logCred(e) {
        setAccesso((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    }

    async function LogIn() {
        try {
            const risposta = await axios.post(`${process.env.REACT_APP_URL_SERVER}/LogIn`, {
                CodiceFiscale: accesso.CodiceFiscale,
                Password: accesso.Password,
            });
            if (risposta.data[0]) {
                sessionStorage.setItem('CodiceFiscale', '');
                sessionStorage.setItem('Nome', '');
                sessionStorage.setItem('ProUser', '');
                sessionStorage.setItem('Azienda', '');
                sessionStorage.setItem('IDutente', '');

                sessionStorage.setItem('CodiceFiscale', risposta.data[0].CodiceFiscale);
                sessionStorage.setItem('Nome', risposta.data[0].Dipendente);
                sessionStorage.setItem('ProUser', risposta.data[0].Livello);
                sessionStorage.setItem('Azienda', risposta.data[0].Azienda);
                sessionStorage.setItem('IDutente', risposta.data[0].ID);

                if (risposta.data[0].Accettazione === 'false') {
                    Conferma();
                } else {
                    if (risposta.data[0].Livello === -1) {
                        Navigate('/Scansioni');
                    } else if (risposta.data[0].Livello === 0) {
                        //Navigate('/IngressiUscite');
                        Navigate('/Turni');
                    } else if (risposta.data[0].Livello === 2) {
                        Navigate('/StatoPulizie');
                    } else if (risposta.data[0].Livello === 5) {
                        //Navigate('/IngressiUscite');
                        Navigate('/Turni');
                    } else if (risposta.data[0].Livello === 10) {
                        Navigate('/Anagrafiche');
                    } else if (risposta.data[0].Livello === 11) {
                        Navigate('/Anagrafiche');
                    }

                    window.location.reload(false);
                }
            } else {
                toast.error('Credenziali errate');
            }
        } catch (error) {
            toast.error(error);
            console.log(error);
        }
    }
    function KeyUpEvent(event) {
        if (event.key === 'Enter') {
            document.getElementById('Accedi').click();
        }
    }

    return (
        <div id='Contenuto'>
            <h1>Accesso ai Servizi</h1>
            <div id='Accesso'>
                <img src={require('.//Immagini/Logo.png')} alt='Logo' id='Logo' />
                <form>
                    <div id='Ingresso'>
                        <input
                            type='text'
                            placeholder='Codice Fiscale'
                            aria-label='CodiceFiscale'
                            name='CodiceFiscale'
                            id='CodiceFiscale'
                            autoComplete='on'
                            required
                            onChange={logCred}
                        />
                        <input
                            type='password'
                            placeholder='Password'
                            aria-label='Password'
                            name='Password'
                            id='Password'
                            autoComplete='on'
                            required
                            onChange={logCred}
                            onKeyUp={(event) => {
                                KeyUpEvent(event);
                            }}
                        />
                        <button type='button' id='Accedi' onClick={LogIn}>
                            Accedi
                        </button>
                    </div>
                </form>
                <hr></hr>
            </div>
        </div>
    );
}

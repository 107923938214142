import React, { useState, useRef } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

import '../Component/CSS/BustePaga.css';
import './File/KitNuovoAssunto.pdf';
import { getOut } from '../App';

export default function BustePaga() {
	getOut(0);

	const [stoCaricando, setStoCaricando] = useState('0');
	const CodiceDipendente = sessionStorage.getItem('CodiceFiscale').toUpperCase();
	const NomeDipendente = sessionStorage.getItem('Nome');

	const NuoveBuste = useRef({}),
		NuoviCUD = useRef({}),
		NuoviTFR = useRef({}),
		NuovaDocumentazione = useRef({}),
		NuovoKit = useRef({});

	function Caricamenti() {
		let LivelloUtenza = parseInt(sessionStorage.getItem('ProUser'));
		if (LivelloUtenza >= 9) {
			return true;
		} else {
			return false;
		}
	}

	function FileDownload(_filePath) {
		let link = document.createElement('a');
		link.href = _filePath;
		link.target = '_blank';
		link.rel = 'noopener noreferrer';
		link.download = _filePath.substr(_filePath.lastIndexOf('/') + 1);
		link.click();
	}

	const CaricaDocumento = (e, _file, _nome) => {
		e.preventDefault();
		_file.current.value = e.target.files;
	};

	const UploadFile = async (e, _file, _nomeFile) => {
		e.preventDefault();

		if (!_file.current || !_file.current.value || _file.current.value.length === 0) {
			setStoCaricando('0');
			toast.error('Nessun File Selezionato.');
			return;
		}

		let formData = new FormData();
		let AnnoBuste = document.getElementById('BusteNellAnno').value;
		let MeseBuste = document.getElementById('CaricaNelMese').value;
		let AnnoCUD = document.getElementById('CUDNellAnno').value;
		let AnnoTFR = document.getElementById('TFRNellAnno').value;
		let url;

		const Posta = async () => {
			const Carica = async () => {
				setStoCaricando('1');
				const risposta = await axios.post(`${url}`, formData, {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				});

				if (risposta.status === 200) {
					toast.success('File Caricati con successo');

					let listaFileCaricati = ['Lista File Nella Cartella:'];
					const fileList = risposta.data.files;

					fileList.forEach((fileName) => {
						listaFileCaricati.push(fileName);
					});

					const toastContent = (
						<div>
							{listaFileCaricati.map((fileName, index) => (
								<React.Fragment key={index}>
									{fileName}
									{index < listaFileCaricati.length - 1 && <br />}
								</React.Fragment>
							))}
						</div>
					);

					toast.success(toastContent);
				} else if (risposta.status === 400) {
					toast.error('Nessun File Selezionato.');
				} else {
					toast.error('File Non caricati.');
				}
			};

			for (let i = 0; i < _file.current.value.length; i++) {
				formData.append(`${_file.current.value[i].name}`, _file.current.value[i]);
			}

			if (_nomeFile === 'NuoveBuste') {
				if (AnnoBuste !== '-1' && MeseBuste !== '-1') {
					url = `${process.env.REACT_APP_URL_SERVER}/SalvaDocumentazione/Buste&${AnnoBuste}&${MeseBuste}&&`;
					Carica();
				}
			} else if (_nomeFile === 'NuoviCUD') {
				if (AnnoCUD !== '-1') {
					url = `${process.env.REACT_APP_URL_SERVER}/SalvaDocumentazione/CUD&&&${AnnoCUD}&`;
					Carica();
				}
			} else if (_nomeFile === 'NuoviTFR') {
				if (AnnoTFR !== '-1') {
					url = `${process.env.REACT_APP_URL_SERVER}/SalvaDocumentazione/TFR&&&&${AnnoTFR}`;
					Carica();
				}
			} else if (_nomeFile === 'NuovaDocumentazione') {
				url = `${process.env.REACT_APP_URL_SERVER}/SalvaDocumentazione/Documenti&&&&`;
				Carica();
			} else if (_nomeFile === 'NuovoKit') {
				url = `${process.env.REACT_APP_URL_SERVER}/SalvaDocumentazione/Kit&&&&`;
				Carica();
			}

			setStoCaricando('0');
		};

		if (_file.current.value) {
			if (!(Object.keys(_file.current.value).length === 0 && _file.current.value.constructor === Object)) {
				try {
					Posta();
				} catch (error) {
					setStoCaricando('0');
					toast.error(error);
					console.log(error);
				} finally {
					const TuttiGliInputFile = document.querySelectorAll('input[type=file]');
					TuttiGliInputFile.forEach((el) => {
						el.value = '';
					});
					document.getElementsByClassName(`NuoveBuste`)[0].innerText = '';
					document.getElementsByClassName(`NuoviCUD`)[0].innerText = '';
					document.getElementsByClassName(`NuoviTFR`)[0].innerText = '';
					document.getElementsByClassName(`NuovaDocumentazione`)[0].innerText = '';
					document.getElementsByClassName(`NuovoKit`)[0].innerText = '';
				}
			}
		} else {
			setStoCaricando('0');
			toast.error('Mancano dei dati per poter caricare i documenti, verifica.');
		}
	};

	const InizializzaSelect = (_NonQuesta) => {
		const selectTutte = document.querySelectorAll('select');
		selectTutte.forEach((select) => {
			if (select !== _NonQuesta) {
				select.value = '-1';
			}
		});
	};

	async function verificaEsistenzaFile(_Percorso) {
		try {
			const response = await axios.get(_Percorso, { responseType: 'arraybuffer' });
			return response.data.byteLength > 651; // Perchè cazzo è esattamente 651 non si sa
		} catch (error) {
			if (error.response && error.response.status === 404) {
				return false; // Lo status 404 indica che il file non esiste
			}
			console.error('Errore nella richiesta GET:', error);
			return false; // Gestisci altri tipi di errori
		}
	}

	return (
		<div id='Contenuto'>
			{process.env.REACT_APP_TIPOSITO === 'SVILUPPO' ? (
				<h1 style={{ color: 'red', textDecoration: 'underline' }}>SVILUPPO</h1>
			) : (
				<h1>Documentazione</h1>
			)}
			<div id='BustePaga'>
				{Caricamenti() ? (
					<>
						<div id='Caricamenti'>
							<h2>Caricamento Documentazione</h2>
							<form>
								<select id='BusteNellAnno'>
									<option label='Scegli Anno Busta Paga' value='-1'></option>
									<option value='2021'>2021</option>
									<option value='2022'>2022</option>
									<option value='2023'>2023</option>
									<option value='2024'>2024</option>
									<option value='2025'>2025</option>
									<option value='2026'>2026</option>
									<option value='2027'>2027</option>
									<option value='2028'>2028</option>
									<option value='2029'>2029</option>
									<option value='2030'>2030</option>
								</select>
								<select id='CaricaNelMese' style={{ marginTop: '5px' }}>
									<option label='Scegli il Mese Busta Paga' value='-1'></option>
									<option value='Gennaio'>Gennaio</option>
									<option value='Febbraio'>Febbraio</option>
									<option value='Marzo'>Marzo</option>
									<option value='Aprile'>Aprile</option>
									<option value='Maggio'>Maggio</option>
									<option value='Giugno'>Giugno</option>
									<option value='Luglio'>Luglio</option>
									<option value='Agosto'>Agosto</option>
									<option value='Settembre'>Settembre</option>
									<option value='Ottobre'>Ottobre</option>
									<option value='Novembre'>Novembre</option>
									<option value='Dicembre'>Dicembre</option>
									<option value='Tredicesima'>Tredicesima</option>
									<option value='Quattordicesima'>Quattordicesima</option>
								</select>
								<label htmlFor='CaricoBuste' className='CaricaDoc'>
									<input
										name='CaricoBuste'
										type='file'
										id='CaricoBuste'
										className='NuoveBuste'
										multiple
										accept='application/pdf'
										onChange={(e) => CaricaDocumento(e, NuoveBuste, 'NuoveBuste')}
									/>
									Aggiungi le Buste Paga
								</label>
								<button type='button' id='LoadBuste' onClick={(e) => UploadFile(e, NuoveBuste, 'NuoveBuste')}>
									Carica le Buste
								</button>
							</form>
							<hr></hr>

							<form>
								<select id='CUDNellAnno'>
									<option label='Scegli Anno CUD' value='-1'></option>
									<option value='2021'>2021</option>
									<option value='2022'>2022</option>
									<option value='2023'>2023</option>
									<option value='2024'>2024</option>
									<option value='2025'>2025</option>
									<option value='2026'>2026</option>
									<option value='2027'>2027</option>
									<option value='2028'>2028</option>
									<option value='2029'>2029</option>
									<option value='2030'>2030</option>
								</select>
								<label htmlFor='CaricoCUD' className='CaricaDoc'>
									<input
										name='CaricoCUD'
										type='file'
										id='CaricoCUD'
										className='NuoviCUD'
										multiple
										accept='application/pdf'
										onChange={(e) => CaricaDocumento(e, NuoviCUD, 'NuoviCUD')}
									/>
									Aggiungi i CUD
								</label>
								<button type='button' id='LoadCUD' onClick={(e) => UploadFile(e, NuoviCUD, 'NuoviCUD')}>
									Carica i CUD
								</button>
							</form>
							<hr></hr>
							<form>
								<select id='TFRNellAnno'>
									<option label='Scegli Anno TFR' value='-1'></option>
									<option value='2021'>2021</option>
									<option value='2022'>2022</option>
									<option value='2023'>2023</option>
									<option value='2024'>2024</option>
									<option value='2025'>2025</option>
									<option value='2026'>2026</option>
									<option value='2027'>2027</option>
									<option value='2028'>2028</option>
									<option value='2029'>2029</option>
									<option value='2030'>2030</option>
								</select>
								<label htmlFor='CaricoTFR' className='CaricaDoc'>
									<input
										name='CaricoTFR'
										type='file'
										id='CaricoTFR'
										className='NuoviTFR'
										multiple
										accept='application/pdf'
										onChange={(e) => CaricaDocumento(e, NuoviTFR, 'NuoviTFR')}
									/>
									Aggiungi i TFR
								</label>
								<button type='button' id='LoadTFR' onClick={(e) => UploadFile(e, NuoviTFR, 'NuoviTFR')}>
									Carica i TFR
								</button>
							</form>
							<hr></hr>

							<form>
								<label htmlFor='CaricoDocumentazione' className='CaricaDoc' style={{ fontSize: 'medium' }}>
									<input
										name='CaricoDocumentazione'
										type='file'
										id='CaricoDocumentazione'
										className='NuovaDocumentazione'
										accept='application/pdf'
										onChange={(e) => CaricaDocumento(e, NuovaDocumentazione, 'NuovaDocumentazione')}
									/>
									Aggiungi Doc.
								</label>
								<button
									type='button'
									id='LoadCUD'
									className='NuovaDocumentazione'
									onClick={(e) => UploadFile(e, NuovaDocumentazione, 'NuovaDocumentazione')}
								>
									Carica Doc.
								</button>
							</form>
							<hr></hr>

							<form>
								<label htmlFor='CaricoKit' className='CaricaDoc'>
									<input
										name='CaricoKit'
										type='file'
										id='CaricoKit'
										className='NuovoKit'
										accept='application/pdf'
										onChange={(e) => CaricaDocumento(e, NuovoKit, 'NuovoKit')}
									/>
									Aggiungi Kit
								</label>
								<button type='button' id='LoadKit' onClick={(e) => UploadFile(e, NuovoKit, 'NuovoKit')}>
									Carica KitNuovoAssunto
								</button>
							</form>
						</div>
						<hr></hr>
						<hr></hr>
					</>
				) : null}
				<div id='Down'>
					<h2 style={{ marginTop: '25px' }}>Buste Paga</h2>
					<form name='FormBuste'>
						<br />
						<select id='Anno'>
							<option label='Scegli Anno della Busta Paga' value='-1'></option>
							<option value='2021'>2021</option>
							<option value='2022'>2022</option>
							<option value='2023'>2023</option>
							<option value='2024'>2024</option>
							<option value='2025'>2025</option>
							<option value='2026'>2026</option>
							<option value='2027'>2027</option>
							<option value='2028'>2028</option>
							<option value='2029'>2029</option>
							<option value='2030'>2030</option>
						</select>
						<select id='Mensilita' style={{ marginTop: '5px' }}>
							<option label='Scegli il Mese della Busta Paga' value='-1'></option>
							<option value='Gennaio'>Gennaio</option>
							<option value='Febbraio'>Febbraio</option>
							<option value='Marzo'>Marzo</option>
							<option value='Aprile'>Aprile</option>
							<option value='Maggio'>Maggio</option>
							<option value='Giugno'>Giugno</option>
							<option value='Luglio'>Luglio</option>
							<option value='Agosto'>Agosto</option>
							<option value='Settembre'>Settembre</option>
							<option value='Ottobre'>Ottobre</option>
							<option value='Novembre'>Novembre</option>
							<option value='Dicembre'>Dicembre</option>
							<option value='Tredicesima'>Tredicesima</option>
							<option value='Quattordicesima'>Quattordicesima</option>
						</select>

						<button
							type='submit'
							onClick={async (e) => {
								e.preventDefault();
								let Mese = document.getElementById('Mensilita').value;
								let Anno = document.getElementById('Anno').value;

								if (Mese !== '-1' && Anno !== '-1') {
									let Percorso = `${process.env.REACT_APP_PERCORSO_DOCUMENTI_PROFILO}/BustePaga/${Anno}/${Mese}/${CodiceDipendente}.pdf`;
									try {
										if (await verificaEsistenzaFile(Percorso)) {
											const corpo = {
												Corpo: `${NomeDipendente} Ha scaricato La Busta Paga di ${Mese} Anno ${Anno}`,
												Oggetto: `${NomeDipendente} Ha scaricato La Busta Paga di ${Mese} Anno ${Anno}`,
												Mail: process.env.REACT_APP_MAIL_NOTIFICHE,
												Testo: `${NomeDipendente} Ha scaricato La Busta Paga di ${Mese} Anno ${Anno}`,
											};

											FileDownload(Percorso);
											await axios.post(`${process.env.REACT_APP_URL_SERVER}/InviaMail`, corpo);
											toast.success('Busta Paga Scaricata');
										} else {
											toast.error(`La Busta Paga di ${Mese}/${Anno} non è ancora disponibile`);
										}
									} catch (error) {
										console.error(error);
									}
								} else {
									toast.error('Devi Scegliere un mese.');
								}
							}}
						>
							Scarica Cedolino
						</button>
						<br />
					</form>

					<h2 style={{ marginTop: '25px' }}>CUD</h2>
					<form name='FormCUD'>
						<select id='CUDAnno'>
							<option label='Scegli Anno del CUD' value='-1'></option>
							<option value='2021'>2021</option>
							<option value='2022'>2022</option>
							<option value='2023'>2023</option>
							<option value='2024'>2024</option>
							<option value='2025'>2025</option>
							<option value='2026'>2026</option>
							<option value='2027'>2027</option>
							<option value='2028'>2028</option>
							<option value='2029'>2029</option>
							<option value='2030'>2030</option>
						</select>
						<button
							type='submit'
							onClick={async (e) => {
								e.preventDefault();
								let Anno = document.getElementById('CUDAnno').value;
								if (Anno !== '-1') {
									let Percorso = `${process.env.REACT_APP_PERCORSO_DOCUMENTI_PROFILO}/CUD/${Anno}/${CodiceDipendente}.pdf`;

									try {
										if (await verificaEsistenzaFile(Percorso)) {
											const corpo = {
												Corpo: `${NomeDipendente} Ha scaricato il CUD`,
												Oggetto: `${NomeDipendente} Ha scaricato il CUD`,
												Mail: process.env.REACT_APP_MAIL_NOTIFICHE,
												Testo: `${NomeDipendente} Ha scaricato il CUD`,
											};

											FileDownload(Percorso);

											await axios.post(`${process.env.REACT_APP_URL_SERVER}/InviaMail`, corpo);
											toast.success('CUD Scaricato');
											InizializzaSelect(e.target);
										} else {
											toast.error('File Non Presente.');
										}
									} catch (error) {
										console.error(error);
									}
								}
							}}
						>
							CUD
						</button>
						<br />
					</form>

					<h2 style={{ marginTop: '25px' }}>TFR</h2>
					<form name='FormTFR'>
						<select id='AnnoTFR'>
							<option label='Scegli Anno del TFR' value='-1'></option>
							<option value='2021'>2021</option>
							<option value='2022'>2022</option>
							<option value='2023'>2023</option>
							<option value='2024'>2024</option>
							<option value='2025'>2025</option>
							<option value='2026'>2026</option>
							<option value='2027'>2027</option>
							<option value='2028'>2028</option>
							<option value='2029'>2029</option>
							<option value='2030'>2030</option>
						</select>
						<button
							type='submit'
							onClick={async (e) => {
								e.preventDefault();

								let Anno = document.getElementById('AnnoTFR').value;
								if (Anno !== '-1') {
									let Percorso = `${process.env.REACT_APP_PERCORSO_DOCUMENTI_PROFILO}/TFR/${Anno}/${CodiceDipendente}.pdf` ?? false;
									try {
										if (await verificaEsistenzaFile(Percorso)) {
											const corpo = {
												Corpo: `${NomeDipendente} Ha scaricato il TFR`,
												Oggetto: `${NomeDipendente} Ha scaricato il TFR`,
												Mail: process.env.REACT_APP_MAIL_NOTIFICHE,
												Testo: `${NomeDipendente} Ha scaricato il TFR`,
											};
											FileDownload(Percorso);
											await axios.post(`${process.env.REACT_APP_URL_SERVER}/InviaMail`, corpo);
											toast.success('TFR Scaricato');
											InizializzaSelect(e.target);
										} else {
											toast.error('File Non Presente.');
										}
									} catch (error) {
										console.error(error);
									}
								}
							}}
						>
							TFR
						</button>
						<br />
					</form>
					<hr />

					{/*  <form style={{ marginTop: '25px' }}>
                        <button
                            type='submit'
                            onClick={async (e) => {
                                e.preventDefault();
                                let Percorso = `${process.env.REACT_APP_PERCORSO_DOCUMENTI_PROFILO}/Documenti/Documenti.pdf`;
                                if (await verificaEsistenzaFile(Percorso)) {
                                    const corpo = {
                                        Corpo: `${NomeDipendente} Ha scaricato la Documentazione Varia`,
                                        Oggetto: `${NomeDipendente} Ha scaricato la Documentazione Varia`,
                                        Mail: process.env.REACT_APP_MAIL_NOTIFICHE,
                                        Testo: `${NomeDipendente} Ha scaricato la Documentazione Varia`,
                                    };
                                    FileDownload(Percorso);
                                    await axios.post(`${process.env.REACT_APP_URL_SERVER}/InviaMail`, corpo);
                                    toast.success('Documentazione Scaricata');
                                } else {
                                    toast.error('File Non Presente.');
                                }
                            }}>
                            Documentazione
                        </button>
                    </form> */}

					<a
						href={`${process.env.REACT_APP_PERCORSO_DOCUMENTI_PROFILO}/Kit/KitNuovoAssunto.pdf`}
						target='_blank'
						rel='noopener noreferrer'
						download
					>
						<button type='submit' id='ScaricaKit'>
							Kit Nuovo Assunto
						</button>
					</a>

					<hr></hr>
					<a href='/Questionari'>
						<button type='button' href={'./Questionari'} id='Questionario'>
							Questionario
						</button>
					</a>
					<br />
					<hr></hr>
					<div>
						<button
							type='button'
							id='Organigramma'
							onClick={() => {
								const urlDummy = `${process.env.REACT_APP_URL_BASE}/DipendentiDocumentazioneMaterialeVarie/Documenti/Organigramma.pdf`;
								document.getElementById('MostraFile').setAttribute('data', urlDummy);
								document.getElementById('ScaricaSeNonLoVedi').setAttribute('href', urlDummy);
								document.getElementById('ScaricaSeNonLoVedi').textContent = 'Organigramma';
								document.getElementById('MostraFile').style.display = 'flex';
								InizializzaSelect('');
							}}
							style={{ marginBottom: '25px' }}
						>
							Organigramma
						</button>
						<button
							type='button'
							id='SchedeSicurezza'
							onClick={() => {
								const urlDummy = `${process.env.REACT_APP_URL_BASE}/DipendentiDocumentazioneMaterialeVarie/Documenti/SchedeSicurezzaPulizia.pdf`;
								document.getElementById('MostraFile').setAttribute('data', urlDummy);
								document.getElementById('ScaricaSeNonLoVedi').setAttribute('href', urlDummy);
								document.getElementById('ScaricaSeNonLoVedi').textContent = 'Schede Sicurezza Pulizia';
								document.getElementById('MostraFile').style.display = 'flex';
								InizializzaSelect('');
							}}
							style={{ marginBottom: '25px' }}
						>
							Elenco Schede di Sicurezza
						</button>
						<button
							type='button'
							id='DecalogoEfficienzaAmbientale'
							onClick={() => {
								const urlDummy = `${process.env.REACT_APP_URL_BASE}/DipendentiDocumentazioneMaterialeVarie/Documenti/DecalogoEfficienzaAmbientale.pdf`;
								document.getElementById('MostraFile').setAttribute('data', urlDummy);
								document.getElementById('ScaricaSeNonLoVedi').setAttribute('href', urlDummy);
								document.getElementById('ScaricaSeNonLoVedi').textContent = 'DecalogoEfficienzaAmbientale';
								document.getElementById('MostraFile').style.display = 'flex';
								InizializzaSelect('');
							}}
							style={{ marginBottom: '25px' }}
						>
							Decalogo Efficienza Ambientale
						</button>

						<button
							type='button'
							id='Benzina'
							onClick={() => {
								const urlDummy = `${process.env.REACT_APP_URL_BASE}/DipendentiDocumentazioneMaterialeVarie/Documenti/Benzina.pdf`;
								document.getElementById('MostraFile').setAttribute('data', urlDummy);
								document.getElementById('ScaricaSeNonLoVedi').setAttribute('href', urlDummy);
								document.getElementById('ScaricaSeNonLoVedi').textContent = 'Benzina';
								document.getElementById('MostraFile').style.display = 'flex';
								InizializzaSelect('');
							}}
							style={{ marginBottom: '25px' }}
						>
							Scheda Sicurezza Benzina
						</button>

						<label htmlFor='SchedeProdotti'>Schede Prodotti Pulizie</label>
						<select
							id='SchedeProdotti'
							onChange={(e) => {
								const urlDummy = `${process.env.REACT_APP_URL_BASE}/DipendentiDocumentazioneMaterialeVarie/Documenti/SchedePulizia/${e.target.value}.pdf`;
								document.getElementById('MostraFile').setAttribute('data', urlDummy);
								document.getElementById('ScaricaSeNonLoVedi').setAttribute('href', urlDummy);
								document.getElementById('ScaricaSeNonLoVedi').textContent = e.target.value;
								document.getElementById('MostraFile').style.display = 'flex';
								InizializzaSelect(e.target);
							}}
						>
							<option label='Scegli la Scheda' value='-1'></option>
							<option value='ACIDOTAMPONATO'>ACIDO TAMPONATO</option>
							<option value='ECOVETRI'>ECO VETRI</option>
							<option value='ALOX2022'>ALOX 2022</option>
							<option value='FLOORMARINE2021'>FLOOR MARINE 2021</option>
							<option value='ANTISAPRILDISINFETTANTE2022'>ANTISAPRIL DISINFETTANTE 2022</option>
							<option value='FORM2022'>FORM 2022</option>
							<option value='BATH2022'>BATH 2022</option>
							<option value='LACTIC2022'>LACTIC 2022</option>
							<option value='CANDEGGINACLASSICA2020'>CANDEGGINA CLASSICA 2020</option>
							<option value='MARSISGRASSATORE'>MARSI SGRASSATORE</option>
							<option value='CLOROSUPERFICI'>CLORO SUPERFICI</option>
							<option value='MULTIGLASS2021'>MULTIGLASS 2021</option>
							<option value='DECALPLUS2023'>DECAL PLUS 2023</option>
							<option value='OLICLORCANDEGGINA2023'>OLICLOR CANDEGGINA 2023</option>
							<option value='DEORNETCLOR2022'>DEORNET CLOR 2022</option>
							<option value='SAPONEMANILATTE'>SAPONE MANI LATTE</option>
							<option value='ECOBAGNO'>ECO BAGNO</option>
						</select>

						<label htmlFor='SchedeDerattizzazione'>Schede Prodotti Deratizzazione</label>
						<select
							id='SchedeDerattizzazione'
							onChange={(e) => {
								const urlDummy = `${process.env.REACT_APP_URL_BASE}/DipendentiDocumentazioneMaterialeVarie/Documenti/SchedeDerattizzazione/${e.target.value}.pdf`;
								document.getElementById('MostraFile').setAttribute('data', urlDummy);
								document.getElementById('ScaricaSeNonLoVedi').setAttribute('href', urlDummy);
								document.getElementById('ScaricaSeNonLoVedi').textContent = e.target.value;
								document.getElementById('MostraFile').style.display = 'flex';
								InizializzaSelect(e.target);
							}}
						>
							<option label='Scegli Scheda Derattizzazione' value='-1'></option>
							<option value='DEP-SOLOBLOX'>DEP-SOLO BLOX</option>
							<option value='SDS_PROXILARCOMPRESSE_ITA_4'>SDS PROXILAR COMPRESSE ITA 4</option>
							<option value='Solo_Blox_SDS_CLP_-_IT_0717'>SOLO BLOX SDS CLP - IT 0717</option>
							<option value='advion_gel_scarafaggi_-_scheda_di_sicurezza_clp_1'>ADVION GEL SCARAFAGGI</option>
							<option value='notracbloxbell'>NOTRAC BLOX BELL</option>
						</select>

						<object id='MostraFile' data='' type='application/pdf' style={{ marginTop: '25px', width: '100%' }} hidden>
							<p>
								Se stai leggendo questa frase non puoi visualizzare il File ma devi scaricarlo dal seguente link:
								<br />
								<a id='ScaricaSeNonLoVedi' href='./' target='_blank' download>
									SCARICA IL FILE
								</a>
							</p>
						</object>
						<p
							style={{
								fontStyle: 'italic',
								fontSize: 'medium',
								lineHeight: '1.5em',
								margin: '0px',
								padding: '0px',
							}}
						>
							Se usi Safari o sei su un iPhone/iPad visualizzerai solamente la prima pagina del documento, per visualizzarlo
							integralmente utilizza un PC o un tablet/telefono Android
							<br />
						</p>
					</div>
				</div>
			</div>
			<img
				id='loading'
				style={{ display: stoCaricando === '1' ? 'block' : 'none' }}
				src={`${process.env.REACT_APP_URL_BASE}/wait.svg`}
				alt='Loading'
			></img>
		</div>
	);
}

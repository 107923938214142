import React, { useRef } from 'react';
import axios from 'axios';
import '../Component/CSS/Protocolli.css';
import { getOut } from '../App';
import { toast } from 'react-toastify';

export default function Protocolli() {
    getOut(0);

    let persona = window.sessionStorage.getItem('Nome');
    let protocollo = useRef();
    let dataInizio = useRef();
    let dataFine = useRef();

    let InizioEpoch;
    let FineEpoch;

    const InserisciProtocollo = async () => {
        if (protocollo.current.value && InizioEpoch && FineEpoch) {
            await axios.post(`${process.env.REACT_APP_URL_SERVER}/InserisciProtocollo`, {
                Persona: persona,
                DataInizio: InizioEpoch,
                DataFine: FineEpoch,
                Protocollo: protocollo.current.value,
            });

            await axios.post(`${process.env.REACT_APP_URL_SERVER}/InviaMail`, {
                Mail: process.env.REACT_APP_MAIL_MALATTIE,
                Oggetto: `Protocollo di: ${persona}`,
                Testo: `Dipendente: ${persona} <br />
                        Protocollo Malattia: ${protocollo.current.value} <br />
                        Dal: ${new Date(InizioEpoch * 1000).toISOString().slice(0, 10)} <br />
                        Al: ${new Date(FineEpoch * 1000).toISOString().slice(0, 10)}`,
            });

            toast.success(`Protocollo ${protocollo.current.value} di ${persona} è stato inserito correttamente.`);

            protocollo.current.value = '';
            dataInizio.current.value = '';
            dataFine.current.value = '';
        } else {
            toast.warning('Tutti i Campi devono essere compilati.');
        }
    };

    return (
        <div id='Contenuto'>
            {process.env.REACT_APP_TIPOSITO === 'SVILUPPO' ? (
                <h1 style={{ color: 'red', textDecoration: 'underline' }}>SVILUPPO</h1>
            ) : (
                <h1>Protocolli</h1>
            )}

            <div id='Protocolli'>
                <form action='post'>
                    <label htmlFor='Protocollo'>Protocollo</label>
                    <input name='Protocollo' type='number' ref={protocollo} id='Protocollo' />

                    <label htmlFor='DataInizio'>Data Inizio</label>
                    <input
                        name='DataInizio'
                        type='date'
                        onChange={(e) => (InizioEpoch = parseInt(e.target.valueAsNumber) / 1000)}
                        onFocus={() => document.getElementById('DataInizio').showPicker()}
                        ref={dataInizio}
                        id='DataInizio'
                    />

                    <label htmlFor='DataFine'>Data Fine</label>
                    <input
                        name='DataFine'
                        type='date'
                        onChange={(e) => (FineEpoch = parseInt(e.target.valueAsNumber) / 1000)}
                        onFocus={() => document.getElementById('DataFine').showPicker()}
                        ref={dataFine}
                        id='DataFine'
                    />

                    <button type='button' onClick={InserisciProtocollo}>
                        Inserisci Protocollo
                    </button>
                </form>
                <hr></hr>
            </div>
        </div>
    );
}

import React, { useRef, useState } from 'react';
import '../Component/CSS/SMS.css';
import { getOut } from '../App';
import Select from '../Component/Select';
import { toast } from 'react-toastify';
import axios from 'axios';

const selezione = {
    url: 'DipendentiAttivi',
    Value: 'Cellulare',
    Display: 'Dipendente',
};

export default function SMS() {
    getOut(10);
    const [dipendente, setDipendente] = useState('');
    const [numeroSingolo, setNumeroSingolo] = useState('');
    const [numeri, setNumeri] = useState('');
    let Corpo = useRef(null);

    const Tutti = () => {
        const Select = document.querySelectorAll('[name=Utenti]');
        setNumeri(
            [...Select[0].options]
                .map((el) => el.value)
                .filter((el) => {
                    return el !== 'No' && el !== '';
                })
        );
    };

    const InviaSMS = async (_All) => {
        if (Corpo.current.value) {
            if (_All === 'SI') {
                try {
                    axios.post(`${process.env.REACT_APP_URL_SERVER}/SMS`, {
                        TelefonoOk: numeri,
                        TestoSMS: Corpo.current.value,
                    });
                    toast.success('SMS Inviati a Tutti i Numeri Presenti a Sistema.');
                    setDipendente('');
                    setNumeroSingolo('');
                    setNumeri('');
                    Corpo.current.value = '';
                } catch (error) {
                    console.log(error);
                }
            } else {
                if (numeroSingolo) {
                    try {
                        await axios.post(`${process.env.REACT_APP_URL_SERVER}/SMS`, {
                            TelefonoOk: [numeroSingolo],
                            TestoSMS: Corpo.current.value,
                        });
                        setDipendente('');
                        setNumeroSingolo('');
                        setNumeri('');
                        Corpo.current.value = '';
                    } catch (error) {
                        console.log(error);
                    }

                    toast.success(`SMS inviato al Numero: ${numeroSingolo} di: ${dipendente}`);
                } else {
                    toast.warning('Il Numero è Obbligatorio per Inviarlo ad un Singolo Dipendente! Scegline uno!');
                }
            }
        } else {
            toast.warning('Il testo è obbligatorio!');
        }
    };

    const Seleziona = (e) => {
        const valore = e.target.value === 'No' ? '' : e.target.value;
        setNumeroSingolo(valore);
        setDipendente(e.target.options[e.target.selectedIndex].text);
    };

    return (
        <div id='Contenuto'>
            {process.env.REACT_APP_TIPOSITO === 'SVILUPPO' ? <h1 style={{ color: 'red', textDecoration: 'underline' }}>SVILUPPO</h1> : <h1>SMS</h1>}

            <div id='SMS'>
                <textarea
                    name='Corpo'
                    maxLength='159'
                    placeholder={`
                    
Inserisci il Testo dell'SMS qui.`}
                    rows='6'
                    ref={Corpo}
                    onFocus={() => Tutti()}></textarea>
                <button
                    type='button'
                    name='InviaATutti'
                    id='InviaATutti'
                    onClick={() => {
                        InviaSMS('SI');
                    }}>
                    Invia a Tutti
                </button>

                <label htmlFor='Utenti'>Scelta Utente Singolo</label>
                <p style={{ marginTop: '15px', marginBottom: '15px' }}>
                    Se il nome non viene selezionato: <br />
                    non è presente il numero di telefono a sistema
                </p>
                <select name='Utenti' id='Utenti' onChange={Seleziona} value={numeroSingolo}>
                    <Select value={selezione} />
                </select>
                <button
                    type='button'
                    name='InviaSMS'
                    id='InviaSMS'
                    onClick={() => {
                        InviaSMS('NO');
                    }}>
                    Invia SMS
                </button>
                <hr></hr>
            </div>
        </div>
    );
}

import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import '../Component/CSS/Preventivi.css';
import { toast } from 'react-toastify';

export default function Preventivi() {
    const [totale, setTotale] = useState(0);

    let luogo = useRef();
    let tipoPulizia = useRef();
    let metri = useRef();
    let tipoPavimento = useRef();
    let tipoPavimento2 = useRef();
    let bagni = useRef();
    let frequenza = useRef();
    let preventivo = 0;

    let mail = useRef();
    let telefono = useRef();
    let corpo = useRef();
    let Testo = '';

    useEffect(() => {
        setTotale(preventivo);
        console.log(totale);
    }, [preventivo, totale]);

    const ControllaMail = (_Mail) => {
        return _Mail.match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    const Calcola = async () => {
        await CalcoloTotale();
        Testo = `<strong>Valori Inseriti nel calcolo del preventivo:</strong> <br />
        <strong>Luogo:</strong> ${luogo.current.value} <br />
        <strong>Tipo Pulizia:</strong> ${tipoPulizia.current.value} <br />
        <strong>Pavimento Primario:</strong> ${tipoPavimento.current.value} <br />
        <strong>Pavimento Secondario:</strong> ${tipoPavimento2.current.value} <br />
        <strong>Bagni:</strong> ${bagni.current.value} <br />
        <strong>Metri:</strong> ${metri.current.value} <br />
        <strong>Frequenza:</strong> ${frequenza.current.value} <br />
        <br />
        <strong>Totale Calcolato dal sistema:</strong><br />
        ${preventivo} <br />
        <br />
        <strong>Testo Messaggio:</strong> <br />
        ${corpo.current.value} <br />
        <br />
        <strong>Telefono:</strong> <br />
        ${telefono.current.value} <br />
        <br />
        <strong>Indirizzo Mail:</strong> <br />
        ${mail.current.value}`;

        await axios.post(`${process.env.REACT_APP_URL_SERVER}/InviaMail`, {
            Mail: process.env.REACT_APP_MAIL_PREVENTIVI,
            Oggetto: `Richiesta Informazioni`,
            Testo: Testo,
        });

        toast.success(`
Il prezzo INDICATIVO della pulizia scelta è di:
${preventivo} Euro.
Il preventivo della Pulizia Ordinaria è riferito ad un mese alla frequenza indicata. Contattaci per avere un preventivo
più mirato alle tue esigenze inviando una mail a ${process.env.REACT_APP_MAILPRIMARIA} oppure telefonando allo ${process.env.REACT_APP_TELEFONO}.
Potete anche
utilizzare il form sottostante per inviarci un messaggio.`);
    };

    const Contattaci = async () => {
        if (mail.current.value && corpo.current.value && ControllaMail(mail.current.value)) {
            await axios.post(`${process.env.REACT_APP_URL_SERVER}/InviaMail`, {
                Mail: mail.current.value,
                Oggetto: `Richiesta Informazioni`,
                Testo: Testo,
            });

            await axios.post(`${process.env.REACT_APP_URL_SERVER}/InviaMail`, {
                Mail: process.env.REACT_APP_MAIL_PREVENTIVI,
                Oggetto: `Richiesta Informazioni`,
                Testo: Testo,
            });

            luogo.current.value = '';
            tipoPulizia.current.value = '';
            metri.current.value = '';
            tipoPavimento.current.value = '';
            tipoPavimento2.current.value = '';
            bagni.current.value = '';
            frequenza.current.value = 1;
            preventivo = '0';

            mail.current.value = '';
            telefono.current.value = '';
            corpo.current.value = '';

            document.getElementById('Calcola').style.display = 'none';
            document.getElementById('Pavimento').style.display = 'none';
            document.getElementById('Pavimento2').style.display = 'none';
            document.getElementById('Bagni').style.display = 'none';
            document.getElementById('Frequenza').style.display = 'none';
        } else {
            toast.error(
                'La vostra e-mail ed il corpo del messaggio sono obbligatori e devono esser validi per poter inviare una richiesta e venire ricontattati. Grazie.'
            );
        }
    };

    const CalcoloTotale = async () => {
        let PavimentiValori = {
            Marmo: 1,
            'Gres Porcellanato': 0.8,
            Parquet: 1,
            Lamellare: 1,
            Moquet: 1.2,
        };
        let moltiplicatorone;

        let moltiplicatoreA = PavimentiValori[tipoPavimento.current.value];
        let moltiplicatoreB = PavimentiValori[tipoPavimento2.current.value];

        if (tipoPavimento2.current.value) {
            if (luogo.current.value === 'Appartamento') {
                if (tipoPulizia.current.value === 'Sgrosso') {
                    moltiplicatorone = 14;
                    preventivo =
                        moltiplicatorone *
                        (0.7 * (metri.current.value * moltiplicatoreA) +
                            0.3 * (metri.current.value * moltiplicatoreB) +
                            0.05 * metri.current.value * bagni.current.value);
                } else if (tipoPulizia.current.value === 'Pulizia Ordinaria') {
                    moltiplicatorone = 1.1;
                    preventivo =
                        moltiplicatorone *
                        (0.7 * (metri.current.value * moltiplicatoreA) +
                            0.3 * (metri.current.value * moltiplicatoreB) +
                            0.05 * metri.current.value * bagni.current.value);
                    preventivo = preventivo * (frequenza.current.value * 4);

                    console.log(metri.current.value, moltiplicatoreA, bagni.current.value, frequenza.current.value, preventivo);
                } else if (tipoPulizia.current.value === 'Sanificazione') {
                    moltiplicatorone = 2;
                    preventivo = metri.current.value * moltiplicatorone;
                }
            } else if (luogo.current.value === 'Ufficio') {
                if (tipoPulizia.current.value === 'Sgrosso') {
                    moltiplicatorone = 11;
                    preventivo =
                        moltiplicatorone *
                        (0.7 * (metri.current.value * moltiplicatoreA) +
                            0.3 * (metri.current.value * moltiplicatoreB) +
                            0.05 * metri.current.value * bagni.current.value);
                } else if (tipoPulizia.current.value === 'Pulizia Ordinaria') {
                    moltiplicatorone = 1.2;
                    preventivo =
                        moltiplicatorone *
                        (0.7 * (metri.current.value * moltiplicatoreA) +
                            0.3 * (metri.current.value * moltiplicatoreB) +
                            0.05 * metri.current.value * bagni.current.value);
                    preventivo = preventivo * (frequenza.current.value * 4);
                } else if (tipoPulizia.current.value === 'Sanificazione') {
                    moltiplicatorone = 2;
                    preventivo = metri.current.value * moltiplicatorone;
                }
            } else if (luogo.current.value === 'Attività Commerciale') {
                if (tipoPulizia.current.value === 'Sgrosso') {
                    moltiplicatorone = 12;
                    preventivo =
                        moltiplicatorone *
                        (0.7 * (metri.current.value * moltiplicatoreA) +
                            0.3 * (metri.current.value * moltiplicatoreB) +
                            0.05 * metri.current.value * bagni.current.value);
                } else if (tipoPulizia.current.value === 'Pulizia Ordinaria') {
                    moltiplicatorone = 1.2;
                    preventivo =
                        moltiplicatorone *
                        (0.7 * (metri.current.value * moltiplicatoreA) +
                            0.3 * (metri.current.value * moltiplicatoreB) +
                            0.05 * metri.current.value * bagni.current.value);
                    preventivo = preventivo * (frequenza.current.value * 4);
                } else if (tipoPulizia.current.value === 'Sanificazione') {
                    moltiplicatorone = 2;
                    preventivo = metri.current.value * moltiplicatorone;
                }
            }
        } else {
            let moltiplicatorone;
            if (luogo.current.value === 'Appartamento') {
                if (tipoPulizia.current.value === 'Sgrosso') {
                    moltiplicatorone = 14;
                    preventivo = moltiplicatorone * (metri.current.value * moltiplicatoreA + 0.05 * metri.current.value * bagni.current.value);
                } else if (tipoPulizia.current.value === 'Pulizia Ordinaria') {
                    moltiplicatorone = 1.1;
                    preventivo = moltiplicatorone * (metri.current.value * moltiplicatoreA + 0.05 * metri.current.value * bagni.current.value);
                    preventivo = preventivo * (frequenza.current.value * 4);
                    console.log(metri.current.value, moltiplicatoreA, bagni.current.value, frequenza.current.value, preventivo);
                } else if (tipoPulizia.current.value === 'Sanificazione') {
                    moltiplicatorone = 2;
                    preventivo = metri.current.value * moltiplicatorone;
                }
            } else if (luogo.current.value === 'Ufficio') {
                if (tipoPulizia.current.value === 'Sgrosso') {
                    moltiplicatorone = 11;
                    preventivo = moltiplicatorone * (metri.current.value * moltiplicatoreA + 0.05 * metri.current.value * bagni.current.value);
                } else if (tipoPulizia.current.value === 'Pulizia Ordinaria') {
                    moltiplicatorone = 1.2;
                    preventivo = moltiplicatorone * (metri.current.value * moltiplicatoreA + 0.05 * metri.current.value * bagni.current.value);
                    preventivo = preventivo * (frequenza.current.value * 4);
                } else if (tipoPulizia.current.value === 'Sanificazione') {
                    moltiplicatorone = 2;
                    preventivo = metri.current.value * moltiplicatorone;
                }
            } else if (luogo.current.value === 'Attività Commerciale') {
                if (tipoPulizia.current.value === 'Sgrosso') {
                    moltiplicatorone = 12;
                    preventivo = moltiplicatorone * (metri.current.value * moltiplicatoreA + 0.05 * metri.current.value * bagni.current.value);
                } else if (tipoPulizia.current.value === 'Pulizia Ordinaria') {
                    moltiplicatorone = 1.2;
                    preventivo = moltiplicatorone * (metri.current.value * moltiplicatoreA + 0.05 * metri.current.value * bagni.current.value);
                    preventivo = preventivo * (frequenza.current.value * 4);
                } else if (tipoPulizia.current.value === 'Sanificazione') {
                    moltiplicatorone = 2;
                    preventivo = metri.current.value * moltiplicatorone;
                }
            }
        }
    };

    const CheckMate = () => {
        if (tipoPulizia.current.value === 'Sanificazione') {
            if (metri.current.value && luogo.current.value) {
                document.getElementById('Calcola').style.display = 'block';
            }
        } else if (tipoPulizia.current.value === 'Pulizia Ordinaria') {
            document.getElementById('Pavimento').style.display = 'block';
            document.getElementById('Pavimento2').style.display = 'block';
            document.getElementById('Bagni').style.display = 'block';
            document.getElementById('Frequenza').style.display = 'block';

            if (metri.current.value && luogo.current.value && tipoPavimento.current.value && bagni.current.value && frequenza.current.value) {
                document.getElementById('Calcola').style.display = 'block';
            }
        } else if (!tipoPulizia.current.value) {
        } else {
            document.getElementById('Pavimento').style.display = 'block';
            if (metri.current.value && luogo.current.value && tipoPavimento.current.value) {
                document.getElementById('Calcola').style.display = 'block';
            }
        }
    };

    return (
        <div id='Contenuto'>
            <h1>Preventivi per Pulizie</h1>
            <div id='PREVENTIVI'>
                <div>
                    <form>
                        <label htmlFor='Luogo'>Luogo</label>
                        <select name='Luogo' ref={luogo} onChange={CheckMate} id='Luogo'>
                            <option placeholder='Scegli' label='Scegli' value=''></option>
                            <option>Appartamento</option>
                            <option>Ufficio</option>
                            <option>Attività Commerciale</option>
                        </select>

                        <label htmlFor='TipoPulizia'>Tipo Pulizia</label>
                        <select name='TipoPulizia' ref={tipoPulizia} onChange={CheckMate} id='TipoPulizia'>
                            <option placeholder='Scegli' label='Scegli' value=''></option>
                            <option>Sgrosso</option>
                            <option>Pulizia Ordinaria</option>
                            <option>Sanificazione</option>
                        </select>

                        <label htmlFor='Metri'>Metri Quadrati</label>
                        <select name='Metri' ref={metri} onChange={CheckMate} id='Metri'>
                            <option placeholder='Scegli' label='Scegli' value=''></option>
                            <option value='20'>20</option>
                            <option value='25'>25</option>
                            <option value='30'>30</option>
                            <option value='35'>35</option>
                            <option value='40'>40</option>
                            <option value='45'>45</option>
                            <option value='50'>50</option>
                            <option value='55'>55</option>
                            <option value='60'>60</option>
                            <option value='65'>65</option>
                            <option value='70'>70</option>
                            <option value='75'>75</option>
                            <option value='80'>80</option>
                            <option value='85'>85</option>
                            <option value='90'>90</option>
                            <option value='95'>95</option>
                            <option value='100'>100</option>
                            <option value='105'>105</option>
                            <option value='110'>110</option>
                            <option value='115'>115</option>
                            <option value='120'>120</option>
                            <option value='125'>125</option>
                            <option value='130'>130</option>
                            <option value='135'>135</option>
                            <option value='140'>140</option>
                            <option value='145'>145</option>
                            <option value='150'>150</option>
                            <option value='155'>155</option>
                            <option value='160'>160</option>
                            <option value='165'>165</option>
                            <option value='170'>170</option>
                            <option value='175'>175</option>
                            <option value='180'>180</option>
                            <option value='185'>185</option>
                            <option value='190'>190</option>
                            <option value='195'>195</option>
                            <option value='200'>200</option>
                            <option value='205'>205</option>
                            <option value='210'>210</option>
                            <option value='215'>215</option>
                            <option value='220'>220</option>
                            <option value='225'>225</option>
                            <option value='230'>230</option>
                            <option value='235'>235</option>
                            <option value='240'>240</option>
                            <option value='245'>245</option>
                            <option value='250'>250</option>
                        </select>
                        <div id='Pavimento'>
                            <label htmlFor='TipoPavimento'>Tipo Pavimento</label>
                            <select name='TipoPavimento' ref={tipoPavimento} onChange={CheckMate} id='TipoPavimento'>
                                <option placeholder='Scegli' label='Scegli' value=''></option>
                                <option>Marmo</option>
                                <option>Gres Porcellanato</option>
                                <option>Parquet</option>
                                <option>Lamellare</option>
                                <option>Moquet</option>
                            </select>
                        </div>
                        <div id='Pavimento2'>
                            <label htmlFor='TipoPavimento2'>Tipo Pavimento Secondario</label>
                            <select name='TipoPavimento2' ref={tipoPavimento2} onChange={CheckMate} id='TipoPavimento2'>
                                <option placeholder='Scegli' label='Scegli' value=''></option>
                                <option>Marmo</option>
                                <option>Gres Porcellanato</option>
                                <option>Parquet</option>
                                <option>Lamellare</option>
                                <option>Moquet</option>
                            </select>
                        </div>
                        <div id='Bagni'>
                            <label htmlFor='Bagno'>Bagni</label>
                            <select name='Bagno' ref={bagni} onChange={CheckMate} id='Bagno'>
                                <option placeholder='Scegli' label='Scegli' value=''></option>
                                <option value='1'>1</option>
                                <option value='2'>2</option>
                                <option value='3'>3</option>
                                <option value='4'>4</option>
                                <option value='5'>5</option>
                            </select>
                        </div>
                        <div id='Frequenza'>
                            <label htmlFor='Freq'>Frequenza Settimanale</label>
                            <select name='Freq' ref={frequenza} onChange={CheckMate} id='Freq'>
                                <option value='1' defaultValue>
                                    1
                                </option>
                                <option value='2'>2</option>
                                <option value='3'>3</option>
                                <option value='4'>4</option>
                                <option value='5'>5</option>
                            </select>
                        </div>
                        <button type='button' id='Calcola' onClick={Calcola}>
                            Calcola
                        </button>
                    </form>
                    <hr></hr>
                    <div id='Mailer'>
                        <form action='post'>
                            <label htmlFor='email'>La tua e-Mail</label>
                            <input name='email' type='email' ref={mail} autoComplete='on' id='email' />
                            <label htmlFor='Telefono'>Il tuo Telefono</label>
                            <input name='Telefono' type='number' ref={telefono} id='Telefono' />
                            <label htmlFor='Corpo'>Testo Mail</label>
                            <textarea name='Corpo' rows='4' required ref={corpo} id='Corpo'></textarea>
                            <button type='button' onClick={Contattaci}>
                                Scrivici
                            </button>
                        </form>
                    </div>
                    <div id='Disclamer'>
                        <p>
                            <strong>Pagina in test, i calcoli non si basano su nessun valore reale.</strong>
                            Nessun dato viene memorizzato dal sito, ogni informazione sensibile immessa viene utilizzata esclusivamente per il calcolo
                            del preventivo e per fini statistici anonimi. Nel caso in cui inviate una mail (dalla vostra casella di posta o dal form
                            in questa pagina) questa verrà utilizzata con il solo scopo di rispondere alla vostra richiesta. Lo stesso vale per il
                            numero di telefono inserito nel form.
                        </p>
                    </div>
                </div>
                <hr></hr>
            </div>
        </div>
    );
}

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../Component/CSS/Piani.css';
import { getOut } from '../App';
import { toast } from 'react-toastify';
import Select from 'react-select';

export default function Piani() {
	getOut(10);

	const [data, setData] = useState([]);
	const [formCompleted, setFormCompleted] = useState(false);

	const Mansione = [
		{ value: '', label: 'Nessuno' },
		{ value: 'Amministrativo', label: 'Amministrativo' },
		{ value: 'Facchino', label: 'Facchino' },
		{ value: 'Operatore', label: 'Operatore' },
		{ value: 'Operatore Pulizie', label: 'Operatore Pulizie' },
		{ value: 'Receptionist', label: 'Receptionist' },
	];
	const Cantiere = [
		{ value: '', label: 'Nessuno' },

		{ value: 'Roma', label: 'Roma' },
		{ value: 'Roma 35', label: 'Roma 35' },
		{ value: 'Rag. Pesaro', label: 'Rag. Pesaro' },
		{ value: 'Trib. Pesaro', label: 'Trib.Pesaro' },
		{ value: 'Pref. Pesaro', label: 'Pref. Pesaro' },
		{ value: 'CC Pesaro', label: 'CC Pesaro' },
		{ value: 'Mot. Pesaro', label: 'Mot. Pesaro' },
		{ value: 'Proc. Latina', label: 'Proc. Latina' },
		{ value: 'Trib. Latina', label: 'Trib. Latina' },
		{ value: 'Giud. Pace', label: 'Giud. Pace' },
		{ value: 'Trib. Frosinone', label: 'Trib. Frosinone' },
		{ value: 'Uni. Urbino', label: 'Uni. Urbino' },
		{ value: 'Trib. Urbino', label: 'Trib. Urbino' },
		{ value: 'IDER', label: 'IDER' },
		{ value: 'Trib. Rimini', label: 'Trib. Rimini' },

		{ value: 'Ferie', label: 'Ferie' },
		{ value: 'Permesso', label: 'Permesso' },
		{ value: 'Malattia', label: 'Malattia' },
		{ value: 'Infortunio', label: 'Infortunio' },
		{ value: 'Aspettativa', label: 'Aspettativa' },
		{ value: '104', label: '104' },
		{ value: 'Sospensione', label: 'Sospensione' },
	];

	function getNextMondayAndSunday() {
		const today = new Date();
		const currentDay = today.getDay();
		const daysUntilNextMonday = 8 - currentDay + (currentDay === 0 ? 1 : 0);
		const nextMonday = new Date(today.setDate(today.getDate() + daysUntilNextMonday));
		const nextSunday = new Date(nextMonday);
		nextSunday.setDate(nextMonday.getDate() + 6);

		return {
			nextMonday: nextMonday.toISOString().split('T')[0],
			nextSunday: nextSunday.toISOString().split('T')[0],
		};
	}

	const SubmitPiano = async (e) => {
		e.preventDefault();

		const { nextMonday, nextSunday } = getNextMondayAndSunday();
		const formElements = e.target.elements;
		const formDataArray = [];

		for (let i = 0; i < data.length; i++) {
			let formDataObject = {};

			let conteggio = 0;

			for (let j = 0; j < formElements.length; j++) {
				const element = formElements[j];

				if (element.closest(`#PianiDiLavoro > div:nth-child(${i + 1})`)) {
					if (element.hasAttribute('name')) {
						if (element.tagName === 'INPUT') {
							if (
								element.name === 'CantiereLun' ||
								element.name === 'CantiereMar' ||
								element.name === 'CantiereMer' ||
								element.name === 'CantiereGio' ||
								element.name === 'CantiereVen' ||
								element.name === 'CantiereSab'
							) {
								if (element.value !== '') {
									conteggio += 1;
								}
							}
							formDataObject[element.name] = element.value;
						} else if (element.tagName === 'TEXTAREA') {
							if (element.name === 'Nome') {
								const combinedValue = element.value;
								const [value, label] = combinedValue.split(': ');
								formDataObject['Nome'] = label;
								formDataObject['IDutente'] = value;
							} else {
								formDataObject[element.name] = element.value;
							}
						}
						formDataObject['Settimana'] = `${nextMonday} - ${nextSunday}`;
						formDataObject['Giorni'] = conteggio;

						let OreGiornaliere = (Number(formDataObject['OreSettimanali']) / conteggio).toFixed(4);
						formDataObject['OreGiorno'] = isNaN(OreGiornaliere) ? 0 : OreGiornaliere;
					}
				}
			}
			console.log(formDataArray);
			if (Object.keys(formDataObject).length > 0) {
				formDataArray.push(formDataObject);
			}
		}

		await axios.post(`${process.env.REACT_APP_URL_SERVER}/CreaPiano`, formDataArray);

		setFormCompleted(true);
		toast.success('Piani di Lavoro Modificati con successo');
	};

	useEffect(() => {
		// CONGELATO FINO ALLA PRIMA SETTIMANA DA INSERIRE GLI UTENTI REALI
		/* const getUser = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_URL_SERVER}/DipendentiAttivi`);
                const result = response.data;
                const formattedData = result.map((data) => ({
                    IDutente: data.ID,
                    Nome: data.Dipendente,
                }));

                setData(formattedData);
            } catch (error) {
                toast.error('Errore nella richiesta:', error);
            }
        };

        getUser(); */

		// FUNZIONE PER POPOLARE LA SETTIMANA SUCCESSIVA GIà ESISTENTE
		const getPianoLavoro = async () => {
			try {
				const response = await axios.get(`${process.env.REACT_APP_URL_SERVER}/ProssimoPianoSettimanale`);
				const result = response.data;

				const formattedData = result.map((data) => ({
					IDutente: data.IDutente,
					Nome: data.Nome,
					Attacco: data.Attacco,
					Mansione: data.Mansione,
					OreSettimanali: data.OreSettimanali,
					CantiereLun: data.CantiereLun,
					CantiereMar: data.CantiereMar,
					CantiereMer: data.CantiereMer,
					CantiereGio: data.CantiereGio,
					CantiereVen: data.CantiereVen,
					CantiereSab: data.CantiereSab,
					Note: data.Note,
				}));
				setData(formattedData);
			} catch (error) {
				toast.error('Errore nella richiesta:', error);
			} finally {
				setFormCompleted(false);
			}
		};

		getPianoLavoro();
	}, [formCompleted]);

	return (
		<div id='Contenuto'>
			<div id='Piani'>
				{process.env.REACT_APP_TIPOSITO === 'SVILUPPO' ? (
					<h1 style={{ color: 'red', textDecoration: 'underline' }}>SVILUPPO</h1>
				) : (
					<h1>Piani Di Lavoro</h1>
				)}

				<div style={{ color: 'green', width: '30vw', minWidth: '30vw', marginTop: '3vh', marginBottom: '5vh' }}>
					{
						<h2>
							{(() => {
								const { nextMonday, nextSunday } = getNextMondayAndSunday();
								return `Settimana dal:
                                    ${nextMonday} al ${nextSunday}`;
							})()}
						</h2>
					}
				</div>
				<form id='PianiDiLavoro' onSubmit={SubmitPiano}>
					{data.map((item, index) => (
						<div
							key={index}
							style={{
								border: '1px solid indigo',
								borderRadius: '5px',
								paddingInline: '15px',
								height: '25vh',
								minHeight: '190px',
								columnGap: '2vw',
							}}
						>
							<span>
								<textarea
									key={index + 50}
									name='Nome'
									value={`${item.IDutente}: ${item.Nome}`}
									style={{
										border: 'none',
										background: 'transparent',
										overflow: 'hidden',
										height: '25vh',
										minHeight: '25vh',
										maxHeight: '25vh',
										width: '15vw',
										minWidth: '15vw',
										maxWidth: '15vw',
										lineHeight: '23vh',
										textAlign: 'center',
									}}
									readOnly
								/>
							</span>

							<div style={{ display: 'flex', columnGap: '1vw' }}>
								<span className='LargoCosi'>
									<Select
										className='SelectWidth'
										key={index + 100}
										options={Mansione}
										name='Mansione'
										defaultValue={item.Mansione ? { value: item.Mansione, label: item.Mansione } : null}
										placeholder='Mansione'
										onChange={() => {}}
									/>
								</span>
								<span>
									<Select
										className='OreWidth'
										key={index + 1000}
										options={[
											{ value: '', label: 'Nessuno' },
											...Array.from({ length: 81 }, (_, i) => ({ value: i, label: `${i++} ore` })),
										]}
										name='OreSettimanali'
										defaultValue={item.OreSettimanali ? { value: item.OreSettimanali, label: item.OreSettimanali } : null}
										placeholder='OreSettimanali'
										onChange={() => {}}
									/>
								</span>
								<span>
									<Select
										className='OreWidth'
										key={index + 1100}
										options={[
											{ value: '', label: 'Nessuno' },
											...Array.from({ length: 24 * 2 }, (_, i) => {
												const hours = Math.floor(i / 2);
												const minutes = i % 2 === 0 ? '00' : '30';
												const time = `${hours.toString().padStart(2, '0')}:${minutes}`;
												return { value: time, label: time };
											}),
										]}
										name='Attacco'
										defaultValue={item.Attacco ? { value: item.Attacco, label: item.Attacco } : null}
										placeholder='Attacco'
										onChange={() => {}}
									/>
								</span>
							</div>

							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<span className='LargoCosi'>
									<Select
										className='SelectWidth'
										key={index + 200}
										options={Cantiere}
										name='CantiereLun'
										defaultValue={item.CantiereLun ? { value: item.CantiereLun, label: item.CantiereLun } : null}
										placeholder='Cantiere Lun'
										onChange={() => {}}
									/>
								</span>
								<span className='LargoCosi'>
									<Select
										className='SelectWidth'
										key={index + 300}
										options={Cantiere}
										name='CantiereMar'
										defaultValue={item.CantiereMar ? { value: item.CantiereMar, label: item.CantiereMar } : null}
										placeholder='Cantiere Mar'
										onChange={() => {}}
									/>
								</span>
								<span className='LargoCosi'>
									<Select
										className='SelectWidth'
										key={index + 400}
										options={Cantiere}
										name='CantiereMer'
										defaultValue={item.CantiereMer ? { value: item.CantiereMer, label: item.CantiereMer } : null}
										placeholder='Cantiere Mer'
										onChange={() => {}}
									/>
								</span>
							</div>

							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<span className='LargoCosi'>
									<Select
										className='SelectWidth'
										key={index + 500}
										options={Cantiere}
										name='CantiereGio'
										defaultValue={item.CantiereGio ? { value: item.CantiereGio, label: item.CantiereGio } : null}
										placeholder='Cantiere Gio'
										onChange={() => {}}
									/>
								</span>
								<span className='LargoCosi'>
									<Select
										className='SelectWidth'
										key={index + 600}
										options={Cantiere}
										name='CantiereVen'
										defaultValue={item.CantiereVen ? { value: item.CantiereVen, label: item.CantiereVen } : null}
										placeholder='Cantiere Ven'
										onChange={() => {}}
									/>
								</span>
								<span className='LargoCosi'>
									<Select
										className='SelectWidth'
										key={index + 700}
										options={Cantiere}
										name='CantiereSab'
										defaultValue={item.CantiereSab ? { value: item.CantiereSab, label: item.CantiereSab } : null}
										placeholder='Cantiere Sab'
										onChange={() => {}}
									/>
								</span>
							</div>

							<span className='NoteWidth'>
								<textarea
									className='Note'
									key={index + 1200}
									name='Note'
									defaultValue={item.Note ? item.Note : null}
									rows={4}
									placeholder='Note'
								/>
							</span>
						</div>
					))}
					<button
						type='submit'
						style={{ width: '100%', marginLeft: '25%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
					>
						Inserisci Piano di Lavoro
					</button>
				</form>
			</div>
		</div>
	);
}

import React, { Component } from 'react';
import './CSS/Footer.css';
import {
	IoMapOutline,
	IoCallOutline,
	IoCall,
	IoMailOutline,
	IoMailUnreadOutline,
	IoNewspaperOutline,
	IoInformationSharp,
} from 'react-icons/io5';

class Footer extends Component {
	state = {};
	render() {
		return (
			<>
				<footer>
					<span>
						<div className='Fine'>
							<div className='left'>
								<ul>
									<li>Pulizie</li>
									<li>Pest Control</li>
									<li>Facchinaggio e Logistica</li>
									<li>Gestione Mense</li>
									<li>
										Smaltimento rifiuti categorie: <br />
										1E, 2E, 3E, 4E, 5E, 8F
									</li>
								</ul>
							</div>

							<div className='center'>
								<p>
									<a href='/Home.html'>Home</a>
									<br />
									<a href='/Preventivi'>Preventivi</a>
									<br />
									<a href='/Certificazioni'>Certificazioni</a>
									<br />
									<a href='/ComunicazioniAnonime'>Comunicazioni</a>
									<br />
									<a href='/Accesso'>Area Riservata</a>
									<br />
									<a
										href={process.env.REACT_APP_LINKCONSIP}
										rel='noreferrer'
										target='_blank'
									>
										{process.env.REACT_APP_CONSIP}
									</a>{' '}
									<br />
								</p>
							</div>

							<div className='right'>
								<p>
									<span className='Icon'>
										<IoMapOutline />
									</span>
									{process.env.REACT_APP_INDIRIZZO} <br />
									<span className='Icon'>
										<IoCallOutline />
									</span>
									{process.env.REACT_APP_TELEFONO} <br />
									<span className='Icon'>
										<IoCall />
									</span>
									{process.env.REACT_APP_CELLULARE} <br />
									<span className='Icon'>
										<IoMailOutline />
									</span>
									{process.env.REACT_APP_MAILPRIMARIA} <br />
									<span className='Icon'>
										<IoMailUnreadOutline />
									</span>
									{process.env.REACT_APP_PEC} <br />
									<span className='Icon'>
										<IoNewspaperOutline />
									</span>
									{process.env.REACT_APP_PIVA}
									<br />
									<span className='Icon'>
										<IoInformationSharp />
									</span>
									{process.env.REACT_APP_CODICEUNIVOCO}
								</p>
							</div>
						</div>
					</span>
				</footer>
				<div className='Finale'>©2024 {process.env.REACT_APP_SITO}</div>
			</>
		);
	}
}

export default Footer;

import React, { useState, useMemo } from 'react';
import axios from 'axios';

const Select = ({ value }) => {
    const [dati, setDati] = useState([]);

    const ottieniDati = async (value) => {
        try {
            const risposta = await axios.get(`${process.env.REACT_APP_URL_SERVER}/${value.url}`);
            setDati(risposta.data);
        } catch (error) {
            console.log(error);
        }
    };

    useMemo(() => {
        ottieniDati(value);
    }, [value]);

    return (
        <>
            <option label='Scegli' placeholder='Scegli' value=''></option>
            {dati.map((elemento) => {
                return (
                    <option key={Math.random()} value={elemento[value.Value]}>
                        {elemento[value.Display]}
                    </option>
                );
            })}
        </>
    );
};

export default Select;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';

const opzioniPaginazione = {
	rowsPerPageText: 'Righe per pagina',
	rangeSeparatorText: 'di',
	selectAllRowsItem: true,
	selectAllRowsItemText: 'Tutto',
};

const TabellaAnagrafiche = ({ value }) => {
	const [dati, setDati] = useState([]);

	let columns = [],
		data = [];

	useEffect(() => {
		const ottieniDati = async () => {
			const Cantiere = value.Cantiere;
			try {
				const risposta = await axios.post(
					`${process.env.REACT_APP_URL_SERVER}/UtentiDipendenti`,
					{ Cantiere: Cantiere }
				);
				setDati(risposta.data);
			} catch (error) {
				console.log(error);
			}
		};
		ottieniDati();
	}, [value]);

	dati.forEach((element) => {
		let dato = {};
		for (const [key, value] of Object.entries(element)) {
			if (key === 'Indirizzo') {
				let Indi = JSON.parse(value);
				dato['Via'] = Indi.Via;
				dato['Citta'] = Indi.Citta;
				dato['CAP'] = Indi.CAP;
			} else if (key === 'Taglie') {
				let Taglie = JSON.parse(value);
				dato['Maglietta'] = Taglie.Maglietta;
				dato['Calzoni'] = Taglie.Calzoni;
				dato['Scarpe'] = Taglie.Scarpe;
			} else if (
				key === 'DataNascita' ||
				key === 'InizioContratto' ||
				key === 'FineContratto' ||
				key === 'ScadenzaVisita'
			) {
				dato[key] = new Date(value * 1000).toISOString().slice(0, 10);
			} else if (
				key === 'Con104' ||
				key === 'Limitazioni' ||
				key === 'Invalidita' ||
				key === 'Termine'
			) {
				value === 'false' ? (dato[key] = 'NO') : (dato[key] = 'SI');
			} else if (key === 'Indeterminato') {
				value === 'false' ? (dato[key] = 'NO') : (dato[key] = 'SI');
			} else {
				dato[key] = value;
			}
		}
		data.push(dato);
	});

	columns = [
		{
			name: `Dipendente`,
			selector: (row) => row[`Dipendente`],
			cellExport: (row) => row[`Dipendente`],
			sortable: true,
			wrap: true,
			compact: true,
			center: true,
		},
		{
			name: `Part`,
			selector: (row) => row[`PercentualePartTime`],
			cellExport: (row) => row[`PercentualePartTime`],
			sortable: true,
			wrap: true,
			compact: true,
			center: true,
		},

		{
			name: `Nascita`,
			selector: (row) => row[`DataNascita`],
			cellExport: (row) => row[`DataNascita`],
			sortable: true,
			wrap: true,
			compact: true,
			center: true,
		},
		{
			name: `CF`,
			selector: (row) => row[`CodiceFiscale`],
			cellExport: (row) => row[`CodiceFiscale`],
			sortable: true,
			wrap: true,
			compact: true,
			center: true,
		},
		/* {
            name: `Azienda`,
            selector: (row) => row[`Azienda`],
            cellExport: (row) => row[`Azienda`],
            sortable: true,
            wrap: true,
            compact: true,
            center: true,
        }, */
		{
			name: `Cantiere`,
			selector: (row) => row[`Cantiere`],
			cellExport: (row) => row[`Cantiere`],
			sortable: true,
			wrap: true,
			compact: true,
			center: true,
		},
		{
			name: `Cellulare`,
			selector: (row) => row[`Cellulare`],
			cellExport: (row) => row[`Cellulare`],
			sortable: true,
			wrap: true,
			compact: true,
			center: true,
		},
		{
			name: `Ore`,
			selector: (row) => row[`Ore`],
			cellExport: (row) => row[`Ore`],
			sortable: true,
			wrap: true,
			compact: true,
			center: true,
		},
		{
			name: `104`,
			selector: (row) => row[`Con104`],
			cellExport: (row) => row[`Con104`],
			sortable: true,
			wrap: true,
			compact: true,
			center: true,
		},
		{
			name: `Limitazioni`,
			selector: (row) => row[`Limitazioni`],
			cellExport: (row) => row[`Limitazioni`],
			sortable: true,
			wrap: true,
			compact: true,
			center: true,
		},
		{
			name: `Invalidità`,
			selector: (row) => row[`Invalidita`],
			cellExport: (row) => row[`Invalidita`],
			sortable: true,
			wrap: true,
			compact: true,
			center: true,
		},
		{
			name: `Sc. Visita`,
			selector: (row) => row[`ScadenzaVisita`],
			cellExport: (row) => row[`ScadenzaVisita`],
			sortable: true,
			wrap: true,
			compact: true,
			center: true,
		},
		/* {
            name: `Note`,
            selector: (row) => row[`Note`],
            cellExport: (row) => row[`Note`],
            sortable: true,
            wrap: true,
            compact: true,
            center: true,
        }, */
		{
			name: `Città`,
			selector: (row) => row[`Citta`],
			cellExport: (row) => row[`Citta`],
			sortable: true,
			wrap: true,
			compact: true,
			center: true,
		},
		/* {
            name: `Via`,
            selector: (row) => row[`Via`],
            cellExport: (row) => row[`Via`],
            sortable: true,
            wrap: true,
            compact: true,
            center: true,
        }, 
        {
            name: `Maglietta`,
            selector: (row) => row[`Maglietta`],
            cellExport: (row) => row[`Maglietta`],
            sortable: true,
            wrap: true,
            compact: true,
            center: true,
        },
        {
            name: `Calzoni`,
            selector: (row) => row[`Calzoni`],
            cellExport: (row) => row[`Calzoni`],
            sortable: true,
            wrap: true,
            compact: true,
            center: true,
        },
        {
            name: `Scarpe`,
            selector: (row) => row[`Scarpe`],
            cellExport: (row) => row[`Scarpe`],
            sortable: true,
            wrap: true,
            compact: true,
            center: true,
        }, */
		{
			name: `Inizio Con.`,
			selector: (row) => row[`InizioContratto`],
			cellExport: (row) => row[`InizioContratto`],
			sortable: true,
			wrap: true,
			compact: true,
			center: true,
		},
		{
			name: `Fine Con.`,
			selector: (row) => row[`FineContratto`],
			cellExport: (row) => row[`FineContratto`],
			sortable: true,
			wrap: true,
			compact: true,
			center: true,
		},
		{
			name: `Indeterm.`,
			selector: (row) => row[`Indeterminato`],
			cellExport: (row) => row[`Indeterminato`],
			sortable: true,
			wrap: true,
			compact: true,
			center: true,
		},
		{
			name: `Termine`,
			selector: (row) => row[`Termine`],
			cellExport: (row) => row[`Termine`],
			sortable: true,
			wrap: true,
			compact: true,
			center: true,
		},
	];

	const tableData = {
		columns,
		data,
	};
	const conditionalRowStyles = [
		{
			when: (row) => row.Termine === 'SI',
			style: {
				backgroundColor: 'darkgrey',
				color: 'white',
				'&:hover': {
					cursor: 'pointer',
				},
			},
		},
	];
	return (
		<DataTableExtensions {...tableData}>
			<DataTable
				title={value.url.replace(/([A-Z])/g, ' $1').trim()}
				defaultSortAsc={false}
				exportHeaders={true}
				fileName={value.url.replace(/([A-Z])/g, ' $1').trim()}
				pagination
				paginationComponentOptions={opzioniPaginazione}
				highlightOnHover
				conditionalRowStyles={conditionalRowStyles}
			/>
		</DataTableExtensions>
	);
};

export default TabellaAnagrafiche;

import React, { useState } from 'react';
import '../Component/CSS/ConsultazioneProtocolli.css';
import Tabelle from '../Component/Tabelle';

import { getOut } from '../App';

export default function ConsultazioneProtocolli() {
	getOut(10);

	const [inizio, setInizio] = useState(0);
	const [fine, setFine] = useState(0);

	const ImpostaDataInizio = (e) => {
		let StatoInizio = parseInt(e.target.valueAsNumber) / 1000;
		setInizio(StatoInizio);
	};
	const ImpostaDataFine = (e) => {
		let StatoFine = parseInt(e.target.valueAsNumber) / 1000 + 83400;
		setFine(StatoFine);
	};

	return (
		<div id='Contenuto'>
			{process.env.REACT_APP_TIPOSITO === 'SVILUPPO' ? (
				<h1 style={{ color: 'red', textDecoration: 'underline' }}>SVILUPPO</h1>
			) : (
				<h1>Consultazione Protocolli</h1>
			)}

			<div id='ConsultazioneProtocolli'>
				<div id='Tempi'>
					<label htmlFor='ImpostaDataInizio'>Da Quando</label>
					<sub>Se non compilato rende dal giorno zero</sub>
					<input
						type='date'
						name='ImpostaDataInizio'
						onChange={ImpostaDataInizio}
						id='ImpostaDataInizio'
						onFocus={() => document.getElementById('ImpostaDataInizio').showPicker()}
					/>

					<label htmlFor='ImpostaDataFine'>A Quando</label>
					<input
						type='date'
						name='ImpostaDataFine'
						onChange={ImpostaDataFine}
						id='ImpostaDataFine'
						onFocus={() => document.getElementById('ImpostaDataFine').showPicker()}
					/>
				</div>
				<hr></hr>
				<div id='Tabelle'>
					<Tabelle
						value={{
							url: 'ConsultazioneProtocolli',
							Inizio: inizio,
							Fine: fine,
						}}
					/>
				</div>
				<hr></hr>
			</div>
		</div>
	);
}

/* <table id='ProtocolliMalattie'>
    <thead>
        <tr>
            <th>Dipendente</th>
            <th>Protocollo</th>
            <th>Da Quando</th>
            <th>A Quando</th>
            <th>Giorni</th>
            <th>Inserito Il</th>
        </tr>
    </thead>
    <tbody></tbody>
</table> */

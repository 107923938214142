import React, { useRef } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';

import '../Component/CSS/ComunicazioniInterne.css';
import { getOut } from '../App';

export default function ComunicazioniInterne() {
    getOut(0);

    const nominativo = window.sessionStorage.getItem('Nome');
    const mail = useRef();
    const corpo = useRef();

    const InviaComunicazione = async () => {
        if (corpo.current.value) {
            await axios.post(`${process.env.REACT_APP_URL_SERVER}/InviaMail`, {
                Mail: process.env.REACT_APP_MAIL_COMUNICAZIONI,
                Oggetto: `Comunicazione interna da ${nominativo}`,
                Testo: `<strong>${nominativo}</strong> <br /><strong>Mail (se indicata): </strong>${mail.current.value}<br /><strong>Ha scritto:</strong><br /> ${corpo.current.value}`,
            });
            toast.success('Comunicazione Inviata.');
        } else {
            toast.error('Il corpo della comunicazione è vuoto, comunicazione NON INVIATA.');
        }
        mail.current.value = '';
        corpo.current.value = '';
    };

    return (
        <div id='Contenuto'>
            {process.env.REACT_APP_TIPOSITO === 'SVILUPPO' ? (
                <h1 style={{ color: 'red', textDecoration: 'underline' }}>SVILUPPO</h1>
            ) : (
                <h1>Comunicazioni Interne</h1>
            )}

            <div id='ComunicazioniInterne'>
                <form>
                    <label htmlFor='TestoComunicazione'>Comunicazione</label>
                    <textarea
                        id='TestoComunicazione'
                        name='TestoComunicazione'
                        placeholder={`
                            
                            
                            
Inserisci qui la comunicazione interna`}
                        rows='10'
                        cols='50'
                        ref={corpo}></textarea>
                    <label htmlFor='eMail'>eMail</label>
                    <input type='text' name='eMail' placeholder='eMail (Consigliata)' autoComplete='on' ref={mail} id='eMail' />
                    <button type='button' onClick={InviaComunicazione}>
                        Invia Comunicazione
                    </button>
                </form>
                <hr></hr>
            </div>
        </div>
    );
}

import React, { useState, useEffect } from 'react';
import axios from 'axios';

const SelectFile = ({ value }) => {
    const [dati, setDati] = useState([]);

    useEffect(() => {
        if (value.ID) {
            const ottieniDati = async (value) => {
                try {
                    const risposta = await axios.get(`${process.env.REACT_APP_URL_SERVER}/Popola`, {
                        params: {
                            ID: value.ID,
                            TipoFile: value.TipoFile,
                        },
                    });
                    risposta.data.length > 0 ? setDati(risposta.data) : setDati([]);
                } catch (error) {
                    console.log(error);
                }
            };
            ottieniDati(value);
        }
    }, [value]);

    return (
        <>
            <option label={value.TipoFile} placeholder={value.TipoFile} value=''></option>
            {dati.length > 0
                ? dati.map((elemento, index) => {
                      return (
                          <option key={Math.random()} value={elemento[index]}>
                              {elemento[index]}
                          </option>
                      );
                  })
                : null}
        </>
    );
};

export default SelectFile;

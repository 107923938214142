import React, { useState, useRef, useEffect, useCallback } from 'react';
import axios from 'axios';

import '../Component/CSS/Anagrafiche.css';
import SelectFile from '../Component/SelectFile';
import SelectDipendenti from '../Component/SelectDipendenti';

import TabellaAnagrafiche from '../Component/TabellaAnagrafiche';
import { getOut } from '../App';
import { toast } from 'react-toastify';

export default function Anagrafiche() {
	//Verifica
	getOut(9);

	//Variabili
	const ArraySelect = [
		'DocContratto',
		'DocVariazioniContratto',
		'DocAspettativa',
		'Doc104',
		'DocIdoneita',
		'DocInvalidita',
		'DocTFR',
		'DocAsset',
		'DocContestazioni',
		'DocRisposteContestazioni',
		'DocSanzioni',
		'DocAttestati',
	];

	const [IDDipendente, setIDDipendente] = useState((prev) => {
		return '';
	});
	const [aggiornato, setAggiornato] = useState(() => {
		return false;
	});
	const [aggiornaPagina, setAggiornaPagina] = useState(() => {
		return false;
	});

	const [mostraModificaUtente, setMostraModificaUtente] = useState(() => {
		return false;
	});
	const [mostraCreaDipendente, setMostraCreaDipendente] = useState(() => {
		return false;
	});

	let selezionaCantiere = useRef(() => {
		return 'NO';
	});

	//Variabili File disponibili/in visualizzazione
	const DocContratto = useRef(),
		DocVariazioniContratto = useRef(),
		DocAspettativa = useRef(),
		Doc104 = useRef(),
		DocIdoneita = useRef(),
		DocInvalidita = useRef(),
		DocTFR = useRef(),
		DocAsset = useRef(),
		DocContestazioni = useRef(),
		DocRisposteContestazioni = useRef(),
		DocSanzioni = useRef(),
		DocAttestati = useRef();

	//Variabili Nuovi file da caricare
	let file = useRef({}),
		NuovoFileContratto = useRef({}),
		NuovoFileVariazioni = useRef({}),
		NuovoFileAspettativa = useRef({}),
		NuovoFile104 = useRef({}),
		NuovoFileIdoneita = useRef({}),
		NuovoFileInvalidita = useRef({}),
		NuovoFileTFR = useRef({}),
		NuovoFileAsset = useRef({}),
		NuovoFileContestazioni = useRef({}),
		NuovoFileRisposteContestazioni = useRef({}),
		NuovoFileSanzioni = useRef({}),
		NuovoFileAttestati = useRef({});

	//Variabili Creazione
	let creaNomeDipendente = useRef(null),
		creaPassword = useRef(null),
		creaCodiceFiscale = useRef(null),
		creaLivello = useRef(null),
		creaAzienda = useRef(null),
		creaCantiere = useRef(null),
		creaCellulare = useRef(null),
		creaOre = useRef(null),
		creaIndirizzoVia = useRef(null),
		creaIndirizzoCitta = useRef(null),
		creaIndirizzoCAP = useRef(null),
		creaTagliaMaglietta = useRef(null),
		creaTagliaPantaloni = useRef(null),
		creaTagliaScarpe = useRef(null),
		creaCon104 = useRef({ current: { checked: false } }),
		creaLimitazioni = useRef({ current: { checked: false } }),
		creaInvalidita = useRef({ current: { checked: false } }),
		creaIndeterminato = useRef({ current: { checked: false } }),
		creaNote = useRef(null),
		creaInizioContratto = useRef(null),
		creaFineContratto = useRef(null),
		creaDataNascita = useRef(null),
		creaMail = useRef(null),
		creaTipoContratto = useRef(null),
		creaLivelloContratto = useRef(null),
		creaMansione = useRef(null),
		creaPercentualePartTime = useRef(null),
		creaTitoloStudio = useRef(null),
		creaScadenzaVisita = useRef(null),
		creaMatricola = useRef(null);

	//Variabili Modifica Dipendente
	let aggiornaNomeDipendente = useRef(null),
		aggiornaCodiceFiscale = useRef(null),
		aggiornaPassword = useRef(null),
		aggiornaLivello = useRef(null),
		aggiornaAzienda = useRef(null),
		aggiornaCantiere = useRef(null),
		aggiornaCellulare = useRef(null),
		aggiornaOre = useRef(null),
		aggiornaIndirizzoVia = useRef(null),
		aggiornaIndirizzoCitta = useRef(null),
		aggiornaIndirizzoCAP = useRef(null),
		aggiornaTagliaMaglietta = useRef(null),
		aggiornaTagliaPantaloni = useRef(null),
		aggiornaTagliaScarpe = useRef(null),
		aggiornaCon104 = useRef(null),
		aggiornaLimitazioni = useRef(null),
		aggiornaInvalidita = useRef(null),
		aggiornaTermine = useRef(null),
		aggiornaNote = useRef(null),
		aggiornaInizioContratto = useRef(null),
		aggiornaFineContratto = useRef(null),
		aggiornaIndeterminato = useRef(null),
		aggiornaDataNascita = useRef(null),
		aggiornaDataTermine = useRef(null),
		aggiornaMail = useRef(null),
		aggiornaTipoContratto = useRef(null),
		aggiornaLivelloContratto = useRef(null),
		aggiornaMansione = useRef(null),
		aggiornaPercentualePartTime = useRef(null),
		aggiornaTitoloStudio = useRef(null),
		aggiornaScadenzaVisita = useRef(null),
		aggiornaMatricola = useRef(null),
		[stoCaricando, setStoCaricando] = useState('0');

	const ToggleElement = (_stato) => {
		const Elementi = [
			'Level',
			'CantiereScelto',
			'Pantaloni',
			'Shoes',
			'Magl',
			'104',
			'Limitazioni',
			'Invalid',
			'Dimiss',
		];
		Elementi.forEach((EL) => {
			document.getElementById(`${EL}`).disabled = _stato;
		});
		const nodes = document.getElementById('Documentazione').querySelectorAll('button', 'input');
		const select = document.getElementById('Documentazione').querySelectorAll('select');
		nodes.forEach((el) => {
			el.disabled = _stato;
		});
		select.forEach((el) => {
			el.disabled = false;
		});
	};

	//Utility
	const AbilitaSelect = async () => {
		document.getElementById('Documentazione').style.visibility = 'visible';
		document.getElementById('Documentazione').style.maxHeight = '100%';
	};
	const DisabilitaSelect = async () => {
		document.getElementById('Documentazione').style.visibility = 'hidden';
		document.getElementById('Documentazione').style.maxHeight = '1px';
	};
	const AbilitaForm = useCallback(() => {
		const form = document.getElementById('FormModificaUtente');
		const elements = form.elements;
		for (let i = 0; i < elements.length; ++i) {
			elements[i].readOnly = false;
			elements[i].disabled = false;
			elements[i].style.background = '';
		}
		ToggleElement(false);
	}, []);

	const DisabilitaForm = useCallback(() => {
		const form = document.getElementById('FormModificaUtente');
		const elements = form.elements;

		for (let i = 0; i < elements.length; ++i) {
			if (elements[i].id !== 'SceltaCantiere' && elements[i].id !== 'Nome') {
				elements[i].readOnly = true;
				elements[i].disabled = true;
				elements[i].style.background = 'lightgrey';
			}
		}
		ToggleElement(true);
	}, []);
	const Deseleziona = (_Esclusione) => {
		ArraySelect.forEach((o) => {
			if (o !== _Esclusione) {
				let daDeselezionare = document.querySelector(`[name=${o}]`);
				daDeselezionare.value = '';
			}
		});
	};
	const ResetForm = async () => {
		// Creazione
		creaNomeDipendente.current.value = null;
		creaPassword.current.value = null;
		creaCodiceFiscale.current.value = null;
		creaLivello.current.value = null;
		creaAzienda.current.value = null;
		creaCantiere.current.value = null;
		creaCellulare.current.value = null;
		creaOre.current.value = null;
		creaIndirizzoVia.current.value = null;
		creaIndirizzoCitta.current.value = null;
		creaIndirizzoCAP.current.value = null;
		creaTagliaMaglietta.current.value = null;
		creaTagliaPantaloni.current.value = null;
		creaTagliaScarpe.current.value = null;
		creaCon104.current.checked = false;
		creaLimitazioni.current.checked = false;
		creaInvalidita.current.checked = false;
		creaIndeterminato.current.checked = false;
		creaNote.current.value = null;
		creaInizioContratto.current.value = null;
		creaFineContratto.current.value = null;
		creaDataNascita.current.value = null;
		creaMail.current.value = null;
		creaTipoContratto.current.value = null;
		creaLivelloContratto.current.value = null;
		creaMansione.current.value = null;
		creaPercentualePartTime.current.value = null;
		creaTitoloStudio.current.value = null;
		creaScadenzaVisita.current.value = null;
		creaMatricola.current.value = null;

		//Modifica
		document.getElementById('FileShow').setAttribute('data', '');
		document.getElementById('ScaricaSeNonLoVedi').setAttribute('href', '');
		document.getElementById('ScaricaSeNonLoVedi').textContent = '';
		document.getElementById('ScaricaMi').setAttribute('href', '');
		document.getElementById('FotoProfilo').src = '';
		document.getElementById('FotoProfilo').className = '';
		document.getElementById('UploadIMG').className = '';
		document.getElementById('FotoProfilo').className = 'nodisplay';
		document.getElementById('UploadIMG').className = 'nodisplay';

		aggiornaNomeDipendente.current.value = null;
		aggiornaPassword.current.value = null;
		aggiornaCodiceFiscale.current.value = null;
		aggiornaLivello.current.value = null;
		aggiornaAzienda.current.value = null;
		aggiornaCantiere.current.value = null;
		aggiornaCellulare.current.value = null;
		aggiornaOre.current.value = null;
		aggiornaCon104.current.checked = false;
		aggiornaLimitazioni.current.checked = false;
		aggiornaInvalidita.current.checked = false;
		aggiornaTermine.current.checked = false;
		aggiornaIndeterminato.current.checked = false;
		aggiornaNote.current.value = null;
		aggiornaIndirizzoVia.current.value = null;
		aggiornaIndirizzoCitta.current.value = null;
		aggiornaIndirizzoCAP.current.value = null;
		aggiornaTagliaMaglietta.current.value = null;
		aggiornaTagliaPantaloni.current.value = null;
		aggiornaTagliaScarpe.current.value = null;
		aggiornaInizioContratto.current.value = null;
		aggiornaFineContratto.current.value = null;
		aggiornaDataNascita.current.value = null;
		aggiornaDataTermine.current.value = null;
		aggiornaMail.current.value = null;
		aggiornaTipoContratto.current.value = null;
		aggiornaLivelloContratto.current.value = null;
		aggiornaMansione.current.value = null;
		aggiornaPercentualePartTime.current.value = null;
		aggiornaTitoloStudio.current.value = null;
		aggiornaScadenzaVisita.current.value = null;
		aggiornaMatricola.current.value = null;

		NuovoFileContratto.current.value = {};
		NuovoFileVariazioni.current.value = {};
		NuovoFileAspettativa.current.value = {};
		NuovoFile104.current.value = {};
		NuovoFileIdoneita.current.value = {};
		NuovoFileInvalidita.current.value = {};
		NuovoFileTFR.current.value = {};
		NuovoFileAsset.current.value = {};
		NuovoFileContestazioni.current.value = {};
		NuovoFileRisposteContestazioni.current.value = {};
		NuovoFileSanzioni.current.value = {};
		NuovoFileAttestati.current.value = {};

		DisabilitaSelect();
		const NomiDocumentiNuovi = [
			'NuovoFileContratto',
			'NuovoFileVariazioni',
			'NuovoFileAspettativa',
			'NuovoFile104',
			'NuovoFileIdoneita',
			'NuovoFileInvalidita',
			'NuovoFileTFR',
			'NuovoFileAsset',
			'NuovoFileContestazioni',
			'NuovoFileRisposteContestazioni',
			'NuovoFileSanzioni',
			'NuovoFileAttestati',
		];
		NomiDocumentiNuovi.forEach((_nome) => {
			document.getElementsByClassName(_nome)[0].innerText = '';
		});
	};

	useEffect(() => {
		NuovoFileContratto.current.value = {};
		NuovoFileVariazioni.current.value = {};
		NuovoFileAspettativa.current.value = {};
		NuovoFile104.current.value = {};
		NuovoFileIdoneita.current.value = {};
		NuovoFileInvalidita.current.value = {};
		NuovoFileTFR.current.value = {};
		NuovoFileAsset.current.value = {};
		NuovoFileContestazioni.current.value = {};
		NuovoFileRisposteContestazioni.current.value = {};
		NuovoFileSanzioni.current.value = {};
		NuovoFileAttestati.current.value = {};

		if (IDDipendente !== '' && IDDipendente) {
			const NomiDocumentiNuovi = [
				'NuovoFileContratto',
				'NuovoFileVariazioni',
				'NuovoFileAspettativa',
				'NuovoFile104',
				'NuovoFileIdoneita',
				'NuovoFileInvalidita',
				'NuovoFileTFR',
				'NuovoFileAsset',
				'NuovoFileContestazioni',
				'NuovoFileRisposteContestazioni',
				'NuovoFileSanzioni',
				'NuovoFileAttestati',
			];

			let Sorgente = `${process.env.REACT_APP_URL_BASE}/FotoProfilo/${IDDipendente}.jpg`;

			document.getElementById('FotoProfilo').src = Sorgente;

			document.getElementById('FotoProfilo').className = '';
			document.getElementById('UploadIMG').className = '';
			document.getElementById('FotoProfilo').className = 'display';
			document.getElementById('UploadIMG').className = 'display';

			const Compila = async () => {
				const risposta = await axios.post(
					`${process.env.REACT_APP_URL_SERVER}/FormModificaDipendente`,
					{
						ID: IDDipendente,
					}
				);

				let {
					Dipendente,
					CodiceFiscale,
					Password,
					DataNascita,
					Livello,
					Azienda,
					Cantiere,
					Cellulare,
					Ore,
					Con104,
					Limitazioni,
					Invalidita,
					Note,
					Indirizzo,
					Taglie,
					InizioContratto,
					FineContratto,
					Indeterminato,
					Mail,
					TipoContratto,
					LivelloContratto,
					Mansione,
					PercentualePartTime,
					TitoloStudio,
					ScadenzaVisita,
					Matricola,

					Termine,
					DataTermine,
				} = risposta.data[0];

				Indirizzo = JSON.parse(Indirizzo);
				Taglie = JSON.parse(Taglie);

				if (Con104 === 'true') {
					aggiornaCon104.current.checked = true;
				}
				if (Limitazioni === 'true') {
					aggiornaLimitazioni.current.checked = true;
				}
				if (Invalidita === 'true') {
					aggiornaInvalidita.current.checked = true;
				}
				if (Indeterminato === 'true') {
					aggiornaIndeterminato.current.checked = true;
				}
				if (Termine === 'true') {
					aggiornaTermine.current.checked = true;
					DisabilitaForm();
				} else {
					aggiornaTermine.current.checked = false;
					AbilitaForm();
				}

				aggiornaNomeDipendente.current.value = Dipendente;
				aggiornaCodiceFiscale.current.value = CodiceFiscale;
				aggiornaPassword.current.value = Password;
				aggiornaLivello.current.value = Livello;
				aggiornaAzienda.current.value = Azienda;
				aggiornaCantiere.current.value = Cantiere;
				aggiornaOre.current.value = Ore;
				aggiornaCellulare.current.value = Cellulare;

				aggiornaIndirizzoVia.current.value = Indirizzo.Via;
				aggiornaIndirizzoCitta.current.value = Indirizzo.Citta;
				aggiornaIndirizzoCAP.current.value = Indirizzo.CAP;

				aggiornaTagliaMaglietta.current.value = Taglie.Maglietta;
				aggiornaTagliaPantaloni.current.value = Taglie.Pantaloni;
				aggiornaTagliaScarpe.current.value = Taglie.Scarpe;

				aggiornaCon104.current.value = Con104;
				aggiornaLimitazioni.current.value = Limitazioni;
				aggiornaInvalidita.current.value = Invalidita;
				aggiornaTermine.current.value = Termine;
				aggiornaIndeterminato.current.value = Indeterminato;

				aggiornaNote.current.value = Note;
				aggiornaMail.current.value = Mail;

				aggiornaTipoContratto.current.value = TipoContratto;
				aggiornaLivelloContratto.current.value = LivelloContratto;
				aggiornaMansione.current.value = Mansione;
				aggiornaPercentualePartTime.current.value = PercentualePartTime;
				aggiornaTitoloStudio.current.value = TitoloStudio;

				aggiornaInizioContratto.current.value = new Date(InizioContratto * 1000)
					.toISOString()
					.slice(0, 10);
				aggiornaFineContratto.current.value = new Date(FineContratto * 1000)
					.toISOString()
					.slice(0, 10);
				aggiornaDataNascita.current.value = new Date(DataNascita * 1000)
					.toISOString()
					.slice(0, 10);
				aggiornaDataTermine.current.value = new Date(DataTermine * 1000)
					.toISOString()
					.slice(0, 10);
				aggiornaScadenzaVisita.current.value = new Date(ScadenzaVisita * 1000)
					.toISOString()
					.slice(0, 10);
				aggiornaMatricola.current.value = Matricola;

				selezionaCantiere.current.value = selezionaCantiere.current.value
					? selezionaCantiere.current.value
					: 'NO';

				NomiDocumentiNuovi.forEach((_nome) => {
					document.getElementsByClassName(_nome)[0].innerText = '';
				});
			};

			setAggiornato((prev) => !prev);

			Compila();
		}
	}, [AbilitaForm, DisabilitaForm, IDDipendente, aggiornaPagina]);

	//Popola
	const popolaForm = async (e) => {
		const urlDummy = `${process.env.REACT_APP_URL_BASE}/DipendentiDocumentazioneMaterialeVarie/LogoDEMO.png`;

		document.getElementById('FileShow').setAttribute('data', urlDummy);
		document.getElementById('ScaricaSeNonLoVedi').setAttribute('href', urlDummy);
		document.getElementById('ScaricaSeNonLoVedi').textContent = '';
		document.getElementById('ScaricaMi').setAttribute('href', urlDummy);

		setIDDipendente((prev) => e.target.value);
		AbilitaSelect();
	};

	// controllo cambiamento dei Check
	const status104 = function (e) {
		creaCon104.current.value = e.target.checked;
		creaCon104.current.checked = e.target.checked;
	};
	const statusLimitazioni = function (e) {
		creaLimitazioni.current.value = e.target.checked;
		creaLimitazioni.current.checked = e.target.checked;
	};
	const statusInvalidita = function (e) {
		creaInvalidita.current.value = e.target.checked;
		creaInvalidita.current.checked = e.target.checked;
	};
	const StatusIndeterminato = function (e) {
		creaIndeterminato.current.value = e.target.checked;
		creaIndeterminato.current.checked = e.target.checked;
	};

	const AggiornaStatus104 = function (e) {
		aggiornaCon104.current.value = e.target.checked;
	};
	const AggiornaStatusLimitazioni = function (e) {
		aggiornaLimitazioni.current.value = e.target.checked;
	};
	const AggiornaStatusInvalidita = function (e) {
		aggiornaInvalidita.current.value = e.target.checked;
	};

	const AggiornaStatusTermine = function (e) {
		aggiornaTermine.current.value = e.target.checked;
		aggiornaTermine.current.checked = e.target.checked;
	};
	const AggiornaStatusIndeterminato = function (e) {
		aggiornaIndeterminato.current.value = e.target.checked;
	};

	//Creazione
	const creaDip = async () => {
		if (
			creaNomeDipendente.current.value &&
			creaCodiceFiscale.current.value &&
			creaCellulare.current.value &&
			creaAzienda.current.value &&
			creaCantiere.current.value &&
			creaOre.current.value &&
			creaPassword.current.value &&
			creaLivello.current.value &&
			creaIndirizzoVia.current.value &&
			creaIndirizzoCitta.current.value &&
			creaIndirizzoCAP.current.value &&
			creaTagliaMaglietta.current.value &&
			creaTagliaPantaloni.current.value &&
			creaTagliaScarpe.current.value &&
			creaInvalidita.current.value &&
			creaInizioContratto.current.value &&
			creaDataNascita.current.value &&
			creaMail.current.value &&
			creaTipoContratto.current.value &&
			creaLivelloContratto.current.value &&
			creaMansione.current.value &&
			creaTitoloStudio.current.value &&
			creaScadenzaVisita.current.value &&
			creaMatricola.current.value
		) {
			const risposta = await axios.post(
				`${process.env.REACT_APP_URL_SERVER}/CreaDipendente`,
				{
					Dipendente: creaNomeDipendente.current.value,
					CodiceFiscale: creaCodiceFiscale.current.value,
					Cellulare: creaCellulare.current.value,
					Azienda: creaAzienda.current.value,
					Cantiere: creaCantiere.current.value,
					Ore: creaOre.current.value,
					Password: creaPassword.current.value,
					Livello: creaLivello.current.value,
					Con104: creaCon104.current.value === 'on' ? 'false' : creaCon104.current.value,
					Via: creaIndirizzoVia.current.value,
					Citta: creaIndirizzoCitta.current.value,
					CAP: creaIndirizzoCAP.current.value,
					Limitazioni:
						creaLimitazioni.current.value === 'on'
							? 'false'
							: creaLimitazioni.current.value,
					Maglietta: creaTagliaMaglietta.current.value,
					Pantaloni: creaTagliaPantaloni.current.value,
					Scarpe: creaTagliaScarpe.current.value,
					Invalidita:
						creaInvalidita.current.value === 'on'
							? 'false'
							: creaInvalidita.current.value,
					InizioContratto: creaInizioContratto.current.value,
					FineContratto: creaFineContratto.current.value
						? creaFineContratto.current.value
						: 32503636800000,
					Indeterminato:
						creaIndeterminato.current.value === 'on'
							? 'false'
							: creaIndeterminato.current.value,
					DataNascita: creaDataNascita.current.value,
					Note: creaNote.current.value,
					Mail: creaMail.current.value,

					TipoContratto: creaTipoContratto.current.value,
					LivelloContratto: creaLivelloContratto.current.value,
					Mansione: creaMansione.current.value,
					PercentualePartTime: creaPercentualePartTime.current.value,
					TitoloStudio: creaTitoloStudio.current.value,
					ScadenzaVisita: creaScadenzaVisita.current.value,
					Matricola: creaMatricola.current.value,
				}
			);

			async function Struttura() {
				const IDUtente = await axios.get(`${process.env.REACT_APP_URL_SERVER}/OttieniID`, {
					params: { CodiceFiscale: creaCodiceFiscale.current.value },
				});

				const CreaCartelle = await axios.post(
					`${process.env.REACT_APP_URL_SERVER}/CreaFolder`,
					{ ID: IDUtente.data[0].ID }
				);

				if (CreaCartelle) {
					console.log('Cartelle Create.');
				} else {
					toast.error('Questa Non Va...');
				}
			}

			if (risposta) {
				await Struttura();

				await ResetForm();
				toast.success('Utente Creato Correttamente.');

				setAggiornato((prev) => !prev);
			}
		} else {
			toast.warning('TUTTI i campi sono obbligatori.');
		}
	};

	//Aggiornamento
	const aggiornaDipendente = async () => {
		if (
			aggiornaNomeDipendente.current.value &&
			aggiornaCodiceFiscale.current.value &&
			aggiornaCellulare.current.value &&
			aggiornaAzienda.current.value &&
			aggiornaCantiere.current.value &&
			aggiornaOre.current.value &&
			aggiornaPassword.current.value &&
			aggiornaLivello.current.value &&
			aggiornaIndirizzoVia.current.value &&
			aggiornaIndirizzoCitta.current.value &&
			aggiornaIndirizzoCAP.current.value &&
			aggiornaTagliaMaglietta.current.value &&
			aggiornaTagliaPantaloni.current.value &&
			aggiornaTagliaScarpe.current.value &&
			aggiornaInvalidita.current.value &&
			aggiornaInizioContratto.current.value &&
			aggiornaDataNascita.current.value &&
			aggiornaMail.current.value &&
			aggiornaTipoContratto.current.value &&
			aggiornaLivelloContratto.current.value &&
			aggiornaMansione.current.value &&
			aggiornaTitoloStudio.current.value &&
			aggiornaScadenzaVisita.current.value &&
			aggiornaMatricola.current.value
		) {
			const Stato =
				aggiornaTermine.current.value === 'false' || aggiornaTermine.current.value === 'on'
					? 'true'
					: 'false';
			const ConfermaModifiche = async () => {
				const risposta = await axios.put(
					`${process.env.REACT_APP_URL_SERVER}/ModificaDipendente/${IDDipendente}`,
					{
						Dipendente: aggiornaNomeDipendente.current.value,
						CodiceFiscale: aggiornaCodiceFiscale.current.value,
						Password: aggiornaPassword.current.value,
						DataNascita: aggiornaDataNascita.current.value,
						Livello: aggiornaLivello.current.value,
						Azienda: aggiornaAzienda.current.value,
						Cantiere: aggiornaCantiere.current.value,
						Cellulare: aggiornaCellulare.current.value,
						Ore: aggiornaOre.current.value,
						Con104: aggiornaCon104.current.value,
						Limitazioni: aggiornaLimitazioni.current.value,
						Invalidita: aggiornaInvalidita.current.value,
						Note: aggiornaNote.current.value,
						Via: aggiornaIndirizzoVia.current.value,
						Citta: aggiornaIndirizzoCitta.current.value,
						CAP: aggiornaIndirizzoCAP.current.value,
						Maglietta: aggiornaTagliaMaglietta.current.value,
						Pantaloni: aggiornaTagliaPantaloni.current.value,
						Scarpe: aggiornaTagliaScarpe.current.value,
						Stato: Stato,
						Termine: aggiornaTermine.current.value,
						InizioContratto: aggiornaInizioContratto.current.value,
						FineContratto: aggiornaFineContratto.current.value,
						Indeterminato: aggiornaIndeterminato.current.value,
						DataTermine: aggiornaDataTermine.current.value,
						Mail: aggiornaMail.current.value,
						TipoContratto: aggiornaTipoContratto.current.value,
						LivelloContratto: aggiornaLivelloContratto.current.value,
						Mansione: aggiornaMansione.current.value,
						PercentualePartTime: aggiornaPercentualePartTime.current.value,
						TitoloStudio: aggiornaTitoloStudio.current.value,
						ScadenzaVisita: aggiornaScadenzaVisita.current.value,
						Matricola: aggiornaMatricola.current.value,
					}
				);

				if (risposta) {
					await PrendiIDoc();
					await CaricaDocDipendente();

					await ResetForm();
					await DisabilitaSelect();

					toast.success('Utente Modificato Correttamente.');
					DisabilitaForm();
					DisabilitaSelect();
					setAggiornato((prev) => !prev);
					setIDDipendente(''); //Azzerare dipendente?
				}
			};
			let Confirm;
			if (aggiornaTermine.current.value === 'true') {
				Confirm = window.confirm(
					`Confermi di voler impostare il Termine del Contratto con il dipendente? L'operazione renderà immodificabile il suo profilo, tutti i dati relativi all'utente resteranno visualizzabili senza problemi`
				);
			} else {
				ConfermaModifiche();
			}
			if (Confirm) {
				ConfermaModifiche();
			}
		} else {
			toast.warn(
				`Compila tutti i campi per poter Modificare l'utente, se vuoi annullare scegli un altro utente o ricarica la pagina.`
			);
		}
	};

	const ImpostaCantiereBase = async () => {
		await ResetForm();
		DisabilitaForm();
		DisabilitaSelect();
		setAggiornato((prev) => !prev);
	};

	//Visualizzazione
	const MostraModUtente = async () => {
		await ResetForm();

		DisabilitaForm();
		DisabilitaSelect();

		setMostraModificaUtente(true);
		setMostraCreaDipendente(false);
	};
	const MostraCreaDipendente = async () => {
		await ResetForm();
		DisabilitaForm();

		setMostraCreaDipendente(true);
		setMostraModificaUtente(false);
	};

	//Gestione documentazioni/file
	const PrendiLaFoto = (e) => {
		e.preventDefault();
		file.current.value = e.target.files[0];
	};
	const CaricaImmagineProfilo = async (e) => {
		e.preventDefault();

		let formData = new FormData();
		formData.append('file', file.current.value);
		if (file.current.value) {
			try {
				setStoCaricando('1');
				await axios.post(
					`${process.env.REACT_APP_URL_SERVER}/SalvaProfilo/${IDDipendente}`,
					formData,
					{
						headers: {
							'Content-Type': 'multipart/form-data',
						},
					}
				);

				document.getElementById(
					'FotoProfilo'
				).src = `${process.env.REACT_APP_URL_BASE}/FotoProfilo/${IDDipendente}.jpg`;
				setStoCaricando('0');
			} catch (error) {
				setStoCaricando('0');
				console.log(error);
			}
		}
	};
	const CaricaDocumento = (e, _file, _nome) => {
		e.preventDefault();
		document.getElementsByClassName(_nome)[0].innerText = e.target.files[0].name;
		_file.current.value = e.target.files[0];
	};
	const UploadFileSingolo = async (e, _file, _nomeFile) => {
		e.preventDefault();

		let formData = new FormData();
		formData.append(`${_nomeFile}`, _file.current.value);

		if (
			!(
				Object.keys(_file.current.value).length === 0 &&
				_file.current.value.constructor === Object
			)
		) {
			try {
				setStoCaricando('1');
				await axios.post(
					`${process.env.REACT_APP_URL_SERVER}/SalvaDocumenti/${IDDipendente}`,
					formData,
					{
						headers: {
							'Content-Type': 'multipart/form-data',
						},
					}
				);
				toast.success('Documento Inserito Correttamente');
				console.log('Caricato');
				if (_nomeFile === 'Risposte') {
					document.getElementsByClassName(`NuovoFileRisposteContestazioni`)[0].innerText =
						'';
				} else if (_nomeFile === 'La104') {
					document.getElementsByClassName(`NuovoFile104`)[0].innerText = '';
				} else {
					document.getElementsByClassName(`NuovoFile${_nomeFile}`)[0].innerText = '';
				}
				setStoCaricando('0');
			} catch (error) {
				setStoCaricando('0');
				toast.error(error);
				console.log(error);
			} finally {
				const TuttiGliInputFile = document.querySelectorAll('input[type=file]');
				TuttiGliInputFile.forEach((el) => {
					el.value = '';
				});
			}
		}
	};
	const PrendiIDoc = async () => {
		NuovoFileContratto.current.value =
			document.getElementById('DocumentiContratto').value !== ''
				? document.getElementById('DocumentiContratto').files[0]
				: null;
		NuovoFileVariazioni.current.value =
			document.getElementById('DocumentiVariazioni').value !== ''
				? document.getElementById('DocumentiVariazioni').files[0]
				: null;
		NuovoFileAspettativa.current.value =
			document.getElementById('DocumentiAspettativa').value !== ''
				? document.getElementById('DocumentiAspettativa').files[0]
				: null;
		NuovoFile104.current.value = NuovoFile104.current.value = document.getElementById(
			'Documenti104'
		).value
			? document.getElementById('Documenti104').files[0]
			: null;
		NuovoFileIdoneita.current.value =
			document.getElementById('DocumentiIdoneita').value !== ''
				? document.getElementById('DocumentiIdoneita').files[0]
				: null;
		NuovoFileInvalidita.current.value =
			document.getElementById('DocumentiInvalidita').value !== ''
				? document.getElementById('DocumentiInvalidita').files[0]
				: null;
		NuovoFileTFR.current.value =
			document.getElementById('DocumentiTFR').value !== ''
				? document.getElementById('DocumentiTFR').files[0]
				: null;
		NuovoFileAsset.current.value =
			document.getElementById('DocumentiAsset').value !== ''
				? document.getElementById('DocumentiAsset').files[0]
				: null;
		NuovoFileContestazioni.current.value =
			document.getElementById('DocumentiContestazioni').value !== ''
				? document.getElementById('DocumentiContestazioni').files[0]
				: null;
		NuovoFileRisposteContestazioni.current.value =
			document.getElementById('DocumentiRisposteContestazioni').value !== ''
				? document.getElementById('DocumentiRisposteContestazioni').files[0]
				: null;
		NuovoFileSanzioni.current.value =
			document.getElementById('DocumentiSanzioni').value !== ''
				? document.getElementById('DocumentiSanzioni').files[0]
				: null;
		NuovoFileAttestati.current.value =
			document.getElementById('DocumentiAttestati').value !== ''
				? document.getElementById('DocumentiAttestati').files[0]
				: null;
	};
	const CaricaDocDipendente = async () => {
		let formData = new FormData();

		if (NuovoFileContratto.current.value) {
			formData.append('Contratto', NuovoFileContratto.current.value);
		}
		if (NuovoFileVariazioni.current.value) {
			formData.append('Variazioni', NuovoFileVariazioni.current.value);
		}
		if (NuovoFileAspettativa.current.value) {
			formData.append('Aspettativa', NuovoFileAspettativa.current.value);
		}
		if (NuovoFile104.current.value) {
			formData.append('La104', NuovoFile104.current.value);
		}
		if (NuovoFileIdoneita.current.value) {
			formData.append('Idoneita', NuovoFileIdoneita.current.value);
		}
		if (NuovoFileInvalidita.current.value) {
			formData.append('Invalidita', NuovoFileInvalidita.current.value);
		}
		if (NuovoFileTFR.current.value) {
			formData.append('TFR', NuovoFileTFR.current.value);
		}
		if (NuovoFileAsset.current.value) {
			formData.append('Asset', NuovoFileAsset.current.value);
		}
		if (NuovoFileContestazioni.current.value) {
			formData.append('Contestazioni', NuovoFileContestazioni.current.value);
		}
		if (NuovoFileRisposteContestazioni.current.value) {
			formData.append('Risposte', NuovoFileRisposteContestazioni.current.value);
		}
		if (NuovoFileSanzioni.current.value) {
			formData.append('Sanzioni', NuovoFileSanzioni.current.value);
		}
		if (NuovoFileAttestati.current.value) {
			formData.append('Attestati', NuovoFileAttestati.current.value);
		}

		if (formData.entries().next().value) {
			try {
				setStoCaricando('1');
				await axios.post(
					`${process.env.REACT_APP_URL_SERVER}/SalvaDocumenti/${IDDipendente}`,
					formData,
					{
						headers: {
							'Content-Type': 'multipart/form-data',
						},
					}
				);
				setStoCaricando('0');
			} catch (error) {
				setStoCaricando('0');
				console.log(error);
			} finally {
				const TuttiGliInputFile = document.querySelectorAll('input[type=file]');
				TuttiGliInputFile.forEach((el) => {
					el.value = '';
				});
			}
		}
	};
	const SulCambioFile = (_Cartella, _Doc, _Esclusione) => {
		if (_Doc) {
			const urlFile = `${process.env.REACT_APP_URL_BASE}/DipendentiDocumentazioneMaterialeVarie/${IDDipendente}/${_Cartella}/${_Doc}`;
			document.getElementById('FileShow').setAttribute('data', urlFile);
			document.getElementById('ScaricaSeNonLoVedi').setAttribute('href', urlFile);
			document.getElementById('ScaricaSeNonLoVedi').textContent = _Doc;
			document.getElementById('ScaricaMi').setAttribute('href', urlFile);
			Deseleziona(_Esclusione);
			window.scrollTo(0, 0);
		} else {
			const urlFile = `${process.env.REACT_APP_URL_BASE}/DipendentiDocumentazioneMaterialeVarie/LogoDEMO.png`;
			document.getElementById('FileShow').setAttribute('data', urlFile);
			document.getElementById('ScaricaSeNonLoVedi').setAttribute('href', urlFile);
			document.getElementById('ScaricaSeNonLoVedi').textContent = '';
			document.getElementById('ScaricaMi').setAttribute('href', urlFile);
		}
	};
	const EliminaDocumento = async (_Dove, _Documento) => {
		if (_Documento) {
			let Confirm = window.confirm(
				`Confermi l'eliminazione del file ${_Documento}? L'operazione è irreversibile.`
			);

			if (Confirm) {
				setStoCaricando('1');
				try {
					await axios.post(
						`${process.env.REACT_APP_URL_SERVER}/EliminaDocumento/${IDDipendente}`,
						{ Dove: _Dove, NomeDoc: _Documento }
					);
					setAggiornaPagina((prev) => !prev);
					const urlDummy = `${process.env.REACT_APP_URL_BASE}/DipendentiDocumentazioneMaterialeVarie/LogoDEMO.png`;
					document.getElementById('FileShow').setAttribute('data', urlDummy);
					document.getElementById('ScaricaSeNonLoVedi').setAttribute('href', urlDummy);
					document.getElementById('ScaricaSeNonLoVedi').textContent = '';
					document.getElementById('ScaricaMi').setAttribute('href', urlDummy);
					setStoCaricando('0');
				} catch (error) {
					setStoCaricando('0');
					console.log(error);
				}
			}
		}
	};

	//Variabili componenti
	let tabellaDipendenti = {
		url: 'UtentiDipendenti',
		Cantiere: selezionaCantiere.current?.value ? selezionaCantiere.current.value : 'NO',
		Aggiornato: aggiornato,
	};

	const selezione = {
		Cantiere: selezionaCantiere.current?.value ? selezionaCantiere.current.value : 'NO',
		onChange: popolaForm,
	};

	return (
		<section>
			<div id='Contenuto'>
				{process.env.REACT_APP_TIPOSITO === 'SVILUPPO' ? (
					<h1 style={{ color: 'red', textDecoration: 'underline' }}>SVILUPPO</h1>
				) : (
					<h1>Anagrafiche</h1>
				)}
				<div id='Anagrafiche'>
					<div id='Pulsantiera'>
						<button onClick={MostraModUtente}>Modifica Utente</button>
						<button onClick={MostraCreaDipendente} style={{ background: 'crimson' }}>
							Crea Utente
						</button>
					</div>
					<div
						id='CreaDipendenti'
						style={{ display: mostraCreaDipendente ? 'block' : 'none' }}
					>
						<h2>Crea Dipendente</h2>
						<span className='Colonna'>
							<div>
								<label htmlFor='CreaNome'>Cognome/Nome</label>
								<input id='CreaNome' ref={creaNomeDipendente} />
							</div>
							<div>
								<label htmlFor='CreaCF'>Codice Fiscale (Nome di Accesso)</label>
								<input id='CreaCF' type='text' ref={creaCodiceFiscale} />
							</div>
							<div>
								<label htmlFor='PassW'>Password</label>
								<input id='PassW' type='text' ref={creaPassword} />
							</div>
							<div>
								<label htmlFor='CreaLivello'>Livello Utenza</label>
								<select
									id='CreaLivello'
									defaultValue={creaLivello}
									ref={creaLivello}
								>
									<option label='Livello Utente' value=''></option>
									<option value='0'>Utente Standard</option>
									<option value='5'>Utente Speciale</option>
									<option value='2'>Cliente</option>
									<option value='-1'>Solo Scansioni</option>
								</select>
							</div>
							<div>
								<label htmlFor='CreaAzienda'>Azienda</label>
								<input
									id='CreaAzienda'
									name='CreaAzienda'
									type='text'
									ref={creaAzienda}
								/>
							</div>
							<div>
								<label htmlFor='CreaCantiere'>Cantiere</label>
								<select id='CreaCantiere' ref={creaCantiere}>
									<option label='Scegli' placeholder='Scegli' value=''></option>
									<option value='Cam. Comm Pesaro'>Cam. Comm Pesaro</option>
									<option value='CCIA Pesaro'>CCIA Pesaro</option>
									<option value='Comm. Tribut. Marche'>
										Comm. Tribut. Marche
									</option>
									<option value='Giud. Pace Latina'>Giud. Pace Latina</option>
									<option value='Giud. Pace Pesaro'>Giud. Pace Pesaro</option>
									<option value='IDER'>IDER</option>
									<option value='Mot. Civ. Pesaro'>Mot. Civ. Pesaro</option>
									<option value='Pref. Pesaro'>Pref. Pesaro</option>
									<option value='Proc. Latina'>Proc. Latina</option>
									<option value='Rag. Pesaro'>Rag. Pesaro</option>
									<option value='Rag. Terr. Stato Ancona'>
										Rag. Terr. Stato Ancona
									</option>
									<option value='Roma'>Roma</option>
									<option value='Trib. Frosinone'>Trib. Frosinone</option>
									<option value='Trib. Latina'>Trib. Latina</option>
									<option value='Trib. Latina Sez. Fallimentare'>
										Trib. Latina Sez. Fallimentare
									</option>
									<option value='Trib. Rimini'>Trib. Rimini</option>
									<option value='Trib. Urbino'>Trib. Urbino</option>
									<option value='Trib. Pesaro'>Trib. Pesaro</option>
									<option value='Traser'>Traser</option>
									<option value='Uni. Urbino'>Uni. Urbino</option>
								</select>
							</div>
							<div>
								<label htmlFor='CreaMansione'>Mansione</label>
								<input
									type='text'
									id='CreaMansione'
									name='CreaMansione'
									ref={creaMansione}
								></input>
							</div>
							<div>
								<label htmlFor='CreaOre'>Ore/Settimana</label>
								<select id='CreaOre' defaultValue={creaOre} ref={creaOre}>
									<option label='Scegli Le Ore' value=''></option>
									{Array.from({ length: 48 }, (_, i) => {
										return (
											<option key={i * -1} value={(i + 1).toString()}>
												{i + 1}
											</option>
										);
									})}
								</select>
							</div>
							<div>
								<label htmlFor='CreaTipoContratto'>Tipo Contratto</label>
								<input
									type='text'
									id='CreaTipoContratto'
									name='CreaTipoContratto'
									ref={creaTipoContratto}
								/>
							</div>
							<div>
								<label htmlFor='CreaLivelloContratto'>Livello Contratto</label>
								<input
									type='text'
									id='CreaLivelloContratto'
									name='CreaLivelloContratto'
									ref={creaLivelloContratto}
								></input>
							</div>
							<div>
								<label htmlFor='CreaPercentualePartTime'>
									Percentuale Part-Time
								</label>
								<input
									type='number'
									min='0'
									step='0.01'
									id='CreaPercentualePartTime'
									name='CreaPercentualePartTime'
									ref={creaPercentualePartTime}
								></input>
							</div>
							<div>
								<label htmlFor='CreaScadenzaVisita'>Scadenza Visita Medica</label>
								<input
									type='date'
									id='CreaScadenzaVisita'
									name='CreaScadenzaVisita'
									ref={creaScadenzaVisita}
								/>
							</div>
							<div>
								<label htmlFor='CreaTitoloStudio'>Titolo Di Studio</label>
								<input
									type='text'
									id='CreaTitoloStudio'
									name='CreaTitoloStudio'
									ref={creaTitoloStudio}
								></input>
							</div>
							<div>
								<label htmlFor='CreaDataNascita'>Data di Nascita</label>
								<input
									id='CreaDataNascita'
									name='CreaDataNascita'
									type='date'
									ref={creaDataNascita}
									onFocus={() =>
										document.getElementById('CreaDataNascita').showPicker()
									}
								/>
							</div>
							<div>
								<label htmlFor='CreaMail'>Mail</label>
								<input type='email' id='CreaMail' name='CreaMail' ref={creaMail} />
							</div>
							<div>
								<label htmlFor='CreaCell'>Cellulare</label>
								<input id='CreaCell' type='number' min='0' ref={creaCellulare} />
							</div>
							<div>
								<label htmlFor='CreaMatricola'>Matricola</label>
								<input
									type='number'
									min='0'
									step='1'
									name='CreaMatricola'
									id='CreaMatricola'
									ref={creaMatricola}
								/>
							</div>
							<div>
								<label htmlFor='CreaInizioContratto'>Inizio Contratto</label>
								<input
									id='CreaInizioContratto'
									name='CreaInizioCotratto'
									type='date'
									ref={creaInizioContratto}
									onFocus={() =>
										document.getElementById('CreaInizioContratto').showPicker()
									}
								/>
							</div>

							<div style={{ display: 'flex', width: '345px', maxWidth: '345px' }}>
								<div
									style={{ width: '100px', maxWidth: '100px', minWidth: '100px' }}
								>
									<label
										htmlFor='CreaInd'
										style={{
											width: '100px',
											maxWidth: '100px',
											minWidth: '100px',
										}}
									>
										Indeterm.
									</label>
									<input
										id='CreaInd'
										name='CreaInd'
										type='checkbox'
										ref={creaIndeterminato}
										defaultChecked={false}
										onChange={(e) => StatusIndeterminato(e)}
										style={{
											width: '100px',
											maxWidth: '100px',
											minWidth: '100px',
										}}
									/>
								</div>
								<div>
									<label
										htmlFor='CreaFineContratto'
										style={{
											width: '245px',
											minWidth: '245px',
											maxWidth: '245px',
										}}
									>
										Fine Contratto
									</label>
									<input
										id='CreaFineContratto'
										name='CreaFineContratto'
										type='date'
										ref={creaFineContratto}
										onFocus={() =>
											document
												.getElementById('CreaFineContratto')
												.showPicker()
										}
										style={{
											width: '245px',
											minWidth: '245px',
											maxWidth: '245px',
										}}
									/>
								</div>
							</div>
							<div></div>
							<div>
								<label htmlFor='CreaInvalidita'>Invalidità</label>
								<input
									id='CreaInvalidita'
									name='CreaInvalidita'
									type='checkbox'
									ref={creaInvalidita}
									defaultChecked={false}
									onChange={(e) => statusInvalidita(e)}
								/>
							</div>
							<div>
								<label htmlFor='crea104'>104</label>
								<input
									id='crea104'
									name='crea104'
									type='checkbox'
									ref={creaCon104}
									defaultChecked={false}
									onChange={(e) => status104(e)}
								/>
							</div>
							<div>
								<label htmlFor='CreaLimitazioni'>Limitazioni</label>
								<input
									id='CreaLimitazioni'
									name='CreaLimitazioni'
									type='checkbox'
									ref={creaLimitazioni}
									defaultChecked={false}
									onChange={(e) => statusLimitazioni(e)}
								/>
							</div>
							<div></div>
							<div>
								<label htmlFor='CreaCitta'>Città</label>
								<input
									id='CreaCitta'
									name='CreaCitta'
									type='text'
									ref={creaIndirizzoCitta}
								/>
							</div>
							<div>
								<label htmlFor='CreaCAP'>CAP</label>
								<input
									id='CreaCAP'
									name='CreaCAP'
									type='number'
									min='0'
									ref={creaIndirizzoCAP}
								/>
							</div>
							<div>
								<label htmlFor='CreaVia'>Via</label>
								<input
									id='CreaVia'
									name='CreaVia'
									type='text'
									ref={creaIndirizzoVia}
								/>
							</div>
							<div></div>
							<div>
								<label htmlFor='CreaMaglietta'>Maglietta</label>
								<select
									id='CreaMaglietta'
									name='CreaMaglietta'
									defaultValue={creaTagliaMaglietta}
									ref={creaTagliaMaglietta}
								>
									<option label='Scegli la Taglia' value=''></option>
									<option value='s'>S</option>
									<option value='m'>M</option>
									<option value='l'>L</option>
									<option value='xl'>XL</option>
									<option value='xxl'>2 XL</option>
									<option value='xxxl'>3 XL</option>
									<option value='xxxl'>4 XL</option>
								</select>
							</div>
							<div>
								<label htmlFor='CreaPantaloni'>Pantaloni</label>
								<select
									id='CreaPantaloni'
									name='CreaPantaloni'
									defaultValue={creaTagliaPantaloni}
									ref={creaTagliaPantaloni}
								>
									<option label='Scegli la Taglia' value=''></option>
									<option value='36'>36</option>
									<option value='38'>38</option>
									<option value='40'>40</option>
									<option value='42'>42</option>
									<option value='44'>44</option>
									<option value='46'>46</option>
									<option value='48'>48</option>
									<option value='50'>50</option>
									<option value='52'>52</option>
									<option value='54'>54</option>
									<option value='56'>56</option>
									<option value='58'>58</option>
									<option value='60'>60</option>
									<option value='62'>62</option>
								</select>
							</div>
							<div>
								<label htmlFor='CreaScarpe'>Scarpe</label>
								<select
									id='CreaScarpe'
									name='CreaScarpe'
									defaultValue={creaTagliaScarpe}
									ref={creaTagliaScarpe}
								>
									<option label='Scegli la Misura' value=''></option>
									<option value='32'>32</option>
									<option value='33'>33</option>
									<option value='34'>34</option>
									<option value='35'>35</option>
									<option value='36'>36</option>
									<option value='37'>37</option>
									<option value='38'>38</option>
									<option value='39'>39</option>
									<option value='40'>40</option>
									<option value='41'>41</option>
									<option value='42'>42</option>
									<option value='43'>43</option>
									<option value='44'>44</option>
									<option value='45'>45</option>
									<option value='46'>46</option>
								</select>
							</div>

							<div>
								<label htmlFor='CreaNote'>Note</label>
								<textarea
									id='CreaNote'
									name='CreaNote'
									rows='4'
									cols='50'
									ref={creaNote}
									onChange={(e) => (creaNote.current.value = e.target.value)}
								/>
							</div>

							<hr />

							<div>
								<button type='button' id='CreaDip' onClick={creaDip}>
									Crea Dipendente
								</button>
							</div>
						</span>
					</div>

					<div
						id='ModificaDipendenti'
						style={{ display: mostraModificaUtente ? 'block' : 'none' }}
					>
						<h2>Modifica Dipendente</h2>
						<form id='FormModificaUtente'>
							<span className='Colonna'>
								<div>
									<label htmlFor='SceltaCantiere'>Cantiere</label>
									<select
										id='SceltaCantiere'
										onChange={ImpostaCantiereBase}
										ref={selezionaCantiere}
									>
										<option
											label='Scegli'
											placeholder='Scegli'
											value=''
										></option>
										<option value='Cam. Comm Pesaro'>Cam. Comm Pesaro</option>
										<option value='CCIA Pesaro'>CCIA Pesaro</option>
										<option value='Comm. Tribut. Marche'>
											Comm. Tribut. Marche
										</option>
										<option value='Giud. Pace Latina'>Giud. Pace Latina</option>
										<option value='Giud. Pace Pesaro'>Giud. Pace Pesaro</option>
										<option value='IDER'>IDER</option>
										<option value='Mot. Civ. Pesaro'>Mot. Civ. Pesaro</option>
										<option value='Pref. Pesaro'>Pref. Pesaro</option>
										<option value='Proc. Latina'>Proc. Latina</option>
										<option value='Rag. Pesaro'>Rag. Pesaro</option>
										<option value='Rag. Terr. Stato Ancona'>
											Rag. Terr. Stato Ancona
										</option>
										<option value='Roma'>Roma</option>
										<option value='Trib. Frosinone'>Trib. Frosinone</option>
										<option value='Trib. Latina'>Trib. Latina</option>
										<option value='Trib. Latina Sez. Fallimentare'>
											Trib. Latina Sez. Fallimentare
										</option>
										<option value='Trib. Rimini'>Trib. Rimini</option>
										<option value='Trib. Urbino'>Trib. Urbino</option>
										<option value='Trib. Pesaro'>Trib. Pesaro</option>
										<option value='Traser'>Traser</option>
										<option value='Uni. Urbino'>Uni. Urbino</option>

										<option value='Ferie'>Ferie</option>
										<option value='Permesso'>Permesso</option>
										<option value='Malattia'>Malattia</option>
										<option value='Infortunio'>Infortunio</option>
										<option value='Aspettativa'>Aspettativa</option>
										<option value='104'>104</option>
										<option value='Sospensione'>Sospensione</option>
									</select>
									<span className='CantiereAttuale'>
										<u>
											{selezionaCantiere.current?.value
												? selezionaCantiere.current.value
												: 'Tutti i Cantieri'}
										</u>
									</span>
									<div>
										<label htmlFor='Nome'>Dipendente</label>
										<SelectDipendenti valore={selezione} />
									</div>
								</div>
								<div id='UploadIMG' className={'nodisplay'}>
									<div>
										<img
											className={'nodisplay'}
											src={''}
											alt='FotoProfilo'
											id='FotoProfilo'
											style={{
												border: '2px solid red',
												borderRadius: '10px',
											}}
										/>
									</div>
									<div>
										<label className='InputFile'>
											<input
												name='InputFile'
												type='file'
												onChange={PrendiLaFoto}
											/>
											Scegli La Foto
										</label>
									</div>
									<div>
										<button type='button' onClick={CaricaImmagineProfilo}>
											Carica La Foto
										</button>
									</div>
								</div>
								<div>
									<button
										style={{
											marginBottom: '5px',
											width: '242%',
											maxWidth: '58vw',
										}}
									>
										<a id='ScaricaMi' href='./' target='_blank' download>
											Download
										</a>
									</button>
									<object id='FileShow' src='' data='' type='application/pdf'>
										<p>
											Se stai leggendo questa frase non puoi visualizzare il
											File ma devi scaricarlo dal seguente link:
											<a
												id='ScaricaSeNonLoVedi'
												href='./'
												target='_blank'
												download
											>
												SCARICA IL FILE
											</a>
										</p>
									</object>
									{/*  <p style={{ fontStyle: 'italic', fontSize: 'medium', lineHeight: '1.5em', margin: '0px', padding: '0px' }}>
                                        Se usi Safari o sei su un iPhone/iPad visualizzerai solamente la prima pagina del documento, per visualizzarlo
                                        integralmente utilizza un PC o un tablet/telefono Android
                                        <br />
                                    </p> */}
								</div>
							</span>
							<span className='Colonna'>
								<div>
									<label htmlFor='CognomeNome'>Cognome/Nome</label>
									<input id='CognomeNome' ref={aggiornaNomeDipendente} />
								</div>
								<div>
									<label htmlFor='CodFisc'>
										Codice Fiscale (Nome di Accesso)
									</label>
									<input id='CodFisc' type='text' ref={aggiornaCodiceFiscale} />
								</div>

								<div>
									<label htmlFor='Pass'>Password</label>
									<input id='Pass' type='text' ref={aggiornaPassword} />
								</div>
								<div>
									<label htmlFor='Level'>Livello Utenza</label>
									<select
										id='Level'
										defaultValue={aggiornaLivello}
										ref={aggiornaLivello}
									>
										<option label='Livello Utente' value=''></option>
										<option value='0'>Utente Standard</option>
										<option value='5'>Utente Speciale</option>
										<option value='2'>Cliente</option>
										<option value='-1'>Solo Scansioni</option>
									</select>
								</div>
								<div>
									<label htmlFor='Az'>Azienda</label>
									<input id='Az' type='text' ref={aggiornaAzienda} />
								</div>
								<div>
									<label htmlFor='CantiereScelto'>Cantiere</label>
									<select id='CantiereScelto' ref={aggiornaCantiere}>
										<option
											label='Scegli'
											placeholder='Scegli'
											value=''
										></option>
										<option value='Cam. Comm Pesaro'>Cam. Comm Pesaro</option>
										<option value='CCIA Pesaro'>CCIA Pesaro</option>
										<option value='Comm. Tribut. Marche'>
											Comm. Tribut. Marche
										</option>
										<option value='Giud. Pace Latina'>Giud. Pace Latina</option>
										<option value='Giud. Pace Pesaro'>Giud. Pace Pesaro</option>
										<option value='IDER'>IDER</option>
										<option value='Mot. Civ. Pesaro'>Mot. Civ. Pesaro</option>
										<option value='Pref. Pesaro'>Pref. Pesaro</option>
										<option value='Proc. Latina'>Proc. Latina</option>
										<option value='Rag. Pesaro'>Rag. Pesaro</option>
										<option value='Rag. Terr. Stato Ancona'>
											Rag. Terr. Stato Ancona
										</option>
										<option value='Roma'>Roma</option>
										<option value='Trib. Frosinone'>Trib. Frosinone</option>
										<option value='Trib. Latina'>Trib. Latina</option>
										<option value='Trib. Latina Sez. Fallimentare'>
											Trib. Latina Sez. Fallimentare
										</option>
										<option value='Trib. Rimini'>Trib. Rimini</option>
										<option value='Trib. Urbino'>Trib. Urbino</option>
										<option value='Trib. Pesaro'>Trib. Pesaro</option>
										<option value='Traser'>Traser</option>
										<option value='Uni. Urbino'>Uni. Urbino</option>

										<option value='Ferie'>Ferie</option>
										<option value='Permesso'>Permesso</option>
										<option value='Malattia'>Malattia</option>
										<option value='Infortunio'>Infortunio</option>
										<option value='Aspettativa'>Aspettativa</option>
										<option value='104'>104</option>
										<option value='Sospensione'>Sospensione</option>
									</select>
								</div>
								<div>
									<label htmlFor='mansione'>Mansione</label>
									<input
										type='text'
										id='mansione'
										name='mansione'
										ref={aggiornaMansione}
									></input>
								</div>
								<div>
									<label htmlFor='Hours'>Ore/Settimana</label>
									<select id='Hours' defaultValue={aggiornaOre} ref={aggiornaOre}>
										<option label='Scegli Le Ore' value=''></option>
										{Array.from({ length: 48 }, (_, i) => {
											return (
												<option key={i + 1} value={(i + 1).toString()}>
													{i + 1}
												</option>
											);
										})}
									</select>
								</div>
								<div>
									<label htmlFor='tipoContratto'>Tipo Contratto</label>
									<input
										type='text'
										id='tipoContratto'
										name='tipoContratto'
										ref={aggiornaTipoContratto}
									/>
								</div>
								<div>
									<label htmlFor='livelloContratto'>Livello Contratto</label>
									<input
										type='text'
										id='livelloContratto'
										name='livelloContratto'
										ref={aggiornaLivelloContratto}
									></input>
								</div>
								<div>
									<label htmlFor='percentualePartTime'>
										Percentuale Part-Time
									</label>
									<input
										type='number'
										min='0'
										step='0.01'
										id='percentualePartTime'
										name='percentualePartTime'
										ref={aggiornaPercentualePartTime}
									></input>
								</div>
								<div>
									<label htmlFor='scadenzaVisita'>Scadenza Visita Medica</label>
									<input
										type='date'
										id='scadenzaVisita'
										name='scadenzaVisita'
										ref={aggiornaScadenzaVisita}
									></input>
								</div>
								<div>
									<label htmlFor='Matricola'>Matricola</label>
									<input
										name='Matricola'
										id='Matricola'
										type='number'
										min='0'
										step='1'
										ref={aggiornaMatricola}
									></input>
								</div>
								<div>
									<label htmlFor='InizioContr'>Inizio Contratto</label>
									<input
										id='InizioContr'
										name='InizioContr'
										type='date'
										ref={aggiornaInizioContratto}
										onFocus={() =>
											document.getElementById('InizioContr').showPicker()
										}
									/>
								</div>
								<div style={{ display: 'flex', width: '345px', maxWidth: '345px' }}>
									<div
										style={{
											width: '100px',
											maxWidth: '100px',
											minWidth: '100px',
										}}
									>
										<label
											htmlFor='Ind'
											style={{
												width: '100px',
												maxWidth: '100px',
												minWidth: '100px',
											}}
										>
											Indeterm.
										</label>
										<input
											id='Ind'
											name='Ind'
											type='checkbox'
											ref={aggiornaIndeterminato}
											onChange={(e) => AggiornaStatusIndeterminato(e)}
											style={{
												width: '100px',
												maxWidth: '100px',
												minWidth: '100px',
											}}
										/>
									</div>
									<div
										style={{
											width: '245px',
											maxWidth: '245px',
											minWidth: '245px',
										}}
									>
										<label
											htmlFor='FineContr'
											style={{
												width: '245px',
												maxWidth: '245px',
												minWidth: '240px',
											}}
										>
											Fine Contratto
										</label>
										<input
											id='FineContr'
											name='FineContr'
											type='date'
											ref={aggiornaFineContratto}
											onFocus={() =>
												document.getElementById('FineContr').showPicker()
											}
											style={{
												width: '245px',
												maxWidth: '245px',
												minWidth: '245px',
											}}
										/>
									</div>
								</div>

								<div style={{ display: 'flex', width: '345px', maxWidth: '345px' }}>
									<div
										style={{
											width: '100px',
											maxWidth: '100px',
											minWidth: '100px',
										}}
									>
										<label
											htmlFor='Dimiss'
											style={{
												width: '100px',
												maxWidth: '100px',
												minWidth: '100px',
											}}
										>
											Fine Rapp.
										</label>
										<input
											id='Dimiss'
											name='Dimiss'
											type='checkbox'
											ref={aggiornaTermine}
											onChange={(e) => AggiornaStatusTermine(e)}
											style={{
												width: '100px',
												maxWidth: '100px',
												minWidth: '100px',
											}}
										/>
									</div>
									<div
										style={{
											width: '245px',
											maxWidth: '245px',
											minWidth: '245px',
										}}
									>
										<label
											htmlFor='DataTerm'
											style={{
												width: '245px',
												maxWidth: '245px',
												minWidth: '240px',
											}}
										>
											Fine Rapporto
										</label>
										<input
											id='DataTerm'
											type='date'
											ref={aggiornaDataTermine}
											onFocus={() =>
												document.getElementById('DataTerm').showPicker()
											}
											style={{
												width: '245px',
												maxWidth: '245px',
												minWidth: '245px',
											}}
										/>
									</div>
								</div>
								<div>
									<label htmlFor='Invalid'>Invalidità</label>
									<input
										id='Invalid'
										name='Invalid'
										type='checkbox'
										ref={aggiornaInvalidita}
										onChange={(e) => AggiornaStatusInvalidita(e)}
									/>
								</div>
								<div>
									<label htmlFor='104'>104</label>
									<input
										id='104'
										name='104'
										type='checkbox'
										ref={aggiornaCon104}
										onChange={(e) => AggiornaStatus104(e)}
									/>
								</div>
								<div>
									<label htmlFor='Limitazioni'>Limitazioni</label>
									<input
										id='Limitazioni'
										name='Limitazioni'
										type='checkbox'
										ref={aggiornaLimitazioni}
										onChange={(e) => AggiornaStatusLimitazioni(e)}
									/>
								</div>
								<div>
									<label htmlFor='titoloStudio'>Titolo Di Studio</label>
									<input
										type='text'
										id='titoloStudio'
										name='titoloStudio'
										ref={aggiornaTitoloStudio}
									></input>
								</div>
								<div>
									<label htmlFor='Birth'>Data di Nascita</label>
									<input
										id='Birth'
										name='Birth'
										type='date'
										ref={aggiornaDataNascita}
										onFocus={() =>
											document.getElementById('Birth').showPicker()
										}
									/>
								</div>
								<div>
									<label htmlFor='Mail'>Mail</label>
									<input
										type='email'
										id='Mail'
										name='Mail'
										ref={aggiornaMail}
									></input>
								</div>
								<div>
									<label htmlFor='Cell'>Cellulare</label>
									<input
										id='Cell'
										type='number'
										min='0'
										ref={aggiornaCellulare}
									/>
								</div>
								<div></div>
								<div>
									<label htmlFor='Citta'>Città</label>
									<input
										id='Citta'
										name='Citta'
										type='text'
										ref={aggiornaIndirizzoCitta}
									/>
								</div>
								<div>
									<label htmlFor='CaP'>CAP</label>
									<input
										id='CaP'
										name='CaP'
										type='number'
										min='0'
										ref={aggiornaIndirizzoCAP}
									/>
								</div>
								<div>
									<label htmlFor='Via'>Via</label>
									<input
										id='Via'
										name='Via'
										type='text'
										ref={aggiornaIndirizzoVia}
									/>
								</div>
								<div></div>
								<div>
									<label htmlFor='Magl'>Maglietta</label>
									<select
										id='Magl'
										name='Magl'
										defaultValue={aggiornaTagliaMaglietta}
										ref={aggiornaTagliaMaglietta}
									>
										<option label='Scegli la Taglia' value=''></option>
										<option value='s'>S</option>
										<option value='m'>M</option>
										<option value='l'>L</option>
										<option value='xl'>XL</option>
										<option value='xxl'>2 XL</option>
										<option value='xxxl'>3 XL</option>
										<option value='xxxl'>4 XL</option>
									</select>
								</div>
								<div>
									<label htmlFor='Pantaloni'>Pantaloni</label>
									<select
										id='Pantaloni'
										name='Pantaloni'
										defaultValue={aggiornaTagliaPantaloni}
										ref={aggiornaTagliaPantaloni}
									>
										<option label='Scegli la Taglia' value=''></option>
										<option value='36'>36</option>
										<option value='38'>38</option>
										<option value='40'>40</option>
										<option value='42'>42</option>
										<option value='44'>44</option>
										<option value='46'>46</option>
										<option value='48'>48</option>
										<option value='50'>50</option>
										<option value='52'>52</option>
										<option value='54'>54</option>
										<option value='56'>56</option>
										<option value='58'>58</option>
										<option value='60'>60</option>
										<option value='62'>62</option>
									</select>
								</div>
								<div>
									<label htmlFor='Shoes'>Scarpe</label>
									<select
										id='Shoes'
										name='Shoes'
										defaultValue={aggiornaTagliaScarpe}
										ref={aggiornaTagliaScarpe}
									>
										<option label='Scegli la Misura' value=''></option>
										<option value='32'>32</option>
										<option value='33'>33</option>
										<option value='34'>34</option>
										<option value='35'>35</option>
										<option value='36'>36</option>
										<option value='37'>37</option>
										<option value='38'>38</option>
										<option value='39'>39</option>
										<option value='40'>40</option>
										<option value='41'>41</option>
										<option value='42'>42</option>
										<option value='43'>43</option>
										<option value='44'>44</option>
										<option value='45'>45</option>
										<option value='46'>46</option>
									</select>
								</div>
								<div>
									<label htmlFor='NotE'>Note</label>
									<textarea
										id='NotE'
										name='NotE'
										rows='4'
										cols='50'
										ref={aggiornaNote}
									></textarea>
								</div>
							</span>

							<span className='Colonna' id='Documentazione'>
								<div>
									<select
										name='DocContratto'
										ref={DocContratto}
										onChange={() => {
											SulCambioFile(
												'Contratto',
												DocContratto.current.value,
												'DocContratto'
											);
										}}
									>
										<SelectFile
											value={{
												ID: IDDipendente ? IDDipendente : '',
												TipoFile: 'Contratto',
											}}
										/>
									</select>
									<label className='CaricaDoc'>
										<input
											name='CaricaDoc'
											type='file'
											id='DocumentiContratto'
											onChange={(e) =>
												CaricaDocumento(
													e,
													NuovoFileContratto,
													'NuovoFileContratto'
												)
											}
										/>
										Aggiungi Doc. Contratto
									</label>
									<p className='NuovoFileContratto'></p>
									<button
										type='button'
										onClick={(e) =>
											UploadFileSingolo(e, NuovoFileContratto, 'Contratto')
										}
									>
										Carica Documento
									</button>
									<button
										type='button'
										className='Orange'
										onClick={() =>
											EliminaDocumento(
												'Contratto',
												DocContratto.current.value
											)
										}
									>
										Elimina Documento
									</button>
								</div>

								<div>
									<select
										name='DocVariazioniContratto'
										ref={DocVariazioniContratto}
										onChange={() => {
											SulCambioFile(
												'Variazioni',
												DocVariazioniContratto.current.value,
												'DocVariazioniContratto'
											);
										}}
									>
										<SelectFile
											value={{
												ID: IDDipendente ? IDDipendente : '',
												TipoFile: 'Variazioni',
											}}
										/>
									</select>
									<label className='CaricaDoc'>
										<input
											name='CaricaDoc'
											type='file'
											id='DocumentiVariazioni'
											onChange={(e) =>
												CaricaDocumento(
													e,
													NuovoFileVariazioni,
													'NuovoFileVariazioni'
												)
											}
										/>
										Aggiungi Doc. Variazioni
									</label>
									<p className='NuovoFileVariazioni'></p>
									<button
										type='button'
										onClick={(e) =>
											UploadFileSingolo(e, NuovoFileVariazioni, 'Variazioni')
										}
									>
										Carica Documento
									</button>
									<button
										type='button'
										className='Orange'
										onClick={() =>
											EliminaDocumento(
												'Variazioni',
												DocVariazioniContratto.current.value
											)
										}
									>
										Elimina Documento
									</button>
								</div>
								<div>
									<select
										name='DocAspettativa'
										ref={DocAspettativa}
										onChange={() => {
											SulCambioFile(
												'Aspettative',
												DocAspettativa.current.value,
												'DocAspettativa'
											);
										}}
									>
										<SelectFile
											value={{
												ID: IDDipendente ? IDDipendente : '',
												TipoFile: 'Aspettative',
											}}
										/>
									</select>
									<label className='CaricaDoc'>
										<input
											name='CaricaDoc'
											type='file'
											id='DocumentiAspettativa'
											onChange={(e) =>
												CaricaDocumento(
													e,
													NuovoFileAspettativa,
													'NuovoFileAspettativa'
												)
											}
										/>
										Aggiungi Doc. Aspettative
									</label>
									<p className='NuovoFileAspettativa'></p>
									<button
										type='button'
										onClick={(e) =>
											UploadFileSingolo(
												e,
												NuovoFileAspettativa,
												'Aspettativa'
											)
										}
									>
										Carica Documento
									</button>
									<button
										type='button'
										className='Orange'
										onClick={() =>
											EliminaDocumento(
												'Aspettativa',
												DocAspettativa.current.value
											)
										}
									>
										Elimina Documento
									</button>
								</div>
								<div>
									<select
										name='Doc104'
										ref={Doc104}
										onChange={() => {
											SulCambioFile('104', Doc104.current.value, 'Doc104');
										}}
									>
										<SelectFile
											value={{
												ID: IDDipendente ? IDDipendente : '',
												TipoFile: '104',
											}}
										/>
									</select>
									<label className='CaricaDoc'>
										<input
											name='CaricaDoc'
											type='file'
											id='Documenti104'
											onChange={(e) =>
												CaricaDocumento(e, NuovoFile104, 'NuovoFile104')
											}
										/>
										Aggiungi Doc. 104
									</label>
									<p className='NuovoFile104'></p>
									<button
										type='button'
										onClick={(e) => UploadFileSingolo(e, NuovoFile104, 'La104')}
									>
										Carica Documento
									</button>
									<button
										type='button'
										className='Orange'
										onClick={() =>
											EliminaDocumento('104', Doc104.current.value)
										}
									>
										Elimina Documento
									</button>
								</div>
								<div>
									<select
										name='DocIdoneita'
										ref={DocIdoneita}
										onChange={() => {
											SulCambioFile(
												'CertificatiIdoneita',
												DocIdoneita.current.value,
												'DocIdoneita'
											);
										}}
									>
										<SelectFile
											value={{
												ID: IDDipendente ? IDDipendente : '',
												TipoFile: 'CertificatiIdoneita',
											}}
										/>
									</select>
									<label className='CaricaDoc'>
										<input
											name='CaricaDoc'
											type='file'
											id='DocumentiIdoneita'
											onChange={(e) =>
												CaricaDocumento(
													e,
													NuovoFileIdoneita,
													'NuovoFileIdoneita'
												)
											}
										/>
										Aggiungi Cert. Idoneita
									</label>
									<p className='NuovoFileIdoneita'></p>
									<button
										type='button'
										onClick={(e) =>
											UploadFileSingolo(e, NuovoFileIdoneita, 'Idoneita')
										}
									>
										Carica Documento
									</button>
									<button
										type='button'
										className='Orange'
										onClick={() =>
											EliminaDocumento(
												'CertificatiIdoneita',
												DocIdoneita.current.value
											)
										}
									>
										Elimina Documento
									</button>
								</div>
								<div>
									<select
										name='DocInvalidita'
										ref={DocInvalidita}
										onChange={() => {
											SulCambioFile(
												'Invalidita',
												DocInvalidita.current.value,
												'DocInvalidita'
											);
										}}
									>
										<SelectFile
											value={{
												ID: IDDipendente ? IDDipendente : '',
												TipoFile: 'Invalidita',
											}}
										/>
									</select>
									<label className='CaricaDoc'>
										<input
											name='CaricaDoc'
											type='file'
											id='DocumentiInvalidita'
											onChange={(e) =>
												CaricaDocumento(
													e,
													NuovoFileInvalidita,
													'NuovoFileInvalidita'
												)
											}
										/>
										Aggiungi Doc. Invalidità
									</label>
									<p className='NuovoFileInvalidita'></p>
									<button
										type='button'
										onClick={(e) =>
											UploadFileSingolo(e, NuovoFileInvalidita, 'Invalidita')
										}
									>
										Carica Documento
									</button>
									<button
										type='button'
										className='Orange'
										onClick={() =>
											EliminaDocumento(
												'Invalidita',
												DocInvalidita.current.value
											)
										}
									>
										Elimina Documento
									</button>
								</div>
								<div>
									<select
										name='DocTFR'
										ref={DocTFR}
										onChange={() => {
											SulCambioFile('TFR', DocTFR.current.value, 'DocTFR');
										}}
									>
										<SelectFile
											value={{
												ID: IDDipendente ? IDDipendente : '',
												TipoFile: 'TFR',
											}}
										/>
									</select>
									<label className='CaricaDoc'>
										<input
											name='CaricaDoc'
											type='file'
											id='DocumentiTFR'
											onChange={(e) =>
												CaricaDocumento(e, NuovoFileTFR, 'NuovoFileTFR')
											}
										/>
										Aggiungi Doc. TFR
									</label>
									<p className='NuovoFileTFR'></p>
									<button
										type='button'
										onClick={(e) => UploadFileSingolo(e, NuovoFileTFR, 'TFR')}
									>
										Carica Documento
									</button>
									<button
										type='button'
										className='Orange'
										onClick={() =>
											EliminaDocumento('TFR', DocTFR.current.value)
										}
									>
										Elimina Documento
									</button>
								</div>
								<div>
									<select
										name='DocAsset'
										ref={DocAsset}
										onChange={() => {
											SulCambioFile(
												'Asset',
												DocAsset.current.value,
												'DocAsset'
											);
										}}
									>
										<SelectFile
											value={{
												ID: IDDipendente ? IDDipendente : '',
												TipoFile: 'Asset',
											}}
										/>
									</select>
									<label className='CaricaDoc'>
										<input
											name='CaricaDoc'
											type='file'
											id='DocumentiAsset'
											onChange={(e) =>
												CaricaDocumento(e, NuovoFileAsset, 'NuovoFileAsset')
											}
										/>
										Aggiungi Doc. Asset
									</label>
									<p className='NuovoFileAsset'></p>
									<button
										type='button'
										onClick={(e) =>
											UploadFileSingolo(e, NuovoFileAsset, 'Asset')
										}
									>
										Carica Documento
									</button>
									<button
										type='button'
										className='Orange'
										onClick={() =>
											EliminaDocumento('Asset', DocAsset.current.value)
										}
									>
										Elimina Documento
									</button>
								</div>
								<div>
									<select
										name='DocContestazioni'
										ref={DocContestazioni}
										onChange={() => {
											SulCambioFile(
												'Contestazioni',
												DocContestazioni.current.value,
												'DocContestazioni'
											);
										}}
									>
										<SelectFile
											value={{
												ID: IDDipendente ? IDDipendente : '',
												TipoFile: 'Contestazioni',
											}}
										/>
									</select>
									<label className='CaricaDoc'>
										<input
											name='CaricaDoc'
											type='file'
											id='DocumentiContestazioni'
											onChange={(e) =>
												CaricaDocumento(
													e,
													NuovoFileContestazioni,
													'NuovoFileContestazioni'
												)
											}
										/>
										Aggiungi Doc. Contestazioni
									</label>
									<p className='NuovoFileContestazioni'></p>
									<button
										type='button'
										onClick={(e) =>
											UploadFileSingolo(
												e,
												NuovoFileContestazioni,
												'Contestazioni'
											)
										}
									>
										Carica Documento
									</button>
									<button
										type='button'
										className='Orange'
										onClick={() =>
											EliminaDocumento(
												'Contestazioni',
												DocContestazioni.current.value
											)
										}
									>
										Elimina Documento
									</button>
								</div>
								<div>
									<select
										name='DocRisposteContestazioni'
										ref={DocRisposteContestazioni}
										onChange={() => {
											SulCambioFile(
												'RisposteContestazioni',
												DocRisposteContestazioni.current.value,
												'DocRisposteContestazioni'
											);
										}}
									>
										<SelectFile
											value={{
												ID: IDDipendente ? IDDipendente : '',
												TipoFile: 'RisposteContestazioni',
											}}
										/>
									</select>
									<label className='CaricaDoc'>
										<input
											name='CaricaDoc'
											type='file'
											id='DocumentiRisposteContestazioni'
											onChange={(e) =>
												CaricaDocumento(
													e,
													NuovoFileRisposteContestazioni,
													'NuovoFileRisposteContestazioni'
												)
											}
										/>
										Aggiungi Risp. Contestazioni
									</label>
									<p className='NuovoFileRisposteContestazioni'></p>
									<button
										type='button'
										onClick={(e) =>
											UploadFileSingolo(
												e,
												NuovoFileRisposteContestazioni,
												'Risposte'
											)
										}
									>
										Carica Documento
									</button>
									<button
										type='button'
										className='Orange'
										onClick={() =>
											EliminaDocumento(
												'RisposteContestazioni',
												DocRisposteContestazioni.current.value
											)
										}
									>
										Elimina Documento
									</button>
								</div>
								<div>
									<select
										name='DocSanzioni'
										ref={DocSanzioni}
										onChange={() => {
											SulCambioFile(
												'Sanzioni',
												DocSanzioni.current.value,
												'DocSanzioni'
											);
										}}
									>
										<SelectFile
											value={{
												ID: IDDipendente ? IDDipendente : '',
												TipoFile: 'Sanzioni',
											}}
										/>
									</select>
									<label className='CaricaDoc'>
										<input
											name='CaricaDoc'
											type='file'
											id='DocumentiSanzioni'
											onChange={(e) =>
												CaricaDocumento(
													e,
													NuovoFileSanzioni,
													'NuovoFileSanzioni'
												)
											}
										/>
										Aggiungi Doc. Sanzioni
									</label>
									<p className='NuovoFileSanzioni'></p>
									<button
										type='button'
										onClick={(e) =>
											UploadFileSingolo(e, NuovoFileSanzioni, 'Sanzioni')
										}
									>
										Carica Documento
									</button>
									<button
										type='button'
										className='Orange'
										onClick={() =>
											EliminaDocumento('Sanzioni', DocSanzioni.current.value)
										}
									>
										Elimina Documento
									</button>
								</div>
								<div>
									<select
										name='DocAttestati'
										ref={DocAttestati}
										onChange={() => {
											SulCambioFile(
												'Attestati',
												DocAttestati.current.value,
												'DocAttestati'
											);
										}}
									>
										<SelectFile
											value={{
												ID: IDDipendente ? IDDipendente : '',
												TipoFile: 'Attestati',
											}}
										/>
									</select>
									<label className='CaricaDoc'>
										<input
											name='CaricaDoc'
											type='file'
											id='DocumentiAttestati'
											onChange={(e) =>
												CaricaDocumento(
													e,
													NuovoFileAttestati,
													'NuovoFileAttestati'
												)
											}
										/>
										Aggiungi Doc. Attestati/Formazione
									</label>
									<p className='NuovoFileAttestati'></p>
									<button
										type='button'
										onClick={(e) =>
											UploadFileSingolo(e, NuovoFileAttestati, 'Attestati')
										}
									>
										Carica Documento
									</button>
									<button
										type='button'
										className='Orange'
										onClick={() =>
											EliminaDocumento(
												'Attestati',
												DocAttestati.current.value
											)
										}
									>
										Elimina Documento
									</button>
								</div>
							</span>

							<span className='Colonna'>
								<div>
									<button
										type='button'
										id='AggiornaDip'
										onClick={aggiornaDipendente}
									>
										Modifica Utente e Carica Documenti
									</button>
								</div>
							</span>
						</form>
					</div>
					<div id='Tabelle'>
						<TabellaAnagrafiche value={tabellaDipendenti} />
					</div>
				</div>
				<div id='NonSuCellulare'>
					<p>
						Non puoi usare questa pagina da un telefono <br />
						:(
					</p>
				</div>
			</div>

			<img
				id='loading'
				style={{ display: stoCaricando === '1' ? 'block' : 'none' }}
				src={`${process.env.REACT_APP_URL_BASE}/wait.svg`}
				alt='Loading'
			></img>
		</section>
	);
}
